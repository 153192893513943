import React, { useState, useEffect, useMemo } from "react";
import Nav from "components/Nav";
import { useEnforcerAuth } from "store/EnforcerAuth";
import { checkEnforcerToken } from "components/checkToken";
import { useNavigate } from 'react-router-dom';

function EnforcementGuide() {
  const getAuth = useEnforcerAuth((state) => state.auth);
  const token = getAuth.token;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  }


  return (
    <>
    <Nav />
    <div className="text-xl lg:w-[60%] w-[80%] mx-auto leading-loose">
      <button className="text-black font-bold" onClick={goBack}>Go Back</button>

    <div className="text-center text-2xl font-bold mb-3">
      Operational Guidelines for CMR Enforcement Teams/Officers
    </div>
    <div className="text-justify">
      The electronic Central Motor Registry (e-CMR) is crucial for improving law enforcement capabilities
      of the Force by streamlining vehicle information retrieval processes for investigative and
      operational efficiency aimed at enhancing the safety and security of lives and property of Nigerians,
      prevention and detection of crime and to strengthen national security.

      <p>Authorized CMR Enforcement Teams/Officers will have access to the CMR central database
        through internet-enabled mobile phone devices (including personal mobile phones) to verify motor
        vehicle information and status. The steps are:
      </p>
    </div>
    <div>
      <div className="font-semibold mt-3">Step 1:</div>
      <div>Visit <a className="text-blue-600" href="https://npfcmr.ng/vcheck">https://npfcmr.ng/vcheck</a></div>

      <div className="font-semibold mt-3">Step 2:</div>
      <div>Enter Username and Password <span className="text-red-600">(Only authorized officers will have access to the e-CMR Enforcement portal)</span></div>

      <div className="font-semibold mt-3">Step 3:</div>
      <div>
      <span className="font-semibold">Motor Vehicle Status</span>
          <p>Enter the License or VIN/Chassis Number and click search</p>
          <p>
            <ul className="list-disc ml-4">
              <li><span>Not Found:</span> The vehicle has no record in the CMR database</li>
              <li><span>Valid:</span> The vehicle has no issue</li>
              <li><span>Conflict:</span> The vehicle information conflicts with that of another vehicle.</li>
              <li><span>Stolen:</span> The vehicle has been reported/flagged as stolen.</li>
              <li><span>Incident:</span> The vehicle has been reported to be involved in an incident</li>
              <li><span>Smuggled: </span>The vehicle is not found in the Nig Customs Service database of imported vehicles.</li>
            </ul>
          </p>
      </div>

      <div className="font-semibold mt-3">Step 4:</div>
      <div>
      <span className="font-semibold">CMR Status</span>
          <p>
            <ul className="list-disc ml-4">
              <li><span>Valid:</span> The CMR Motor Vehicle Information Certificate is valid</li>
              <li><span>Expired:</span> The CMR Motor Vehicle Information Certificate has expired</li>
            </ul>
          </p>
      </div>

      <div className="font-semibold mt-3">Step 5:</div>
      <div>
      <span className="font-semibold">Click Close to refresh the page and begin a new search.</span></div>

      <div className="mt-3 text-left">
        <p className="font-semibold">Note:</p>
        <p className="mt-2">A vehicle without information in the CMR central database will display Not Found. Such vehicle
        owners should be advised to visit the website: <a href="https://cmris.npf.gov.ng" target="_blank"> https://cmris.npf.gov.ng</a> to apply for provide the
        vehicle information.</p>
        <p className="mt-6"><span className="font-semibold">Verification of CMR Certificate</span> <br />
        A CMR Certificate presented by a vehicle owner in either hardcopy or softcopy (digital form) can
        be verified via the embedded QR Code using a QR Code scanning application usually
        found/installed in mobile phones.</p>
        <p className="mt-2">All motor vehicle and motorcycle/tricycle owners are enjoined to visit the official website at
        <a href="https://cmris.npf.gov.ng" target="_blank">https://cmris.npf.gov.ng</a> and register to obtain the NPF CMR Motor Vehicle Information Certificate.</p>

        <p className="mt-2">CMR Enforcement Teams/Officers are expected to impound motor vehicles with Status indicating
        Stolen, Incident and Smuggled and refer the matter to the Police Division having jurisdiction over
        the location where the theft/incident occurred for investigation.</p>
        <p className="mt-2">Every personnel deployed for CMR Enforcement duties shall maintain the highest level of
        professionalism and decorum in the discharge of their functions.</p>
      </div>
    </div>
    </div>
    </>
  );
}

export default EnforcementGuide;

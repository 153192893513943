import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "components/admin/SideBars/Sidebar";
import Header from "components/admin/Headers/Header";
import Loader from "components/Loader";
import { useAdminAuth } from "store/AdminAuth";
import axios from "axios";
import { base_url } from "constants/endpoints";
import Swal from "sweetalert2";
import Input from "components/EditInput";

///trying this
export default function EditCamera() {
  const getAuth = useAdminAuth((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [cam, setCam] = useState("");
  const { id } = useParams();

  useEffect(() => {
    let token = getAuth.token;
    const fetchCamDetails = async () => {
      setLoading(true);
      fetch(`https://cmr.friedenapps.ng/api/admin/camera/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then(({ data }) => setCam(data))
        .catch((err) => {
          let message;

          if (err && err.response) {
            const { data } = err.response;
            message = data.message;
          }

          Swal.fire(message || "Unable to fetch profile");
          setLoading(false);
        });
    };
    fetchCamDetails();
  }, []);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCam({ ...cam, [name]: value });
  };

  const handleSubmit = async (e) => {
    let token = getAuth.token;
    e.preventDefault();
    try {
      fetch(`https://cmr.friedenapps.ng/api/admin/camera/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(cam),
      })
        .then((response) => {
          return response.json();
        })
        .then(({ data }) => {
          setCam(data);
        });
    } catch (error) {
      Swal.fire("unable to edit profile");
    }
  };

  return (
    <div className="bg-gray-100">
      <Sidebar />
      <div className="md:ml-64">
        <Header title="Edit ANPR" />
        <div className="w-2/4 mx-auto  mt-24 border shadow-md rounded-lg pt-10 pb-20 bg-white">
          <div className="mb-8 text-2xl text-center border-b pb-4 font-bold text-gray-600">
            Edit ANPR Information
          </div>
          <form className="px-10" onSubmit={handleSubmit}>
            <label
              htmlFor="cam_id"
              className="block text-gray-900 text-lg font-semibold"
            >
              ANPR ID
            </label>
            <Input
              type="tel"
              name="camId"
              value={cam?.camId}
              onChange={handleInput}
              placeholder={"Your Id"}
            />

            <label
              htmlFor="cam_location"
              className="mt-8 block text-gray-900 text-lg font-semibold"
            >
              Location
            </label>

            {/* <select
            name="location_id"
            {...register("location_id", { required: true })}
            aria-invalid={errors.location_id ? "true" : "false"}
            // onChange={handleChange}
            className="w-full rounded border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
          >
            <option value="">Select CMR Formation</option>
            {locations.map((val, index) => {
              return (
                <option key={index} value={index}>
                  {val}
                </option>
              );
            })}
          </select> */}
            <select
              className="w-full text-sm rounded-md border-2 border-gray-200 hover:border-gray-300 mt-1"
              type="text"
              name="location"
              value={cam?.location}
              onChange={handleInput}
              placeholder={"Your Location"}
            />
            <button
              onClick={handleSubmit}
              className={`bg-lightBlue-700 rounded-lg w-32 cursor-pointer py-3 text-center mt-8 text-gray-100`}
            >
              Update Camera
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

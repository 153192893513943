import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "components/admin/SideBars/Sidebar";
import Header from "components/admin/Headers/Header";
import Loader from "components/Loader";
import { useAdminAuth } from "store/AdminAuth";
import Input from "components/EditInput";
import axios from "axios";
import { base_url } from "constants/endpoints";
import Swal from "sweetalert2";

export default function EditCamera() {
  const { id } = useParams();
  const [locations, setLocations] = useState([]);
  const getAuth = useAdminAuth((state) => state.auth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = getAuth.token;
    const fetchLocation = async () => {
      setLoading(true);
        fetch(`https://cmr.friedenapps.ng/api/admin/location/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then(( response ) => {
          return response.json()
        })
        .then(({data}) => setLocations(data))
        .catch((err) => {
          let message;

          if (err && err.response) {
            const { data } = err.response;
            message = data.message;
          }

          Swal.fire(message || "Unable to fetch Location");
          setLoading(false);
        });
    };
    fetchLocation();
  }, []);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLocations({...locations, [name]: value})
  };

  const handleSubmit = async (e) => {
    let token = getAuth.token;
    e.preventDefault();
    try {
      fetch(
        `https://cmr.friedenapps.ng/api/admin/location/${id}`,{
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(locations)
        }
      )
      .then(( response ) => {
        return response.json()
      })
      .then(({data}) =>{
        setLocations(data)
      });
      
    } catch (error) {
      console.log(error);
      Swal.fire("unable to edit location")
    }
  };

  return (
    <div className="">
      <Sidebar />
      <div className="md:ml-64">
        <Header title="Edit Formation" />
        <div className="w-2/4 my-10 mx-auto px-10 border shadow-md rounded-lg py-10">
          <form className="grid grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="locationName"
                className="block text-gray-900 text-md font-semibold"
              >
                Name
              </label>
              <Input
                type="text"
                name="name"
                value={locations.name}
                onChange={handleInput}
                placeholder={"Your Name"}
              />
            </div>

            <div>
              <label
                htmlFor="locationEmail"
                className="block text-gray-900 text-md font-semibold"
              >
                Email
              </label>
              <Input
                type="email"
                name="locationsId"
                value={locations.email}
                onChange={handleInput}
                placeholder={"Your Id"}
              />
            </div>

            <div>
              <label
                htmlFor="locations"
                className="block mt-3 text-gray-900 text-md font-semibold"
              >
                State
              </label>
              <Input
                type="text"
                name="state"
                value={locations.state}
                onChange={handleInput}
                placeholder={"Your State"}
              />
            </div>

            <div>
              <label
                htmlFor="locationsLg"
                className="block mt-3 text-gray-900 text-md font-semibold"
              >
                Local Government
              </label>
              <Input
                type="text"
                name="lg"
                value={locations.lg}
                onChange={handleInput}
                placeholder={"Your LG"}
              />
            </div>

            <div className="col-span-2">
              <label
                htmlFor="address"
                className="block mt-3 text-gray-900 text-md font-semibold"
              >
                Address
              </label>
              <Input
                type="text"
                name="address"
                value={locations.address}
                onChange={handleInput}
                placeholder={"Your Address"}
              />
            </div>

            <div>
              <label
                htmlFor="phone"
                className="block mt-3 text-gray-900 text-md font-semibold"
              >
                Phone
              </label>
              <Input
                type="tel"
                name="phone"
                value={locations.phone}
                onChange={handleInput}
                placeholder={"Your Phone Number"}
              />
            </div>

            <div>
              <label
                htmlFor="locations"
                className="block mt-3 text-gray-900 text-md font-semibold"
              >
                Zone
              </label>
              <Input
                type="text"
                name="zone"
                value={locations.zone}
                onChange={handleInput}
                placeholder={"Your Zone"}
              />
            </div>

            <div>
              <label
                htmlFor="action"
                className="block mt-3 text-gray-900 text-md font-semibold"
              >
                Type
              </label>
              <Input
                type="text"
                name="type"
                value={locations.action}
                onChange={handleInput}
                placeholder={"Type"}
              />
            </div>

            <button
            onClick={handleSubmit}
              className={`mt-3 col-span-2 bg-lightBlue-700 rounded-lg w-32 text-md cursor-pointer py-2 text-center text-gray-100`}
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

import { useNavigate } from "react-router-dom";
import { useAdminAuth } from "store/AdminAuth";
import { useState } from "react";
import { base_url, profile } from "../../../constants/endpoints";
import { useEffect } from "react";
import Loader from "components/Loader";
import Swal from "sweetalert2";

export default function Index() {
  const getAuth = useAdminAuth((state) => state.auth);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorFunc = (
    message = "Unable to get user details",
    icon = "error",
    title = "Error!"
  ) => {
    return Swal.fire({
      title: title,
      text: message,
      icon: icon,
      confirmButtonText: "Ok",
      confirmButtonColor: "#1C89DE",
    });
  };
  const navigate = useNavigate();

  const getAdmin = async () => {
    const token = getAuth.token;
    const url = base_url + profile;
    setLoading(true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        data.statusCode === 0
          ? setUser(data.data)
          : // show error;

            errorFunc().then(() => navigate(-1));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorFunc().then(() => navigate(-1));
      });
  };

  if (!user && !loading) {
    return <div></div>;
  }
  return (
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      {loading && !user ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 flex justify-between items-center ">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                User Profile
              </h3>
              <button
                className="bg-green-500 text-white rounded-full py-2 px-2 text-sm"
                onClick={() => navigate("/admin/change-password")}
              >
                change password
              </button>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Full name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {user?.first_name + " " + user?.last_name}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Email address
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {user?.email}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Phone number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {user?.phone}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Role</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {user?.roles[0].toUpperCase()}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { base_url, enforcer_logout } from "constants/endpoints";
import { useEnforcerAuth } from "store/EnforcerAuth";

export default function Nav() {
  // let token = JSON.parse(localStorage.getItem('enforcer_auth'))
  // console.log(token)
  const [expand, setExpand] = useState(false);
  const getAuth = useEnforcerAuth((state) => state.auth);
  const reset = useEnforcerAuth((state) => state.reset);
  const setAuth = useEnforcerAuth((state) => state.setEnforcerAuth)

  const LogoutEnforcer = async () => {
    const token = getAuth.token;
    const url = base_url + enforcer_logout;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
  
      const resp = await response.json();
      window.location.href="/vcheck"
      // setAuth({})
      reset()
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const expandMenu = () => {
    setExpand(!expand);
  };

  return (<>
    <nav className="bg-gray-800 border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        {/* <div className="flex items-center text-white">
          <a href="/">
            <img
              src="/logo.png"
              alt="Police Logo"
              className="h-16 w-auto"
            />
          </a>
          <div className="font-semibold mt-1 ml-2 md:ml-4 text-gray-100">
            <a className="" href="/">
              <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
                Nigeria Police Force
              </p>
              <p className="text-sm lg:text-3xl">
                {" "}
                Central Motor Registry (CMR)
              </p>
            </a>
          </div>
        </div> */}
        <a href="/" className="flex items-center">
          <img
            src="/logo.png"
            className="mr-3 lg:h-24 h-16"
            alt="POLICE Logo"
          />
           <div className="font-semibold mt-1 ml-2 md:ml-4 text-gray-100">
            <a className="" href="/">
              <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
                Nigeria Police Force
              </p>
              <p className="text-sm lg:text-3xl">
                {" "}
                Central Motor Registry (CMR)
              </p>
            </a>
          </div>
        </a>
        <button
          onClick={() => {
            expandMenu();
          }}
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        {/* <div className="hidden w-full md:block md:w-auto" id="navbar-default"> */}
        {/* {`banner ${active ? "active" : ""}`} */}
        <div
          className={`w-full md:block md:w-auto ${!expand ? "hidden" : ""}`}
          id="navbar-default"
        >
          <ul className="flex flex-col p-4 mt-4 rounded-lg border md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-gray-800 border-gray-700">
            <li>
              <a
                href="/vecheck/search"
                className="block py-2 pr-4 pl-3 text-xl text-white bg-gray-100 rounded md:bg-transparent md:text-gray-100 md:p-0 dark:text-white"
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 pr-4 pl-3 text-xl text-white rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Profile
              </a>
            </li>
            <li>
              <a
                className="cursor-pointer block py-2 pr-4 pl-3 text-xl text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                onClick={()=>{LogoutEnforcer()}}
              >
                Logout
              </a>
            </li>
            {/* <li>
                  <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Pricing</a>
                </li>
                <li>
                  <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
                </li> */}
          </ul>
        </div>
      </div>
    </nav>
    <div className="text-center bg-yellow-100 font-bold py-2">Motor Vehicle Information Checker</div>
    </>
  );
}

import { HasAccess } from "@permify/react-role";
import { colorResolution } from "utils/ColorResolution";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import { classNames } from "../../shared/Utils";
import {
  base_url,
  get_operative_idp_request,
  admin_idp_entries,
  ccchead_idp_entries,
} from "constants/endpoints";
import { useEffect, useMemo, useState } from "react";
import { useAdminAuth } from "store/AdminAuth";
import {
  SelectColumnFilter,
  StatusPill,
} from "../../components/admin/DataTable/PreCMRTable";
import PreIDPTableAdmin from "../../components/admin/DataTable/PreIDPTableAdmin";
import PreCMRTable from "../../components/admin/DataTable/PreCMRTable";
import { formatDateNew } from "utils/date_format";

export default function PreIDPEntries() {
  const [loading, setLoading] = useState(false);
  const [preidps, setPreIDPs] = useState([]);
  const [cicidps, setCICIDPs] = useState([]);
  const [cicOperativeRequests, setCICOperativeRequests] = useState([]);
  const masterTableData = useMemo(() => preidps, [preidps]);
  const commandCenterTableData = useMemo(() => cicidps, [cicidps]);
  const operativeRequests = useMemo(
    () => cicOperativeRequests,
    [cicOperativeRequests]
  );
  const getAuth = useAdminAuth((state) => state.auth);

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      // fetch(base_url + pre_cmr, {
      fetch(`${base_url}${admin_idp_entries}?status=pending`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          if (jsonResp.data == null) {
            setLoading(false);
          }
          if (jsonResp.data != null) {
            setPreIDPs(jsonResp.data);
            setLoading(false);
          }
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    // let token = localStorage.getItem("cmr_admin_token");
    setLoading(true);
    let token = getAuth.token;

    try {
      // fetch(base_url + pre_cmr_by_cc, {
      fetch(`${base_url}${ccchead_idp_entries}?status=pending`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          if (jsonResp.data != null) {
            setCICIDPs(jsonResp.data);
            setLoading(false);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      // fetch(base_url + get_analysers_requests, {
      fetch(`${base_url}${get_operative_idp_request}?status=assigned`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          if (jsonResp.data == null) {
            setLoading(false);
          }
          if (jsonResp.data != null) {
            setCICOperativeRequests(jsonResp.data);
            setLoading(false);
          }
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: () => <div className="w-22">Request Number</div>,
        accessor: "request_number",
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Middle Name",
        accessor: "middle_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Licence Class",
        accessor: "license_class",
      },
      {
        Header: "Date of Birth",
        accessor: "date_of_birth",
        Cell: ({ cell }) => (
          <span>{formatDateNew(cell.row.values.date_of_birth)}</span>
        ),
      },
      {
        Header: "Request State",
        accessor: "request_state",
      },
      {
        Header: "Eye Color",
        accessor: "eye_color",
        Cell: ({ cell }) => (
          <span
            className={`text-gray-100 px-4 py-1 rounded ${colorResolution(
              cell.row.values.eye_color
            )}`}
          >
            {cell.row.values.eye_color}
          </span>
        ),
        // Cell: StatusPill,
        // Filter: SelectColumnFilter,
      },
      {
        Header: "Command Centre",
        accessor: "cc",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "Id",
        Cell: ({ cell }) => (
          <>
            <a
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              // value={cell.row.values.id}
              disabled={true}
              href={"/admin/idpentry/" + cell.row.values.Id}
            >
              <HasAccess
                roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                // permissions="view-formation"
                renderAuthFailed={
                  <span className="text-red-700 text-4xl mx-8 hidden"></span>
                }
              >
                Details
              </HasAccess>
              <HasAccess
                roles={["cic-operative"]}
                // permissions="view-formation"
                renderAuthFailed={
                  <span className="text-red-700 text-4xl mx-8 hidden"></span>
                }
              >
                Analyse
              </HasAccess>
            </a>
          </>
        ),
      },
    ],
    []
  );

  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Information Requests List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin", "ccc-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <PreCMRTable
                columns={columns}
                data={masterTableData}
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["cic-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <PreIDPTableAdmin
                columns={columns}
                data={commandCenterTableData}
                // data={cccprecmrs}
                setcccPrecmrs={setCICIDPs}
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["cic-operative"]}
              // permissions=""
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Assigned table */}
              <PreIDPTableAdmin
                columns={columns}
                data={operativeRequests}
                loading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

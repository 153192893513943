import React, { useState, useEffect, useMemo } from "react";
// import NavBar from "../../components/Nav";
import Nav from "components/Nav";
import { Navigate } from "react-router-dom";
import { useEnforcerAuth } from "store/EnforcerAuth";
import { checkEnforcerToken } from "components/checkToken";
import {
  base_url,
  initial_vehicles,
  manual_search,
} from "../../constants/endpoints";
// import Table, { SelectColumnFilter, StatusPill } from "../../components/Table";
import EnforcementTable, {
  SelectColumnFilter,
  StatusPill,
} from "components/enforcer/EnforcementTable";
import Pusher from "pusher-js";
import { pusherKey } from "../../constants/keys";

function Enforcement() {
  const getAuth = useEnforcerAuth((state) => state.auth);
  const token = getAuth.token;
  const [initial_activities, setInitialActivities] = useState([]);
  const data = useMemo(() => initial_activities, [initial_activities]);
  const [plate_number, setPlateNumber] = useState("");
  useEffect(() => {
    if (
      getAuth.authenticated === true ||
      getAuth.token !== "" ||
      getAuth.token !== ""
    ) {
      checkEnforcerToken(getAuth.token).then((auth) => {
        if (!auth) {
          window.location = "/vcheck/login";
        }
      });
    }
  }, []);

  useEffect(() => {
    const token = getAuth.token;
    const camera = getAuth.camera
    const endpoint = base_url + initial_vehicles + camera;
    fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        if (jsonResp.statusCode === 0) {
          console.log(jsonResp.data);
          setInitialActivities(jsonResp.data);
        }
      });
  }, []);

  useEffect(() => {
    const channelName = getAuth.camera;
    // console.log(JSON.stringify(channelName));
    Pusher.logToConsole = false;
    const pusher = new Pusher(pusherKey, {
      cluster: "eu",
    });
    const channel = pusher.subscribe(channelName);

    channel.bind("enforcement", function (data) {
      setInitialActivities((oldArray) => [data, ...oldArray]);
    });

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Licence Plate Number",
        accessor: "licensePlate",
      },
      {
        Header: "Vehicle Name",
        accessor: "name",
      },
      {
        Header: "Vehicle Status",
        accessor: "status",
        Cell: StatusPill,
        Filter: SelectColumnFilter, // new
      },
      {
        Header: "Chassis Number",
        accessor: "chassisNumber",
      },
      {
        Header: "Engine Number",
        accessor: "engineNumber",
      },
      {
        Header: "Make",
        accessor: "manufacturer",
        Filter: SelectColumnFilter, // new
        // filter: "includes",
      },
      {
        Header: "Model",
        accessor: "model",
      },
      {
        Header: "Color",
        accessor: "color",
      },
      {
        Header: "Vehicle Usage",
        accessor: "vehicleUsage",
      },
      {
        Header: "CMR Status",
        accessor: "cmrStatus",
        Cell: StatusPill,
      },
      {
        Header: "Insurance",
        accessor: "insurance",
        Cell: StatusPill,
      },
      {
        Header: "Vehicle Licence",
        accessor: "vehicleLicense",
        Cell: StatusPill,
      },
      {
        Header: "Road Worthiness",
        accessor: "roadWorthiness",
        Cell: StatusPill,
      },
      {
        Header: "Time",
        accessor: "time",
      },
    ],
    []
  );

  //handle manual search box change
  const handleChange = (e) => {
    setPlateNumber(e.target.value);
  };
  const manualSearch = () => {
    const camera = getAuth.camera
    const token = getAuth.token;
    let url =
      base_url +
      manual_search + "?camera=" +
      camera + "&searchwith=" +plate_number +"&channel=web";
    
    console.log(url);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        if (jsonResp.statusCode === 0) {
          // console.log(jsonResp.data);
          // setInitialActivities(jsonResp.data);
        }
      });
  };
  // if (!token) {
  //   return <Navigate to={"vcheck/login"} />;
  // }
  return (
    <>
      {/* <StrictMode> */}
      <Nav />
      <div className="h-full bg-gray-100 text-gray-900">
        <main className="px-4 sm:px-6 lg:px-8 pt-4">
          <div className=""></div>
          <div className="mt-4">
            <div className="w-1/2 mb-4">
              <label htmlFor="search" className="text-xl mr-4">
                New Search
              </label>
              <input
                type="text"
                name="plate_number"
                onChange={handleChange}
                className="w-2/4 rounded-lg hover:shadow-black hover:shadow-lg text-lg h-12 shadow-red-300 shadow-lg border-1 border-green-700"
              />
              <button
                onClick={() => {
                  manualSearch();
                }}
                className="ml-5 rounded-lg bg-green-700 h-12 text-gray-100 px-6 shadow-xl"
              >
                Search
              </button>
            </div>
            {data && <EnforcementTable columns={columns} data={data} />}
            {!data && (
              <p className="text-center text-3xl font-semibold font-mono">
                Data will appear once activity starts on the camera
              </p>
            )}
            {/* {!data && <EnforcementTable columns={columns} />} */}
            {/* <EnforcementTable columns={columns} /> */}
          </div>
        </main>
      </div>
      {/* </StrictMode> */}
    </>
  );
}

export default Enforcement;

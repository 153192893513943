import React from "react";
import Header from "components/admin/Headers/Header";
import FullReportForm from "components/admin/Forms/FullReportForm";
import Sidebar from "components/admin/SideBars/Sidebar";

export default function FullReport() {
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64">
        <Header title="Motor Vehicle Incident Flash Report" />
        <div className="w-[70%] mx-auto px-5  my-12 border shadow-md rounded-lg pt-10 pb-3 bg-white">
          <div className="mb-8 text-2xl text-center border-b pb-4 font-bold text-gray-600">
            FULL REPORT FORM
          </div>
          <FullReportForm />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Header from "components/admin/Headers/Header";
import FlashReportForm from "components/admin/Forms/FlashReportForm";
import Sidebar from "components/admin/SideBars/Sidebar";

export default function FlashReport() {
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="Motor Vehicle Incident Flash Report" />
        <div className="bg-white mt-6 py-8">
        <div className="px-8 mt-1 w-full text-lg leading-9 overflow-x-auto">
            <button
              onClick={() => {
                window.history.back();
              }}
              className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
            >
              &larr; Go Back
            </button>
            <FlashReportForm />
           </div>
          
        </div>
      </div>
    </div>
  );
}

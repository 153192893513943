export const removeSpaces = (string) => {
  let trimmedString = string.replaceAll(" ", "");
  return trimmedString;
};

export const stringToUpper = (string) => {
  return string.toUpperCase();
};

export const upperFirstChar = (string = "") => {
  return string.slice(0, 1).toUpperCase() + string.slice(1).toLowerCase();
};

import React, { useEffect, useState } from "react";
import {
  base_url,
  get_profile as search_profile,
  update_profile
} from "constants/endpoints";
import { useParams } from "react-router-dom";
import Sidebar from "components/admin/SideBars/Sidebar";
import Swal from "sweetalert2";
import { useAdminAuth } from "store/AdminAuth";
import Header from "components/admin/Headers/Header";
import CmrCorrectionModal from "./CmrCorrectionModal";
import Loader from "components/Loader";
import verifyUser from "utils/VerifyUser";

export default function UserDetails() {
  const { id } = useParams();
  const getAuth = useAdminAuth((state) => state.auth);
  const [userDetails, setUserDetails] = useState({})
  const [loading, setLoading]=useState(false)
  const [editFirstName, setEditFirstName] = useState(false)
  const [editLastName, setEditLastName] = useState(false)
  const [editMiddleName, setEditMiddleName] = useState(false)
  const [editIdNumber, setEditIdNumber] = useState(false)
  const [editPhone, setEditPhone] = useState(false)
  const [editEmail, setEditEmail] = useState(false)
  const [editAddress, setEditAddress] = useState(false)
  const [editProfileType, setEditProfileType] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [editGender, setEditGender] = useState(false)
  
  useEffect(()=>{
      setUserDetails({})
      setLoading(true);
      let token = getAuth.token;
      let url = `${base_url}${search_profile}${id}`;
      try {
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((jsonResp) => {
            console.log(jsonResp.data);
            if (jsonResp.data == null) {
              setLoading(false);
            }
            if (jsonResp.data != null) {
              setUserDetails(jsonResp.data);
              setLoading(false);
            }
          });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
  }, [])

  const handleChange = (e)=>{
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  }

  const Commit =()=>{
      setLoading(true);
      let token = getAuth.token;
      let url = `${base_url}${update_profile}${userDetails.Id}`;
      let data={
        first_name:userDetails?.first_name?.toUpperCase(),
        middle_name:userDetails?.middle_name?.toUpperCase(),
        last_name:userDetails?.last_name?.toUpperCase(),
        id_type:userDetails.id_type,
        id_number:userDetails.id_number,
        phone:userDetails.phone,
        email:userDetails.email,
        state:userDetails.current_state,
        lg:userDetails.current_lg,
        profile_type: userDetails.profile_type,
        address:userDetails.current_address?.toUpperCase()
      }
      try {
        fetch(url, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((jsonResp) => {
            console.log(jsonResp.data);
            if(jsonResp.data==true){
              Swal.fire({
                title: "User Updated!",
                text: "",
                icon: "success",
              }).then(()=>{
                window.location.reload()
              })
            }else{
              Swal.fire({ title: "Failed!", text: jsonResp.message, icon: "error" }).then(()=>{setLoading(false)});
            }
          });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
  }

  // const handleBlur = (e)=>{
  //   // setEditLastName(false)
  //   console.log(e.target.name)
  //   if (e.target.name=="first_name"){
  //     setEditFirstName(false)
  //   }else if(e.target.name=="middle_name"){
  //     setEditMiddleName(false)
  //   }else if (e.target.name=="last_name"){
  //     setEditLastName(false)
  //   }else if(e.target.name=="id_number"){
  //     setEditIdNumber(false)
  //   }else if(e.target.name=="id_type"){
  //     setEditIdNumber(false)
  //   }else if(e.target.name=="phone"){
  //     setEditPhone(false)
  //   }else if(e.target.name=="email"){
  //     setEditEmail(false)
  //   }else if(e.target.name=="current_address"){
  //     setEditAddress(false)
  //   }else if(e.target.name=="profile_type"){
  //     setEditProfileType(false)
  //   }else{}
  // }

  const handleBlur = (e) => {
    const name = e.target.name;
    console.log(name);
  
    const editStateMap = {
      first_name: setEditFirstName,
      middle_name: setEditMiddleName,
      last_name: setEditLastName,
      id_number: setEditIdNumber,
      id_type: setEditIdNumber,
      phone: setEditPhone,
      email: setEditEmail,
      current_address: setEditAddress,
      profile_type: setEditProfileType,
      gender: setEditGender,
    };
  
    if (editStateMap[name]) {
      editStateMap[name](false);
    }
  };
  
  if (!getAuth.token || getAuth.token === "" || getAuth.token === undefined) {
    window.location = "/admin/login";
    return;
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Information Request Details" />
        {/* <ul className="ml-8 flex flex-wrap text-lg pb-4 font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"> */}
        {/* <li className="mr-2 cursor-pointer"></li>
          <li className="mr-2"></li> */}
        {/* </ul> */}

        <div className="bg-white mt-6 py-8">
          <div className="px-8 mt-1 w-full text-lg leading-9 overflow-x-auto">
            <div className="flex justify-between">
              <button
                onClick={() => {
                  window.history.back();
                }}
                className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
              >
                &larr; Go Back
              </button>
              {/* <button
                className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                onClick={() => toggleModal()}
              >
                Make Corrections
              </button> */}
            </div>
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Owner Details <span className="bg-green-700 rounded p-1 cursor-pointer hover:bg-black h-7 text-sm" onClick={()=>{Commit()}}>{loading ? <Loader /> : "Save Changes "}</span>
              {/* | <span className="cursor-pointer hover:text-black" onClick={()=>{!syncing && SyncroniseOwner()}}>{syncing ? 'Syncing...' : 'Syncronise'}</span> */}
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">First Name: <span className="text-blue-800 font-semibold text-sm cursor-pointer" onDoubleClick={() => setEditFirstName(true)}>{userDetails.first_name=="" && !editFirstName && "Add"}</span></td>
                      <td>
                        {!editFirstName ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit uppercase"
                            onDoubleClick={() => setEditFirstName(true)}
                          >
                            {userDetails.first_name}
                          </span>
                        ) : (
                          <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="first_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={userDetails.first_name || ""} 
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Profile Type:</td>
                      <td className="text-blue-800 font-semibold">
                        
                        {!editProfileType ? (
                          <span
                            className="uppercase text-blue-800 font-semibold inline-edit"
                            onDoubleClick={() => setEditProfileType(true)}
                          >
                            {userDetails.profile_type === "individual" &&
                              "Personal"}
                            {userDetails.profile_type === "corporate" &&
                              "Organisation"}
                          </span>
                        ) : (
                          <>
                            <select name="profile_type" onBlur={handleBlur} onChange={handleChange} className="rounded-lg h-10 uppercase inline-edit">
                            <option value="">Select Profile Type</option>
                              <option value="individual">Individual</option>
                              <option value="corporate">Organization</option>
                            </select>
                          </>
                          
                        )} 
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Phone:</td>
                      <td 
                        className="text-blue-800 font-semibold"
                      >
                        {!editPhone ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit"
                            onDoubleClick={() => setEditPhone(true)}
                          >
                            {userDetails.phone || ""}
                          </span>
                        ) : (
                          <>
                            <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={userDetails.phone || ""} 
                          />
                          </>
                          
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="table-headers">Id Number:</td>
                      <td>{!editIdNumber ? (
                          <span
                            className="uppercase text-blue-800 font-semibold inline-edit"
                            onDoubleClick={() => setEditIdNumber(true)}
                          >
                            {userDetails?.id_type || ""}
                          </span>
                        ) : (
                          <>
                            <select name="id_type" onBlur={handleBlur} onChange={handleChange} className="rounded-lg h-10 uppercase inline-edit">
                            <option value="">Select ID Type</option>
                              <option value="nin">NIN</option>
                              <option value="cac">CAC</option>
                              <option value="tin">TIN</option>
                            </select>
                          </>
                          
                        )} - 

                        {!editIdNumber ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit"
                            onDoubleClick={() => setEditIdNumber(true)}
                          >
                            {userDetails.id_number}
                          </span>
                        ) : (
                          <>
                            <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="id_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={userDetails.id_number || ""} 
                          />
                          </>
                          
                        )}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="table-headers">Address:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.current_address}
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Last Name: <span className="text-blue-800 font-semibold text-sm cursor-pointer" onDoubleClick={() => setEditLastName(true)}>{userDetails.last_name=="" && !editLastName && "Add"}</span> </td>
                      <td>
                        {!editLastName ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit uppercase"
                            onDoubleClick={() => setEditLastName(true)}
                          >
                            {userDetails.last_name}
                          </span>
                        ) : (
                          <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="last_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={userDetails.last_name || ""}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Gender:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.gender}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">State:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.current_state}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Name on ID:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.id_number_name}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Middle Name: <span className="text-blue-800 font-semibold text-sm cursor-pointer" onDoubleClick={() => setEditMiddleName(true)}>{userDetails.middle_name=="" && !editMiddleName && "Add"}</span></td>
                      <td>
                        {!editMiddleName ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit uppercase"
                            onDoubleClick={() => setEditMiddleName(true)}
                          >
                            {userDetails.middle_name}
                          </span>
                        ) : (
                          <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="middle_name"
                            value={userDetails.middle_name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Email:</td>
                      <td 
                        className="text-blue-800 font-semibold"
                      >
                        {!editEmail ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit"
                            onDoubleClick={() => setEditEmail(true)}
                          >
                            {userDetails.email || ""}
                          </span>
                        ) : (
                          <>
                            <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={userDetails.email || ""} 
                          />
                          </>
                          
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">L.G.A:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.current_lg}
                      </td>
                    </tr>

                    <tr>
                      <td className="table-headers">Validation Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.status}
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
            <div className="table-headers">Address:
              <span className="text-blue-800 font-semibold ml-12">
                {!editAddress ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit"
                            onDoubleClick={() => setEditAddress(true)}
                          >
                            {userDetails.current_address || ""}
                          </span>
                        ) : (
                          <>
                            <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="current_address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={userDetails.current_address || ""} 
                          />
                          </>
                          
                        )}
              </span>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
      <CmrCorrectionModal />
    </div>
  );
}

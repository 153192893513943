import create from 'zustand';
import { persist } from 'zustand/middleware';
import produce from 'immer';

const useModalStore = create(
  persist(
    (set) => ({
      modals: {}, // Object to store the state of multiple modals
      toggleModal: (id) =>
        set((state) =>
          produce(state, (draft) => {
            draft.modals[id] = !draft.modals[id];
          })
        ),
      // Optional: function to set modal state directly
      setModalState: (id, value) =>
        set((state) =>
          produce(state, (draft) => {
            draft.modals[id] = value;
          })
        ),
    }),
    {
      name: 'modal-store',
    }
  )
);

export default useModalStore;

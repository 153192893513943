import React from "react";

function Select({
  size,
  label,
  name,
  id,
  options,
  register,
  required,
  onChange,
  errors,
  loading,
}) {
  return (
    <div className="flex items-center  flex-col mt-5 w-full">
      <label htmlFor={id} className="whitespace-nowrap mr-2 self-start">
        {label}
      </label>
      <select
        name={name}
        id={id}
        className={`${size ? size : "w-full"}  h-auto
        p-2
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
        {...register(name, { required })}
        onChange={onChange}
      >
        <option value="">{loading ? "Loading..." : "Select"}</option>
        {options.map((val, index) => {
          return (
            <option key={index} value={val}>
              {val.name ? val.name : val}
            </option>
          );
        })}
      </select>
      {errors?.message ? (
        <span className="text-sm text-red-600 self-start">{`${label} is required`}</span>
      ) : null}
    </div>
  );
}

export default Select;

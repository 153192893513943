import React, { useState, useEffect, useMemo } from "react";
import { colorResolution } from "utils/ColorResolution";
import Sidebar from "components/admin/SideBars/Sidebar";
import Header from "components/admin/Headers/Header";
import FinalizedCMRTable, {
  StatusPill,
  SelectColumnFilter,
} from "../../components/admin/DataTable/FinalizedCMRTable";
import PreCMRTableAdmin, {
  StatusPill as StatusP,
  SelectColumnFilter as SelectColumnF,
} from "../../components/admin/DataTable/PreCMRTableAdmin";
import { HasAccess } from "@permify/react-role";
import {
  base_url,
  assigned_cmr_requests,
  pre_cmr_by_cc,
  admin_idp_entries,
  ccchead_idp_entries,
} from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";
import { formatDateNew } from "utils/date_format";
import PreCMRTable from "components/admin/DataTable/PreCMRTable";
import PreIDPTable from "components/admin/DataTable/PreIDPTable";

export default function AssignedIDPRequests() {
  const [loading, setLoading] = useState(false);
  const [precmrs, setPreCMRs] = useState([]);
  const [cccprecmrs, setcccPrecmrs] = useState([]);
  const masterTableData = useMemo(() => precmrs, [precmrs]);
  const commandCenterTableData = useMemo(() => cccprecmrs, [cccprecmrs]);
  const getAuth = useAdminAuth((state) => state.auth);

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      // fetch(base_url + assigned_cmr_requests, {
      fetch(`${base_url}${admin_idp_entries}?status=assigned`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          setLoading(false);
          console.log(jsonResp);
          if (jsonResp.data != null) {
            setPreCMRs(jsonResp.data);
          }
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      setLoading(true);
      // fetch(base_url + pre_cmr_by_cc, {
      fetch(`${base_url}${ccchead_idp_entries}?status=assigned`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          if (jsonResp.data != null) {
            setcccPrecmrs(jsonResp.data);
            setLoading(false);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: () => <div className="w-22">Request Number</div>,
        accessor: "request_number",
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Middle Name",
        accessor: "middle_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Licence Class",
        accessor: "license_class",
      },
      {
        Header: "Date of Birth",
        accessor: "date_of_birth",
      },
      {
        Header: "Request State",
        accessor: "request_state",
      },
      {
        Header: "Eye Color",
        accessor: "eye_color",
        Cell: ({ cell }) => (
          <span
            className={`text-gray-100 px-4 py-1 rounded ${colorResolution(
              cell.row.values.eye_color
            )}`}
          >
            {cell.row.values.eye_color}
          </span>
        ),
        // Cell: StatusPill,
        // Filter: SelectColumnFilter,
      },
      {
        Header: "Analyzer",
        accessor: "analyser",
      },
      {
        Header: "Assigned Date",
        accessor: "assigned_at",
        Cell: ({ cell }) => (
          <span>{formatDateNew(cell.row.values.assigned_at)}</span>
        ),
      },
      {
        Header: "Command Centre",
        accessor: "cc",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "Id",
        Cell: ({ cell }) => (
          <>
            <a
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              // value={cell.row.values.id}
              disabled={true}
              href={"/admin/idpupdate/" + cell.row.values.Id}
            >
              <HasAccess
                roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                // permissions="view-formation"
                renderAuthFailed={
                  <span className="text-red-700 text-4xl mx-8 hidden"></span>
                }
              >
                Details
              </HasAccess>
              <HasAccess
                roles={["cic-operative"]}
                // permissions="view-formation"
                renderAuthFailed={
                  <span className="text-red-700 text-4xl mx-8 hidden"></span>
                }
              >
                Analyse
              </HasAccess>
            </a>
          </>
        ),
      },
    ],
    []
  );

  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle International Drivers License Assigned List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <PreIDPTable
                columns={columns}
                data={masterTableData}
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <FinalizedCMRTable
                columns={columns}
                data={commandCenterTableData}
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-analyser"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Assigned table */}
              <FinalizedCMRTable
                columns={columns}
                data={commandCenterTableData}
                loading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

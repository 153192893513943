import React, {useEffect} from 'react'
import Header from "../../components/Header";
import Login from "../../components/enforcer/auth/Login";
import { useEnforcerAuth } from "store/EnforcerAuth";
import { checkEnforcerToken } from 'components/checkToken';
export default function LoginPage() {
  const getAuth = useEnforcerAuth((state) => state.auth);

  return (
    <>
      <div className="">
        <Header heading="" subheading={`CMR Enforcement Portal`} />
        <Login />
      </div>
    </>
  );
}

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";
import { base_url, change_password } from "../../../constants/endpoints";
import FormAction from "../../FormAction";
import IconComponent from "./IconComponent";

function ChangePasswordForm() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordCon, setShowPasswordCon] = useState(false);
  const getAuth = useAdminAuth((state) => state.auth);

  const errorFunc = (message, icon, title) => {
    return Swal.fire({
      title: title,
      text: message,
      icon: icon,
      confirmButtonText: "Ok",
      confirmButtonColor: "#1C89DE",
    });
  };

  const onSubmit = async (data) => {
    const token = getAuth.token;
    setIsSubmitting(true);
    const passwordData = {
      current_password: data.oldPassword.trim(),
      new_password: data.newPassword.trim(),
      confirm_password: data.confirmPassword.trim(),
    };
    try {
      const response = await fetch(base_url + change_password, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(passwordData),
      });
      const jsonResponse = await response.json();
      if (jsonResponse.statusCode === 0) {
        errorFunc(jsonResponse.message, "success", "Success!").then(() =>
          navigate("/")
        );
      } else {
        errorFunc(jsonResponse.message, "error", "Error!");
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      errorFunc("Invalid credentials", "error", "Error!");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full p-5  max-w-md mx-auto"
    >
      <div className="mb-4 ">
        <label
          htmlFor="oldPassword"
          className="block text-gray-700 font-bold mb-2"
        >
          Current Password
        </label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            name="oldPassword"
            id="oldPassword"
            {...register("oldPassword", { required: true })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <IconComponent
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />

          {errors.oldPassword && (
            <span className="text-red-500 text-sm">
              Please enter your old password.
            </span>
          )}
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="newPassword"
          className="block text-gray-700 font-bold mb-2"
        >
          New Password
        </label>
        <div className="relative">
          <input
            type={showPasswordNew ? "text" : "password"}
            name="newPassword"
            id="newPassword"
            {...register("newPassword", { required: true, minLength: 6 })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <IconComponent
            showPassword={showPasswordNew}
            setShowPassword={setShowPasswordNew}
          />
        </div>
        {errors.newPassword && errors.newPassword.type === "required" && (
          <span className="text-red-500 text-sm">
            Please enter your new password.
          </span>
        )}
        {errors.newPassword && errors.newPassword.type === "minLength" && (
          <span className="text-red-500 text-sm">
            Password must be at least 6 characters.
          </span>
        )}
      </div>
      <div className="mb-4 ">
        <label
          htmlFor="confirmPassword"
          className="block text-gray-700 font-bold mb-2"
        >
          Confirm Password
        </label>
        <div className="relative">
          <input
            type={showPasswordCon ? "text" : "password"}
            name="confirmPassword"
            id="confirmPassword"
            {...register("confirmPassword", {
              required: true,
              validate: (value) => value === watch("newPassword"),
            })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <IconComponent
            showPassword={showPasswordCon}
            setShowPassword={setShowPasswordCon}
          />
        </div>
        {errors.confirmPassword &&
          errors.confirmPassword.type === "required" && (
            <span className="text-red-500 text-sm">
              Please confirm your new password.
            </span>
          )}
        {errors.confirmPassword &&
          errors.confirmPassword.type === "validate" && (
            <span className="text-red-500 text-sm">
              Passwords do not match.
            </span>
          )}
      </div>
      <FormAction text={"Change Password"} loadingState={isSubmitting} />
    </form>
  );
}

export default ChangePasswordForm;

import React from "react";

function Input({
  label,
  name,
  type,
  id,
  placeholder,
  required,
  register,
  errors,
  onChange,
  onBlur,
  readOnly,
  disabled
}) {
  return (
    <div className="flex flex-col mt-5 w-full">
      <label htmlFor={id} className="whitespace-nowrap mr-2">
        {label}
      </label>
      <div className=" w-full flex flex-col">
        <input
          type={type}
          name={name}
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          className={`w-full h-auto
        p-2
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
          placeholder={placeholder}
          {...register(name, { required, onBlur: onBlur })}
          aria-invalid={errors ? "true" : "false"}
          onChange={onChange}
        />
        {/* {errors?.type === "required" ? (
          <span className="text-sm text-red-600">{`${label} is required`}</span>
        ) : null} */}
        {errors?.message ? (
          <span className="text-sm text-red-600">
            {errorFormater(label, errors?.message)}
          </span>
        ) : null}
      </div>
    </div>
  );
}

const errorFormater = (label, message) => {
  if (message.includes("must be a `number` type")) {
    return "Please enter a valid number.";
  }
  const words = message.split(" ");
  words[0] = label;
  return words.join(" ");
};

export default Input;

import React, {useState, useEffect} from 'react';
import Nav from 'components/Nav';
import { manual_enforcement_search, base_url } from 'constants/endpoints';
import { useEnforcerAuth } from 'store/EnforcerAuth';
import Loader from 'components/Loader';
import { checkEnforcerToken } from 'components/checkToken';
import { stolen_details_by_enforcer } from 'constants/endpoints';
import { formatDateNew } from 'utils/date_format';

const Search = () => {
    const [searchwith, setSearchWith] = useState("")
    const [info, setInfo] = useState([])
    const getAuth = useEnforcerAuth((state) => state.auth);
    const [loading, setLoading]= useState(false)
    const [checkStolen, setCheckStolen] = useState(false)
    const [stolenInfo, setStolenInfo] = useState([])
    const [status, setStatus] = useState();
    const [CMRStatus, setCMRStatus] = useState("")
    
    useEffect(() => {
        //Confirm the token
        if (getAuth.token !=""){
          checkEnforcerToken(getAuth.token).then((auth) => {
            if(auth !=true){
              window.location.href="/vcheck/login"
            }
          })
        }else{
          window.location.href="/vcheck/login"
        }
      })

    const handleBlur = () => {
        setLoading(true)
        let url = base_url + manual_enforcement_search + "?searchwith=" + searchwith + "&channel=web"
        let token = getAuth.token
        try{
            fetch(url, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
              })
                .then((response) => response.json())
                .then((jsonResp) => {
                  if (jsonResp.data == null) {
                    setLoading(false);
                  }
                  if (jsonResp.data != null) {
                    console.log(jsonResp.data)
                    setInfo(jsonResp.data);
                    setLoading(false);
                  }
            });
        } catch (e) {
          setLoading(false);
          console.log(e);
        }
    }

    const getStolenDetails = (license, vin) => {
      setCheckStolen(true)
      setLoading(true)
      let url = base_url + stolen_details_by_enforcer + "?license=" + license + "&vin=" + vin
      let token = getAuth.token
      // console.log(token)
      // console.log(url)
        try{
            fetch(url, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
              })
                .then((response) => response.json())
                .then((jsonResp) => {
                  console.log(jsonResp)
                  if (jsonResp.data == null) {
                    setLoading(false);
                  }
                  if (jsonResp.data != null) {
                    // console.log (jsonResp.data)
                    setInfo(jsonResp.data);
                    setLoading(false);
                  }
            });
        } catch (e) {
          setLoading(false);
          console.log(e);
        }
    }

    useEffect(() => {
      setStatus(info.status)
      if (info?.status=="Reported Stolen" || info?.status=="Flagged as Stolen"){
        setStatus("Stolen")
      }
      if(info?.conflictFlag == true || info?.conflictFlag==1){
        setStatus("Conflict")
      }

      //set the cmr status
      if(info?.cmrStatus=="Not Active"){
        setCMRStatus("NOT FOUND")
      }else if(info?.cmrStatus=="Approved"){
        setCMRStatus("Active")
      }else if(info?.cmrStatus=="Pending" || info?.cmrStatus=="Processed" || info?.cmrStatus=="Assigned"){
        setCMRStatus("Pending")
      }
      else{
        setCMRStatus(info?.cmrStatus)
      }
    }, [info.status])
    return (
        <div>
            <Nav />
            <div className='mx-4 lg:mx-96'>
              <div className='lg:flex mt-4'>
                <div className='lg:flex-1 lg:w-[80%] w-full'>
                  <label for="searchWith" className='text-sm'>Enter Licence or VIN/Chassis Number</label>
                  <input 
                    // placeholder={`Enter Licence or VIN/Chassis Number`}
                    type="text"
                    className="uppercase text-lg text-left w-full rounded-lg border-2 font-sans border-yellow-400 focus:border-green-500"
                    
                    onChange={(e) => setSearchWith(e.target.value)}
                    onBlur={handleBlur}
                  />
                </div>
                <div className='lg:flex-1 lg:w-[20%]'><button className="bg-gray-800 text-white px-4 lg:py-3 py-1 mt-1 lg:mt-6 rounded-lg lg:ml-2 w-full" onClick={handleBlur}>Search</button></div>
              </div>
            </div>
            <div className='mt-8 mx-4 lg:mx-96'>  
            <div className='text-center'>{loading && <div><Loader /></div>}</div>
            <div className={`text-center text-3xl  
              ${(status == "Not Found" || status === "Flagged as Stolen" || status === "Reported Stolen" || status=="Stolen" || status === "Conflict" || (info?.cmrStatus !== "Approved" && status !== "Valid")) ? 'text-red-600' : 'text-green-700'}`}>
            </div>
            {status=="Not Found" && <div className='text-center text-4xl text-red-600 font-bold mt-16'>
              Not found
              <div className='mt-16 text-sm'>Owner be advised to visit CMRIS Portal via <a className="text-blue-600" href="https://cmris.npf.gov.ng">https://cmris.npf.gov.ng</a> to provide motor vehicle information.</div>

              <div className='lg:flex-1 lg:w-[20%]'><button className="bg-gray-800 text-white px-4 lg:py-3 py-1 mt-16 lg:-mt-0 rounded-lg lg:ml-2 w-full mb-4 text-lg" onClick={()=>{window.location.reload()}}>Close</button></div>
              </div>}
                {/* <span className='font-bold' onClick={()=>{if (info?.status === "Stolen") getStolenDetails(info?.licensePlate, info?.chassisNumber)}}>{info?.licensePlate?.toUpperCase()}</span> - { (info.status != "Valid" && !info.conflictFlag) ? <span className={``}>{!info.conflictFlag ? info?.status?.toUpperCase() : "CONFLICT"}</span> : <span className={`${info?.cmrStatus=="Approved"} ? 'text-green-600':'text-red-600'`} >{info?.cmrStatus?.toUpperCase()}</span> }</div> */}
                {/* <span className='font-bold' onClick={()=>{if (info?.status === "Stolen") getStolenDetails(info?.licensePlate, info?.chassisNumber)}}>{info?.licensePlate?.toUpperCase()}</span> - { (info.status != "Valid" && !info.conflictFlag) ? <span className={``}>{!info.conflictFlag ? info?.status?.toUpperCase() : "CONFLICT"}</span> : <span className={`${info?.cmrStatus=="Approved"} ? 'text-green-600':'text-red-600'`} >{info?.cmrStatus?.toUpperCase()}</span> }</div> */}
                {/* { info.status != "Valid" ? <span className={``}>{!info.conflictFlag ? info?.status?.toUpperCase() : "CONFLICT"}</span> : <span className={`${info?.cmrStatus=="Approved"} ? 'text-green-600':'text-red-600'`} >{info?.cmrStatus?.toUpperCase()}</span> }</div> */}
                
                {(status == "Valid" || status == "Flagged as Stolen" || status == "Conflict" || status=="Reported Stolen"  || status=="Stolen" || status=="Pending" || status=="Expired") && <>
                <div>
                    <table className="w-full mt-4 text-lg">
                      <tr className='border'>
                        <td className="font-bold p-1">Vehicle Status:</td>
                        <td className={`uppercase font-bold text-2xl ${status=="Valid" ? "text-green-600" : "text-red-600"}`}>{status}</td>
                      </tr>

                      <tr className='border'>
                        <td className="font-bold p-1">CMR Certificate:</td>
                        <td className={`uppercase text-2xl font-bold ${CMRStatus=="Active" ? "text-green-600":"text-red-600"}`}>{CMRStatus}</td>
                      </tr>

                      <tr className='border'>
                        <td className="font-bold p-1">Vehicle Licence:</td>
                        <td>N/A</td>
                      </tr>

                      <tr className='border'>
                        <td className="font-bold p-1">Insurance:</td>
                        <td>N/A</td>
                      </tr>

                      <tr className='border'>
                        <td className="font-bold p-1">Driver License:</td>
                        <td>N/A</td>
                      </tr>

                      <tr className='border'>
                        <td className="font-bold p-1">Tinted Glass Permit:</td>
                        <td className={`uppercase font-bold text-2xl ${(status=="Valid" && info?.tintedStatus=="Approved") ? "text-green-600" : "text-red-600 text-center"}`}>{info?.tintedStatus || "---"}</td>
                      </tr>
                    </table>
                    <table className='w-full mt-4 text-lg'>
                        {(info?.status=="Flagged as Stolen") &&  <>
                          <tr className='mt-3'>
                              <td className='font-bold'>Flagged on:</td>
                              <td>{formatDateNew(info?.flaggedAt)}</td>
                          </tr>
                        </>}

                        {(info?.status=="Reported Stolen") &&  <>
                          <tr className='mt-3'>
                              <td className='font-bold'>Date Reported:</td>
                              <td>{formatDateNew(info?.reportedAt)}</td>
                          </tr>

                          <tr className='mt-3'>
                              <td className='font-bold'>Date Stolen:</td>
                              <td>{formatDateNew(info?.dateStolen)}</td>
                          </tr>

                          <tr className='mt-3'>
                              <td className='font-bold'>Place Stolen:</td>
                              <td>{info?.placeStolen}</td>
                          </tr>

                          <tr className='mt-3'>
                              <td className='font-bold'>State:</td>
                              <td>{info?.StateStolen}</td>
                          </tr>

                          <tr className='mt-3 mb-3'>
                              <td className='font-bold'>Formation:</td>
                              <td>{info?.policeStation}</td>
                          </tr>
                        </>}
                        <hr className='border-0 mt-5'/>
                        <tr className='border-b-2 border-t-2'>
                            <td className='font-bold mt-2'>Owner:</td>
                            <td>{info?.name}</td>
                        </tr>
                        
                        <tr className='border-b-2'>
                            <td className='font-bold'>Make:</td>
                            <td>{info?.manufacturer}</td>
                        </tr>

                        <tr className='border-b-2'>
                            <td className='font-bold'>Model:</td>
                            <td>{info?.model}</td>
                        </tr>

                        <tr className='border-b-2'>
                            <td className='font-bold'>Type:</td>
                            <td>{info?.vehicleType}</td>
                        </tr>

                        <tr className='border-b-2'>
                            <td className='font-bold'>Color:</td>
                            <td>{info?.color}</td>
                        </tr>

                        <tr className='border-b-2'>
                            <td className='font-bold'> Licence No:</td>
                            <td>{info?.licensePlate?.toUpperCase()}</td>
                        </tr>

                        <tr className='border-b-2'>
                            <td className='font-bold'>Chassis No:</td>
                            <td>{info?.chassisNumber?.toUpperCase()}</td>
                        </tr>


                        <tr className='border-b-2'>
                            <td className='font-bold'>Engine No:</td>
                            <td>{info?.engineNumber?.toUpperCase()}</td>
                        </tr>
                    </table>
                    <div className='lg:flex-1 lg:w-[20%]'><button className="bg-gray-800 text-white px-4 lg:py-3 py-1 mt-1 lg:-mt-0 rounded-lg lg:ml-2 w-full mb-4" onClick={()=>{window.location.reload()}}>Close</button></div>
                </div>
                </>}
            </div>
            <div className='bg-gray-100 text-center'><span className='font-bold'>For support:</span> 09169896000, 08117776698</div>
            <div className='bg-gray-100 text-center mt-3'><a href="/vcheck/guide" target="_self">Click <span className='font-bold'>here</span> for Operational Guidelines</a></div>
        </div>
    )
}

export default Search
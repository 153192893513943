import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import showFunction from "utils/showFunction";
import {
  base_url,
  pre_cmr_details,
  pre_cmr_details_registered,
  pre_cmr_details_stolen,
} from "constants/endpoints";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";
import { tableArrayData } from "constants/others";

export default function GeneralPreCMREntryDetails() {
  const { id } = useParams();
  const [userInputVehicle, setUserInputVehicle] = useState([]);
  const [registeredDetails, setRegisteredDetails] = useState([]);
  const [stolenDetails, setStolenDetails] = useState([]);
  const getAuth = useAdminAuth((state) => state.auth);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const status = queryParams.get("watchlist_status");
  const vin = queryParams.get("vin");
  const licenseNumber = queryParams.get("license_number");

  useEffect(() => {
    fetchFunc(pre_cmr_details + id, setUserInputVehicle, "Applicant Data");
    if (status === "Conflict") {
      fetchFunc(
        `${pre_cmr_details_registered}?vin=${vin}&license=${licenseNumber}`,
        setRegisteredDetails,
        "CMRIS MV Info Data"
      );
    }

    if (status === "Stolen") {
      fetchFunc(
        `${pre_cmr_details_stolen}?vin=${vin}&license=${licenseNumber}`,
        setStolenDetails,
        "CMRIS Stolen MV Data"
      );
    }
  }, []);

  const fetchFunc = async (url, setter, type) => {
    const token = getAuth.token;
    try {
      const response = await fetch(base_url + url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const responseData = await response.json();
      const { data, statusCode } = responseData;
      if (statusCode === 0) {
        setter(data);
      } else {
        Swal.fire({
          title: "Error!",
          text: `Error fetching ${type} data from the server!`,
          icon: "error",
        });
      }
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: `Error fetching ${type} data from the server!`,
        icon: "error",
      });
    }
  };

  
  const stateArray = [userInputVehicle, registeredDetails, stolenDetails];

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Information Request Analysis" />
        <div className="bg-white mt-6 pt-1">
          <div className="overflow-x-auto">
            <div className="p-5">
              <div className="flex justify-between mb-4 ">
                <button
                  onClick={() => {
                    window.history.back();
                  }}
                  className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                >
                  &larr; Go Back
                </button>
                <div className="bg-red-400 text-white rounded  text-base text-center p-2">
                  {status}
                </div>
              </div>

              <table className="analyzer-table">
                <tbody>
                  <tr className="analyzer-table-header">
                    <td>Data Field</td>
                    <td>Applicant Data</td>
                    <td>CMRIS MV Info Data</td>
                    <td>CMRIS Stolen MV Data</td>
                    <td>CMRIS MV Incident Data</td>
                  </tr>
                  {tableArrayData.map((each, i) => {
                    return (
                      <TableData
                        key={i}
                        stateArray={stateArray}
                        currentValue={each.value}
                        name={each.dataField}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const TableData = ({ stateArray, currentValue, name }) => {
  return (
    <tr>
      <td className="text-blue-800 font-semibold">{name}:</td>
      {stateArray.map((each, i, arr) => {
        return <td key={i}>{showFunction(each[currentValue])}</td>;
      })}
      <td>{showFunction()}</td>
    </tr>
  );
};

export const base_url = process.env.REACT_APP_BACKEND_URL;
export const public_base = process.env.REACT_APP_PUBLIC_BASE;
export const ws_url = process.env.REACT_WS_BACKEND_URL;
export const login = "/api/login";
export const enforcer_logout = "/api/enforcer/logout";
export const refresh = "/api/refresh";
export const admin_login = "/api/admin/login";
export const admin_logout = "/api/admin/logout";
export const get_admin = "/api/admin/admin/view/";
export const edit_admin_perm = "/api/admin/admin/update/";
export const enforcement = "/api/ws/enforcement";
export const states = "/api/states";
export const lgs = "/api/lgs/";
export const create_location = "/api/admin/location";
export const get_locations = "/api/admin/location";
export const create_camera = "/api/admin/camera";
export const get_cameras = "/api/admin/camera";
export const pending_reports = "/api/admin/stolen/pendings";
export const posted_reports = "/api/admin/stolen/posteds";
export const stolen_details = "/api/admin/stolen/";
export const approval_layer1 = "/api/admin/approval/layer1";
export const approval_layer2 = "/api/admin/approval/layer2";
export const approval_layer = "/api/admin/analysers/cmrinfo/recommend";
export const approval_layer_cow = "/api/admin/analysers/cow/recommend";
export const approval_layer_cow2 = "/api/admin/approval";
export const create_enforcer = "/api/admin/enforcer/create";
export const get_enforcers = "/api/admin/enforcer/index";
export const initial_vehicles = "/api/enforcement/vehicles/";
export const get_my_camera = "/api/me/camera/";
export const manual_search = "/api/search/";
export const create_permission = "/api/admin/permission/create";
export const get_permissions = "/api/admin/permission";
export const upload_passport = "/api/admin/upload/passport";
export const vehicle_types = "/api/vehicle_types/";
export const correct_cmr_approve = "/api/admin/corrections/approve/";
export const correct_cmr_initiate = "/api/admin/corrections/initiate";
export const correct_cmr_list = "/api/admin/corrections/index/";
export const pre_cmr = "/api/admin/precmr/pendings";
export const pre_cmr_details = "/api/admin/precmr/";
export const get_idp_details = "/api/admin/idp/";
export const pre_cmr_details_registered = "/api/admin/registeredvehicle";
export const pre_cmr_details_stolen = "/api/admin/stolenvehicle";
export const get_police_officer = "/api/admin/npfhr/";
export const register_admin = "/api/admin/register";
export const get_admins = "/api/admin/adminusers";
export const disable_location = "/api/admin/disablelocation/";
export const enable_location = "/api/admin/enablelocation/";
export const disable_camera = "/api/admin/disablecamera/";
export const enable_camera = "/api/admin/enablecamera/";
export const pre_cmr_by_cc = "/api/admin/cccprecmr";
export const analysers = "/api/admin/analysers";
export const assign_cmr_entry = "/api/admin/assign/cmrinfo";
export const assign_idp = "/api/admin/assign/idp"
export const assign_cow_entry = "/api/admin/assign/cow";
export const assigned_cmr_requests = "/api/admin/precmr/assigned";
export const get_analysers_requests = "/api/admin/analysers/cmrinfo";
export const recommend = "/api/admin/analysers/cmrinfo/recommend";
export const get_admin_command_center = "/api/admin/admin/location";
export const get_invoice = "/api/admin/transaction/";
export const get_admin_request = "/api/admin/precmr";
export const get_admin_cow = "/api/admin/admincows";
export const get_ccc_cow = "/api/admin/cowsccc";
export const get_analyser_cow = "/api/admin/analysercows";
export const get_ccc_request = "/api/admin/cccprecmr";
export const get_analyser_request = "/api/admin/analysers/cmrinfo";
export const get_operative_idp_request = "/api/admin/operative/idp";
export const approval = "/api/admin/approval";
export const police_formation = "/api/formations";
export const change_password = "/api/admin/admin/passwordchange";
export const analyser_analysis = "/api/admin/analysersummary";
export const ccc_head_analysis = "/api/admin/cccsummary";
export const profile = "/api/admin/admin/profile";
export const addAgent = "/api/admin/agent/register";
export const ninData = "/api/id/verification/agent";
export const get_mvaa = "/api/admin/getvehiclefromstates";
export const get_cow_details = "/api/admin/cow/";
export const ng_customs = "/api/admin/verifycustoms";
export const admin_idp_entries = "/api/admin/idpentries";
export const ccchead_idp_entries = "/api/admin/cccidpentries";
export const tint_entries = "/api/admin/tintentries";
export const confirm_cmr = "/api/admin/search";
export const get_stolen_vehicles="/api/admin/search/stolenvehicles";
export const vehicle_details = "/api/admin/vehicle/"
export const idp_recommendation = "/api/admin/operative/idp/recommend";
export const upload_signature = "/api/admin/upload/signature";
export const search_profile= "/api/admin/cmrisusers/";
export const get_profile= "/api/admin/cmrisuser/";
export const reset_cmris_user_password = "/api/admin/cmrisusers/passwordreset/";

// export const validate_token
export const agents = "/api/admin/agents";
export const superagents = "/api/admin/superagents";
export const set_agent_status = "/api/admin/approve/agent/";
export const manufacturers = "/api/manufacturers/";
export const models = "/api/models/";
export const add_model = "/api/admin/addmodel";
export const checktoken = "/api/admin/checktoken";

export const checkenforcertoken = "/api/checkenforcertoken";
export const manual_enforcement_search = "/api/search"
export const stolen_details_by_enforcer = "/api/getstolenvehicle";
export const verifyuser ="/api/admin/verifyuser/"
export const update_profile = "/api/admin/profile/update/"
export const switch_owner = "/api/admin/vehicle/owner/update/"
export const synccmrinfo = "/api/admin/synccmrinfo/"
export const regenerate = "/api/admin/regenerate/"
import { base_url, admin_logout } from "../../../constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";

export const AdminLogout = () => {
  // let token = localStorage.getItem("cmr_admin_token");
  const getAuth = useAdminAuth((state) => state.auth);
  const resetAuth = useAdminAuth((state) => state.reset);
  // console.log(token);
  let endpoint = base_url + admin_logout;
  // alert(getAuth.token);
  return;
  // let response = fetch(endpoint, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + JSON.parse(getAuth.token),
  //   },
  // });

  // if (response.status == 200) {
  //   localStorage.removeItem("__permifyUser");
  //   resetAuth();
  //   // localStorage.removeItem("cmr_admin_token");
  //   // window.location = "/admin/login";
  // }
};

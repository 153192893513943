import React from "react";
import Sidebar from "components/admin/SideBars/Sidebar";
import Header from "components/admin/Headers/Header";

export default function StolenVehicle() {
  return (
    <div>
      <div>
        <Sidebar />
        <div className="md:ml-64 bg-blueGray-600">
          <Header title="Stolen Vehicle Report" />
        </div>
      </div>
    </div>
  );
}

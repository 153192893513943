import React, { useMemo, useState, useEffect } from "react";
import Sidebar from "components/admin/SideBars/Sidebar";
import Header from "components/admin/Headers/Header";
import Table from "components/Table";
import RegisterAdmin from "components/admin/auth/RegisterAdmin";
import { base_url, get_admins } from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";

export default function Admin_CIC() {
  const [menu, setMenu] = useState(0);
  const [admins, setAdmins] = useState([]);
  const data = useMemo(() => admins, [admins]);
  const getAuth = useAdminAuth((state) => state.auth);
  const columns = React.useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "AP/Force Number",
        accessor: "username",
      },
      {
        Header: "Rank",
        accessor: "rank",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Dept/Comd",
        accessor: "command",
        // Cell: StatusPill,
      },
      {
        Header: "Section/Division",
        accessor: "division",
        // Cell: StatusPill, // new
      },
      {
        Header: "Subsection",
        accessor: "subsection",
        // Filter: SelectColumnFilter, // new
        // filter: "includes",
      },
      {
        Header: "CMR Formation",
        accessor: "location_id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "roles",
      },
      {
        Header: "Actions",
        accessor: "CMREntryId",
        Cell: ({ cell }) => (
          <>
            <a
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              href={"/admin/users/edit/" + cell.row.original.userId}
            >
              Edit
            </a>
          </>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    console.log(base_url + get_admins);
    let token = getAuth.token;
    try {
      fetch(base_url + get_admins, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          setAdmins(jsonResp.data);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR User Management" />
        <div className="flex mx-4 py-1 w-1/5">
          <div
            onClick={() => {
              setMenu(0);
            }}
            className={`flex-auto hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100 ${
              menu === 0 && "text-yellow-300"
            }`}
          >
            Users List
          </div>
          <div
            onClick={() => {
              setMenu(1);
            }}
            className={`flex-auto hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100 ${
              menu === 1 && "text-yellow-300"
            }`}
          >
            Add User
          </div>
        </div>
      </div>
      {menu === 0 && (
        <div className="ml-64 mt-3">
          <div className="mx-4">
            <Table columns={columns} data={data} />
          </div>
        </div>
      )}

      {menu === 1 && (
        <div className="ml-64 mt-3">
          <div className="mx-4">
            <RegisterAdmin />
          </div>
        </div>
      )}
      {/* {menu === 0 && <RegisterAdmin />} */}
    </div>
  );
}

import create from "zustand";
import produce from "immer";
// import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  invoiceDetails: {
    firstName: "",
    middleName: "",
    lastName: "",
    address: "",
    email: "",
    phone: "",
    narration: "",
    amount: "",
    rrr: "",
    transactionId: "",
    invoiceNumber: "",
    initiatedDate: "",
    status: "",
    productCode: "",
    make: "",
    vin: "",
    licenceNumber: "",
  },

  setInvoiceDetails: (invoice_details) =>
    set(
      produce((temp) => {
        temp.invoiceDetails = {
          firstName: invoice_details.firstName,
          middleName: invoice_details.middleName,
          lastName: invoice_details.lastName,
          email: invoice_details.email,
          phone: invoice_details.phone,
          address: invoice_details.address,
          narration: invoice_details.narration,
          rrr: invoice_details.rrr,
          amount: invoice_details.amount,
          transactionId: invoice_details.transactionId,
          invoiceNumber: invoice_details.invoiceNumber,
          initiatedDate: invoice_details.initiatedDate,
          status: invoice_details.status,
          productCode: invoice_details.productCode,
          make: invoice_details.make,
          vin: invoice_details.vin,
          licenceNumber: invoice_details.licenseNumber,
        };
      })
    ),

  reset: (invoiceDetails) => set(invoiceDetails),
});

// store = devtools(store);
// store = persist(store, { name: "invoice" });
export const useInvoiceStore = create(store);

import create from "zustand";
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  auth: {
    token: "",
    authenticated: false,
    cc: "",
    state: "",
    name: "",
    loc_name: "",
    loc_zone: "",
  },
  setAuth: (auth) =>
    set(
      produce((temp) => {
        temp.auth = {
          token: auth.token,
          authenticated: auth.authenticated,
          cc: auth.cc,
          state: auth.state,
          name: auth.name,
          location_name: auth.location_name,
          location_zone: auth.location_zone,
        };
      })
    ),

  reset: (auth) => set(auth),
});
// store = devtools(store);
store = persist(store, { name: "adminAuth" });
export const useAdminAuth = create(store);

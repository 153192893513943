import { useState } from "react";
import { loginFields } from "../../../constants/loginFormFields";
import Input from "../../Input";
import FormAction from "../../FormAction";
import { base_url, admin_login } from "../../../constants/endpoints";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import { usePermify } from "@permify/react-role";
import { useAdminAuth } from "store/AdminAuth";
import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";

const fields = loginFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function AdminLogin() {
  // const token = localStorage.getItem("cmr_admin_token");
  const [loginState, setLoginState] = useState(fieldsState);
  const { setUser } = usePermify();
  const [loadingState, setLoadingState] = useState(false);
  const setAuth = useAdminAuth((state) => state.setAuth);
  const getAuth = useAdminAuth((state) => state.auth);

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let auth = await authenticateUser();
    if (auth.loggedIn === true) {
      setAuth({
        token: auth.token,
        authenticated: true,
        name: auth.name,
        state: auth.state,
        location_name: auth.location_name,
        location_zone: auth.location_zone,
      });
      // localStorage.setItem("cmr_admin_token", JSON.stringify(auth.token));
      window.location.href = "/admin/dashboard";
    }
  };

  //Handle Login API Integration here
  const authenticateUser = async () => {
    setLoadingState(true);
    let endpoint = base_url + admin_login;
    let data = {
      loggedIn: false,
      token: "",
      name: "",
      state: "",
      location_name: "",
      location_zone: "",
    };
    // console.log(endpoint);

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginState),
    });
    let jsonResponse = await response.json();
    console.log(jsonResponse.statusCode);

    if (jsonResponse.statusCode === 0) {
      data = {
        loggedIn: true,
        token: jsonResponse.token,
        name: jsonResponse.name,
        state: jsonResponse.state,
        location_name: jsonResponse.location_name,
        location_zone: jsonResponse.location_zone,
      };
      setUser({
        id: jsonResponse.username,
        roles: jsonResponse.roles,
        permissions: jsonResponse.permissions,
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: "You entered wrong credentials!",
        icon: "error",
      })
        .then(() => {
          setLoadingState(false);
        })
        .catch((error) => {
          setLoadingState(false);
        });
    }
    return data;
  };

  // if (getAuth.token !== undefined || getAuth.token !== "") {
  //   return <Navigate to={"/admin/dashboard"} />;
  // }

  return (
    <div className="container md:w-1/3 pb-12 border-b-4 rounded-lg border-green-800 mx-auto -mt-8">
      <form className="mt-3 space-y-6" onSubmit={handleSubmit}>
        <div className="-space-y-px">
          {fields.map((field) => (
            <Input
              key={field.id}
              handleChange={handleChange}
              value={loginState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
            />
          ))}
        </div>
        <FormAction
          handleSubmit={handleSubmit}
          text="Login"
          loadingState={loadingState}
        />
      </form>
    </div>
  );
}

import React, {useEffect} from "react";
import "./App.css";
import OneSignal from 'react-onesignal';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/enforcer/Login";
import Enforcement from "./pages/enforcer/Enforcement";
import AdminLoginPage from "./pages/admin/Login";
import NoMatch from "./pages/common/errrors/NoMatch";
import Landing from "./pages/Landing";
import Dashboard from "./pages/admin/Dashboard";
import Locations from "pages/admin/Locations";
import Cameras from "pages/admin/Cameras";
import PatrolOfficer from "pages/admin/PatrolOfficer";
import { PermifyProvider } from "@permify/react-role";
import PendingStolenReports from "pages/admin/PendingStolenReports";
import PostedStolenReports from "pages/admin/PostedStolenReports";
import PendingReportDetails from "pages/admin/PendingReportDetails";
import PostedReportDetails from "pages/admin/PostedReportDetails";
import Permission from "pages/admin/Permission";
import Admin_CIC from "pages/admin/Admin-CIC";
import PreCMREntries from "pages/admin/PreCMREntries";
import PreCMREntryDetailsForAnalyser from "pages/admin/PreCMREntryDetailsForAnalyser";
import GeneralPreCMREntryDetails from "pages/admin/GeneralPreCMREntryDetails";

import PreCMREntryDetailsForAdmin from "pages/admin/PreCMREntryDetailsForAdmin";
import EditLocations from "pages/admin/EditLocations";
import FullReport from "pages/admin/FullReport";
import FlashReport from "pages/admin/FlashReport";
import StolenVehicle from "pages/admin/StolenVehicle";
import EditCamera from "pages/admin/EditCamera";
import AssignedCMRRequests from "pages/admin/AssignedCMRRequests";

import Enquiry from "pages/admin/Enquiry";
import CorrectionApproval from "pages/admin/CorrectionApproval";
import FinalizedCMRRequests from "pages/admin/FinalizedCMRRequests";
import ProcessedCMRRequests from "pages/admin/ProcessedCMRRequests";
import InvoiceDetails from "pages/admin/InvoiceDetails";
import ChangePassword from "pages/admin/ChangePassword";
import ProfilePage from "pages/admin/ProfilePage";
import Model from "pages/admin/Model";
import AddAgent from "pages/admin/AddAgent";
import ChangeOwnershipEntries from "pages/admin/ChangeOwnershipEntries";
import EditAdmin from "pages/admin/EditAdmin";
// import ProcessedCMRRequests from "pages/admin/ProcessedCMRRequests";
import AssignedChangeOwnershipRequests from "pages/admin/AssignedChangeOwnershipRequests";
import ProcessedChangeOwnershipRequests from "pages/admin/ProcessedChangeOwnershipRequests ";
import FinalizedChangeOwnershipRequests from "pages/admin/FinalizedChangeOwnershipRequests ";
import PreChangeOwnershipDetails from "pages/admin/PreChangeOwnershipDetails";
import Agents from "pages/admin/Agents";

//IDP imports
import PreIDPEntries from "pages/admin/PreIDPEntries";
import AssignedIDPRequests from "pages/admin/AssignedIDPRequests";
import ProcessedIDPRequests from "pages/admin/ProcessedIDPRequests";
import FinalizedIDPRequests from "pages/admin/FinalizedIDPRequests";
import VehicleDetails from "pages/admin/VehicleDetails";

//TINT imports
import AssignedTintRequests from "pages/admin/AssignedTintRequests";
import ProcessedTintPermitRequests from "pages/admin/ProcessedTintPermitRequests";
import FinalizedTintRequests from "pages/admin/FinalizedTintRequests";
import IDPEntryDetails from "pages/admin/IDPEntryDetails";
import IDPUpdateDetails from "pages/admin/IDPUpdateDetails";
import Search from "pages/enforcer/Search";
import EnforcementGuide from "pages/enforcer/EnforcementGuide";
import UserDetails from "pages/admin/UserDetails";

const PreCMREntryDetails = () => {
  let role = localStorage.getItem("__permifyUser");
  let roleJson = JSON.parse(role);
  if (roleJson.roles[0] !== "ccc-analyser") {
    return (
      <>
        <PreCMREntryDetailsForAdmin />
      </>
    );
  } else {
    return (
      <>
        <PreCMREntryDetailsForAnalyser />
      </>
    );
  }
};
function App() {
  useEffect(() => {
    OneSignal.init({
      appId: "e2f7c5d2-676a-41d4-8e6d-4d96abf8b693"
    });
  }, []);

  return (
    <PermifyProvider>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Landing />} /> */}
          <Route path="/" element={<Navigate to="/admin/login" />} />
          <Route path="/admin" element={<Navigate to="/admin/login" />} />
          <Route path="/vcheck" element={<Navigate  to="/vcheck/login" />} />
          <Route path="/enforcement" element={<Navigate  to="/vcheck/login" />} />
          <Route path="/compliance" element={<Navigate  to="/vcheck/login" />} />
          <Route path="/enforce" element={<Navigate  to="/vcheck/login" />} />
          <Route path="/vcheck/login" element={<LoginPage />} />
          <Route path="/vcheck/guide" element={<EnforcementGuide />} />
          <Route path="/admin/permissions" element={<Permission />} />
          <Route path="/admin/users" element={<Admin_CIC />} />
          <Route path="/admin/users/edit/:admin_id" element={<EditAdmin />} />
          <Route path="/vcheck/enforce" element={<Enforcement />} />
          <Route path="vcheck/search" element={<Search />} />
          <Route path="/admin/login" element={<AdminLoginPage />} />
          <Route path="/admin/locations" element={<Locations />} />
          <Route
            path="/admin/locations/:id"
            exact
            element={<EditLocations />}
          />
          <Route path="/admin/cameras" element={<Cameras />} />
          <Route path="/admin/cameras/:id" exact element={<EditCamera />} />
          <Route path="/admin/cpos" element={<PatrolOfficer />} />
          <Route path="*" element={<NoMatch />} status={404} />
          <Route
            path="/admin/stolens/pending"
            element={<PendingStolenReports />}
          />
          <Route
            path="/admin/stolens/posted"
            element={<PostedStolenReports />}
          />
          <Route path="/admin/dashboard" exact element={<Dashboard />} />
          <Route
            path="/admin/stolens/pending/:id"
            exact
            element={<PendingReportDetails />}
          />

          <Route
            path="/admin/enquiry/vehicle/:id"
            exact
            element={<VehicleDetails />}
          />

          <Route
            path="/admin/cmrisusers/:id"
            exact
            element={<UserDetails />}
          />
          <Route
            path="/admin/correction/approval"
            exact
            element={<CorrectionApproval />}
          />

          <Route
            path="/admin/vehicle/enquiry/:id"
            exact
            element={<PendingReportDetails />}
          />

          <Route
            path="/admin/stolens/posted/:id"
            exact
            element={<PostedReportDetails />}
          />

          <Route path="/admin/precmr" element={<PreCMREntries />} />
          <Route path="/admin/assignedcmr" element={<AssignedCMRRequests />} />
          <Route
            path="/admin/processedcmr"
            element={<ProcessedCMRRequests />}
          />

          <Route
            path="/admin/finalizedcmr"
            element={<FinalizedCMRRequests />}
          />
          <Route
            path="/admin/precmr/:id"
            exact
            element={<PreCMREntryDetails />}
          />

          <Route
            path="/admin/assignedcmr/:id"
            exact
            element={<PreCMREntryDetails />}
          />

          <Route
            path="/admin/idpentry/:id"
            exact
            element={<IDPEntryDetails />}
          />

          <Route
            path="/admin/idpupdate/:id"
            exact
            element={<IDPUpdateDetails />}
          />

          <Route
            path="/admin/precmr/status/:id"
            exact
            element={<GeneralPreCMREntryDetails />}
          />

          <Route
            path="/admin/report/flash-report"
            exact
            element={<FlashReport />}
          />
          <Route
            path="/admin/report/full-report"
            exact
            element={<FullReport />}
          />
          <Route path="/admin/cmrisassist/add" exact element={<AddAgent />} />
          <Route
            path="/admin/report/stolen"
            exact
            element={<StolenVehicle />}
          />
          <Route
            path="/admin/change-password"
            exact
            element={<ChangePassword />}
          />

          <Route
            path="/admin/cow/pending"
            exact
            element={<ChangeOwnershipEntries />}
          />
          <Route
            path="/admin/cow/assigned"
            exact
            element={<AssignedChangeOwnershipRequests />}
          />
          <Route
            path="/admin/cow/processed"
            exact
            element={<ProcessedChangeOwnershipRequests />}
          />
          <Route
            path="/admin/cow/finalized"
            exact
            element={<FinalizedChangeOwnershipRequests />}
          />
          <Route
            path="/admin/cow/details/:id"
            exact
            element={<PreChangeOwnershipDetails />}
          />

          <Route path="/admin/profile" exact element={<ProfilePage />} />

          <Route path="/invoice/:reference" element={<InvoiceDetails />} />
          <Route path="/admin/assist" element={<Agents />} />
          <Route path="/admin/model" exact element={<Model />} />

          {/* IDP routes */}
          <Route path="/admin/idp/pending" exact element={<PreIDPEntries />} />
          <Route
            path="/admin/idp/assigned"
            exact
            element={<AssignedIDPRequests />}
          />
          <Route
            path="/admin/idp/processed"
            exact
            element={<ProcessedIDPRequests />}
          />
          <Route
            path="/admin/idp/finalized"
            exact
            element={<FinalizedIDPRequests />}
          />

          {/* Tint Routes */}
          <Route
            path="/admin/tint/assigned"
            exact
            element={<AssignedTintRequests />}
          />
          <Route
            path="/admin/tint/processed"
            exact
            element={<ProcessedTintPermitRequests />}
          />
          <Route
            path="/admin/tint/finalized"
            exact
            element={<FinalizedTintRequests />}
          />

          <Route path="/admin/vehicle/enquiry" exact element={<Enquiry />} />
        </Routes>
      </BrowserRouter>
    </PermifyProvider>
  );
}

export default App;

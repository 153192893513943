import { HasAccess } from "@permify/react-role";
import { colorResolution } from "utils/ColorResolution";
import PreCMRTable from "components/admin/DataTable/PreCMRTable";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import { formatDateNew } from "utils/date_format";
import {
  public_base,
  base_url,
  admin_idp_entries,
  ccchead_idp_entries,
} from "constants/endpoints";
import { useEffect, useMemo, useState } from "react";
import { useAdminAuth } from "store/AdminAuth";
import FinalizedCMRTable, {
  SelectColumnFilter,
  StatusPill,
} from "../../components/admin/DataTable/FinalizedCMRTable";
import { useNavigate} from "react-router-dom";

export default function FinalizedIDPRequests() {
  const [loading, setLoading] = useState(false);
  const [precmrs, setPreCMRs] = useState([]);
  const [cccprecmrs, setcccPrecmrs] = useState([]);
  const masterTableData = useMemo(() => precmrs, [precmrs]);
  const commandCenterTableData = useMemo(() => cccprecmrs, [cccprecmrs]);
  const getAuth = useAdminAuth((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      fetch(`${base_url}${admin_idp_entries}?status=finalized`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          if (jsonResp.data != null) {
            setPreCMRs(jsonResp.data);
            setLoading(false);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      fetch(`${base_url}${ccchead_idp_entries}?status=finalized`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          if (jsonResp.data != null) {
            setcccPrecmrs(jsonResp.data);
            setLoading(false);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: () => <div className="w-22">Request Number</div>,
        accessor: "request_number",
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Middle Name",
        accessor: "middle_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Licence Class",
        accessor: "license_class",
      },
      {
        Header: "Date of Birth",
        accessor: "date_of_birth",
      },
      {
        Header: "Request State",
        accessor: "request_state",
      },
      {
        Header: "Eye Color",
        accessor: "eye_color",
        Cell: ({ cell }) => (
          <span className={`text-gray-100 px-4 py-1 rounded ${colorResolution(cell.row.values.eye_color)}`}>{cell.row.values.eye_color}</span>
        ),
        // Cell: StatusPill,
        // Filter: SelectColumnFilter,
      },
      {
        Header: "Finalized Date",
        accessor: "finalized_at",
        Cell: ({ cell }) => (
          <span>{formatDateNew(cell.row.values.assigned_at)}</span>
        ),
      },
      {
        Header: "IDP Number",
        accessor: "certificate_number",
      },
      {
        Header: "Command Centre",
        accessor: "cc",
        Filter: SelectColumnFilter,
      },
      // {
      //   Header: "Actions",
      //   accessor: "CMREntryId",
      //   Cell: ({ cell }) => (
      //     <>
      //       <button
      //         className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
      //         value={cell.row.values.id}
      //         disabled={true}
      //         onClick={() => {}}
      //       >
      //         Invoice
      //       </button>
      //       <button
      //         className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900 ml-3`}
      //         value={cell.row.values.id}
      //         disabled={true}
      //         onClick={() => {}}
      //       >
      //         View
      //       </button>
      //     </>
      //   ),
      // },
    ],
    []
  );

  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle International Drivers License Finalized List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <PreCMRTable
                columns={columns}
                data={masterTableData}
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-head", "cic-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <FinalizedCMRTable
                columns={columns}
                data={commandCenterTableData}
                action="link"
                loading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";

// components
import { HasAccess } from "@permify/react-role";
import Header from "components/admin/Headers/Header";
import InformationRequest from "./InformationRequest";
import {
  analyser_analysis,
  base_url,
  ccc_head_analysis,
  get_admin_command_center
} from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";
import Sidebar from "../../components/admin/SideBars/Sidebar";
import DashboardCard from "../../components/DashboardCard";
import ChangeOfOwnershipRequest from "./ChangeOfOwnershipRequest";
import { checkToken } from "components/checkToken";

export default function Dashboard() {
  const [menu, setMenu] = useState(1);
  const getAuth = useAdminAuth((state) => state.auth);
  const setAuth = useAdminAuth((state) => state.setAuth);
  const [analysisData, setAnalysisData] = useState({});
  const role = localStorage.getItem("__permifyUser");
  const roleJson = JSON.parse(role);

  const topCMRInfoData = () => {
    const ccc =
      roleJson.roles[0] === "ccc-head" || roleJson.roles[0] === "cic-head";
    return ccc
      ? analysisData?.cmr_request?.pending
      : analysisData?.cmr_request?.assigned;
  };

  const topCowData = () => {
    const ccc =
      roleJson.roles[0] === "ccc-head" || roleJson.roles[0] === "cic-head";
    return ccc
      ? analysisData?.cow_request?.pending
      : analysisData?.cow_request?.assigned;
  };

  
  useEffect(() => {
    //set admin command centre
    let admin_cc = base_url + get_admin_command_center;
    let token = getAuth.token;
    fetch(admin_cc, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        if (roleJson.roles[0] === "ccc-head") {
          setAuth({
            ...getAuth,
            cc: jsonResp.data,
          });
        }
      });
  }, []);

  if (
    getAuth.authenticated === false ||
    getAuth.token === undefined ||
    getAuth.token === ""
  ) {
    window.location = "/admin/login";
  }

  const getAnalysis = async () => {
    const token = getAuth.token;
    let url;
    roleJson.roles[0] === "ccc-head"
      ? (url = ccc_head_analysis)
      : (url = analyser_analysis);
    fetch(base_url + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setAnalysisData(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAnalysis();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="md:ml-64 bg-blue-100">
        <Header title="Dashboard" />
       <div>
       <div className="flex mx-4 py-1 gap-2 overflow-x-auto  ">
          <div className="flex flex-col justify-end gap-2 flex-1 ">
            <DashboardCard
              start
              top={`${
                getAuth.cc ? "Pending Assignment" : "Pending Processing"
              }`}
              bottom="Pending Approval"
            />
          </div>
          <div className="flex flex-col gap-2 flex-1 ">
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-cmr-information-analytic"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <div
                onClick={() => {
                  setMenu(1);
                }}
                className={`${
                  menu === 1 && "text-yellow-300"
                }  hover:text-yellow-100 cursor-pointer bg-gray-600  rounded  text-xs p-2 text-gray-100 h-[80px]`}
              >
                Motor Vehicle Information
              </div>
            </HasAccess>
            <DashboardCard
              top={topCMRInfoData() ? topCMRInfoData() : 0}
              bottom={
                analysisData?.cmr_request?.processed
                  ? analysisData?.cmr_request?.processed
                  : 0
              }
            />
          </div>

          <div className="flex flex-col gap-2 flex-1 ">
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-change-of-ownership-analytic"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <div
                onClick={() => {
                  setMenu(2);
                }}
                className={`${
                  menu === 2 && "text-yellow-300"
                }  hover:text-yellow-100 cursor-pointer bg-gray-600  rounded  text-xs p-2 text-gray-100 h-[80px]`}
              >
                Change of Vehicle Ownership
              </div>
            </HasAccess>
            <DashboardCard
              top={topCowData() ? topCowData() : 0}
              bottom={
                analysisData?.cow_request?.processed
                  ? analysisData?.cow_request?.processed
                  : 0
              }
            />
          </div>

          <div className="flex flex-col gap-2 flex-1 ">
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-change-of-body-analytic"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <div
                onClick={() => {
                  setMenu(3);
                }}
                className={`${
                  menu === 3 && "text-yellow-300"
                }  hover:text-yellow-100 cursor-pointer bg-gray-600  rounded  text-xs p-2 text-gray-100 h-[80px]`}
              >
                Change of Vehicle Chassis/Body
              </div>
            </HasAccess>

            <DashboardCard top={0} bottom={0} />
          </div>

          <div className="flex flex-col gap-2 flex-1 ">
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-change-of-engine-analytic"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <div
                onClick={() => {
                  setMenu(4);
                }}
                className={`${
                  menu === 4 && "text-yellow-300"
                }  hover:text-yellow-100 cursor-pointer bg-gray-600  rounded  text-xs p-2 text-gray-100 h-[80px]`}
              >
                Change of Vehicle Engine
              </div>
            </HasAccess>

            <DashboardCard top={0} bottom={0} />
          </div>

          <div className="flex flex-col gap-2 flex-1 ">
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-change-of-color-analytic"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <div
                onClick={() => {
                  setMenu(5);
                }}
                className={`${
                  menu === 5 && "text-yellow-300"
                }  hover:text-yellow-100 cursor-pointer bg-gray-600  rounded  text-xs p-2 text-gray-100 h-[80px]`}
              >
                Change of Vehicle Color
              </div>
            </HasAccess>

            <DashboardCard top={0} bottom={0} />
          </div>

          <div className="flex flex-col gap-2 flex-1 ">
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-international-driving-permit-analytic"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <div
                onClick={() => {
                  setMenu(6);
                }}
                className={`${
                  menu === 6 && "text-yellow-300"
                }  hover:text-yellow-100 cursor-pointer bg-gray-600  rounded  text-xs p-2 text-gray-100 h-[80px]`}
              >
                Int'l Driver's Permit
              </div>
            </HasAccess>
            <DashboardCard top={0} bottom={0} />
          </div>

          <div className="flex flex-col gap-2 flex-1 ">
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-international-certificate-for-vehicle-analytic"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <div
                onClick={() => {
                  setMenu(7);
                }}
                className={`${
                  menu === 7 && "text-yellow-300"
                }  hover:text-yellow-100 cursor-pointer bg-gray-600  rounded  text-xs p-2 text-gray-100 h-[80px] `}
              >
                Int'l Certificate For Vehicle
              </div>
            </HasAccess>

            <DashboardCard top={0} bottom={0} />
          </div>
          <div className="flex flex-col gap-2 flex-1 ">
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-tint-permit-analytic"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <div
                onClick={() => {
                  setMenu(8);
                }}
                className={`${
                  menu === 8 && "text-yellow-300"
                }  hover:text-yellow-100 cursor-pointer bg-gray-600  rounded  text-xs p-2 text-gray-100 h-[80px]`}
              >
                Tint Permit
              </div>
            </HasAccess>

            <DashboardCard top={0} bottom={0} />
          </div>

          <div className="flex flex-col gap-2 flex-1 ">
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-spy-plate-permit-analytic"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <div
                onClick={() => {
                  setMenu(9);
                }}
                className={`${
                  menu === 9 && "text-yellow-300"
                }  hover:text-yellow-100 cursor-pointer bg-gray-600  rounded  text-xs p-2 text-gray-100 h-[80px]`}
              >
                SPY Number Plate Permit
              </div>
            </HasAccess>

            <DashboardCard top={0} bottom={0} />
          </div>

          <div className="flex flex-col gap-2 flex-1 ">
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-stolen-vehicle-analytic"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <div
                onClick={() => {
                  setMenu(10);
                }}
                className={` hover:text-yellow-100 cursor-pointer bg-gray-600 rounded  text-xs p-2 text-gray-100 h-[80px] ${
                  menu === 10 && "text-yellow-300"
                }`}
              >
                Stolen Vehicle
              </div>
            </HasAccess>

            <DashboardCard top={0} bottom={0} />
          </div>
        </div>
       </div>
        {menu == 1 && (
          <>
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-cmr-information-analytic"
            >
              <InformationRequest />
            </HasAccess>
          </>
        )}

        {menu == 2 && (
          <>
            <HasAccess
              roles={["super-admin", "admin"]}
              permissions="view-cmr-information-analytic"
            >
              <ChangeOfOwnershipRequest />
            </HasAccess>
          </>
        )}

        {/* <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-cmr-information-analytic"
        >  */}
        {/* <div className={`${menu !== 1 && "hidden"}`}> */}
        {/* Motor vehicle Information Request analytics */}
        {/* <InformationRequest /> */}
        {/* </div> */}
        {/* </HasAccess> */}

        {/* <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-change-of-ownership-analytic"
        > */}
        {/* <div className={`${menu !== 2 && "hidden"}`}> */}
        {/* Motor vehicle Change of Ownership Request analytics */}
        {/* <ChangeOfOwnershipRequest /> */}
        {/* </div> */}
        {/* </HasAccess> */}

        {/* <div className={`${menu !== 2 && "hidden"}`}>
          <iframe
            className="w-full"
            width="1500"
            height="900"
            src="https://lookerstudio.google.com/embed/reporting/fb4b5932-b103-40f3-985c-63ba158889ed/page/Uq88C"
            frameBorder="0"
            // style="border:0"
            allowFullscreen
          ></iframe>
        </div> */}
        {/* <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-change-of-ownership-analytic"
          renderAuthFailed={
            <></>
            // <div className="text-red-700 h-screen text-center text-4xl mx-8">
            //   You do not have access to this resource
            // </div>
          }
        >
          <div className={`${menu !== 2 && "hidden"}`}>
            <div
              className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
            >
              CMR Motor Vehicle Vehicle Change of Ownershp Request
            </div>
            {getAuth.cc === "Abuja" && (
              <>
                <iframe
                  className="w-full"
                  src="https://lookerstudio.google.com/embed/reporting/21acd6e8-e8a2-4a40-a4ff-c0e88e95a644/page/Uq88C"
                  height="900"
                  width="1500"
                  title="CMR Motor Vehicle Change of Ownership"
                  style={{ border: "none" }}
                ></iframe>
              </>
            )}

            {getAuth.cc === "Lagos" && (
              <>
                <iframe
                  className="w-full"
                  src="https://lookerstudio.google.com/embed/reporting/fec220da-0293-4870-8ea9-22125410a4bd/page/Uq88C"
                  height="900"
                  width="1500"
                  title="CMR Motor Vehicle Change of Ownership"
                  style={{ border: "none" }}
                ></iframe>
              </>
            )}
            {(getAuth.cc === "" || getAuth.cc === undefined) && (
              <>
                <iframe
                  className="w-full"
                  width="1500"
                  height="900"
                  src="https://lookerstudio.google.com/embed/reporting/fb4b5932-b103-40f3-985c-63ba158889ed/page/Uq88C"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullscreen
                  title="CMR Motor Vehicle Change of Ownership"
                  allowFullScreen
                  title="CMR Motor Vehicle Information Request"
                ></iframe>
              </>
            )}
          </div>
        </HasAccess> */}

        {/* <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-change-of-body-analytic"
          renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
        >
          <div
            onClick={() => {
              setMenu(3);
            }}
            className={`${
              menu === 3 && "text-yellow-300"
            }  hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100`}
          >
            Change of Vehicle Chassis/Body
          </div>
        </HasAccess> */}

        {/* <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-change-of-body-analytic"
          renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
        >
          <div
            onClick={() => {
              setMenu(3);
            }}
            className={`${
              menu === 3 && "text-yellow-300"
            } flex-1 hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100`}
          >
            Change of Vehicle Chassis/Body
          </div>
        </HasAccess> */}

        {/* <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-change-of-body-analytic"
          renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
        >
          <div
            onClick={() => {
              setMenu(3);
            }}
            className={`${
              menu === 3 && "text-yellow-300"
            } flex-1 hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100`}
          >
            Change of Vehicle Chassis/Body
          </div>
        </HasAccess> */}

        <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-change-of-body-analytic"
          renderAuthFailed={
            <></>
            // <div className="text-red-700 h-screen text-center text-4xl mx-8">
            //   You do not have access to this resource
            // </div>
          }
        >
          <div className={`${menu !== 3 && "hidden"}`}>
            <div
              className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
            >
              CMR Motor Vehicle Change of Body/Chassis Request
            </div>
            <iframe
              src="https://lookerstudio.google.com/reporting/fb4b5932-b103-40f3-985c-63ba158889ed/page/Uq88C"
              // src="https://datastudio.google.com/embed/reporting/f29af657-2108-4ae7-ac39-af0f0fea2867/page/Uq88C"
              height="900"
              className="w-full"
              width="1500"
              title="CMR Motor Vehicle Change of Body/Chassis Request"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </HasAccess>

        <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-change-of-engine-analytic"
          renderAuthFailed={
            <></>
            // <div className="text-red-700 h-screen text-center text-4xl mx-8">
            //   You do not have access to this resource
            // </div>
          }
        >
          <div className={`${menu !== 4 && "hidden"}`}>
            <div
              className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
            >
              CMR Motor Vehicle Change of Engine Request
            </div>
            <iframe
              className="w-full"
              src="https://datastudio.google.com/embed/reporting/f29af657-2108-4ae7-ac39-af0f0fea2867/page/Uq88C"
              height="900"
              width="1500"
              title="CMR Motor Vehicle Change of Engine Request"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </HasAccess>

        <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-change-of-color-analytic"
          renderAuthFailed={
            <></>
            // <div className="text-red-700 h-screen text-center text-4xl mx-8">
            //   You do not have access to this resource
            // </div>
          }
        >
          <div className={`${menu !== 5 && "hidden"}`}>
            <div
              className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
            >
              CMR Motor Vehicle Change of Color Request
            </div>
            <iframe
              src="https://datastudio.google.com/embed/reporting/f29af657-2108-4ae7-ac39-af0f0fea2867/page/Uq88C"
              height="900"
              width="1500"
              title="CMR Motor Vehicle Change of Color Request"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </HasAccess>

        <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-international-driving-permit-analytic"
          renderAuthFailed={
            <></>
            // <div className="text-red-700 h-screen text-center text-4xl mx-8">
            //   You do not have access to this resource
            // </div>
          }
        >
          <div className={`${menu !== 6 && "hidden"}`}>
            <div
              className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
            >
              International Driving Permit Request
            </div>
            <iframe
              src="https://datastudio.google.com/embed/reporting/f29af657-2108-4ae7-ac39-af0f0fea2867/page/Uq88C"
              height="900"
              className="w-full"
              width="1500"
              title="International Driving Permit Request"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </HasAccess>

        <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-international-certificate-for-vehicle-analytic"
          renderAuthFailed={
            <></>
            // <div className="text-red-700 h-screen text-center text-4xl mx-8">
            //   You do not have access to this resource
            // </div>
          }
        >
          <div className={`${menu !== 7 && "hidden"}`}>
            <div
              className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
            >
              International Certificate for Vehicle Request
            </div>
            <iframe
              className="w-full"
              src="https://datastudio.google.com/embed/reporting/f29af657-2108-4ae7-ac39-af0f0fea2867/page/Uq88C"
              height="900"
              width="1500"
              title="International Certificate for Vehicke Request"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </HasAccess>

        <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-tint-permit-analytic"
          renderAuthFailed={
            <></>
            // <div className="text-red-700 h-screen text-center text-4xl mx-8">
            //   You do not have access to this resource
            // </div>
          }
        >
          <div className={`${menu !== 8 && "hidden"}`}>
            <div
              className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
            >
              Tint Permit Request
            </div>
            <iframe
              src="https://datastudio.google.com/embed/reporting/f29af657-2108-4ae7-ac39-af0f0fea2867/page/Uq88C"
              height="900"
              width="1500"
              className="w-full"
              title="Tint Permit Request"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </HasAccess>

        <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-spy-plate-permit-analytic"
          renderAuthFailed={<></>}
        >
          <div className={`${menu !== 9 && "hidden"}`}>
            <div
              className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
            >
              SPY Plate Number Permit Request
            </div>
            <iframe
              src="https://datastudio.google.com/embed/reporting/f29af657-2108-4ae7-ac39-af0f0fea2867/page/Uq88C"
              height="900"
              width="1500"
              className="w-full"
              title="SPY Plate Number Request"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </HasAccess>

        <HasAccess
          roles={["super-admin", "admin"]}
          permissions="view-stolen-vehicle-analytic"
          renderAuthFailed={
            <></>
            // <div className="text-red-700 h-screen text-center text-4xl mx-8">
            //   You do not have access to this resource
            // </div>
          }
        >
          <div className={`${menu !== 10 && "hidden"}`}>
            <div
              className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
            >
              Stolen Vehicle Reports
            </div>

            {getAuth.cc === "Abuja" && (
              <>
                <iframe
                  src="https://lookerstudio.google.com/embed/reporting/21acd6e8-e8a2-4a40-a4ff-c0e88e95a644/page/Uq88C"
                  height="900"
                  width="1500"
                  className="w-full"
                  title="Stolen Vehicle Reports"
                  style={{ border: "none" }}
                ></iframe>
              </>
            )}

            {getAuth.cc === "Lagos" && (
              <>
                <iframe
                  src="https://lookerstudio.google.com/embed/reporting/55d2aa7b-0927-494e-a26f-c70dbfac1331/page/Uq88C"
                  height="900"
                  width="1500"
                  className="w-full"
                  title="Stolen Vehicle Reports"
                  style={{ border: "none" }}
                ></iframe>
              </>
            )}
            {(getAuth.cc === "" || getAuth.cc === undefined) && (
              <>
                <iframe
                  src="https://datastudio.google.com/embed/reporting/34a1b1ce-cf48-486c-b06d-4aa4cff1a253/page/Uq88C"
                  height="900"
                  width="1500"
                  className="w-full"
                  title="Stolen Vehicle Reports"
                  style={{ border: "none" }}
                ></iframe>
              </>
            )}
          </div>
        </HasAccess>
      </div>
      {/* <FooterAdmin /> */}
    </>
  );
}

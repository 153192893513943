import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import Loader from "components/Loader";
import {
  base_url,
  get_admin,
  edit_admin_perm,
  get_locations,
} from "constants/endpoints";
import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";

const EditAdmin = () => {
  const { admin_id } = useParams();
  const getAuth = useAdminAuth((state) => state.auth);
  const [requestDetails, setRequestDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const [userPermissions, setUserPermissions] = useState([]);
  const [adminRoles, setAdminRoles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("");
  const [editLoading, setEditLoading] = useState(false);

  const [userPermissionsState, setUserPermissionsState] = useState([
    { 
      id: 1, 
      label: "Add User", 
      value: "register-admin-user", 
      checked: false 
    },
    { 
      id: 2, 
      label: "View User", 
      value: "view-admin-user", 
      checked: false 
    },
    {
      id: 3,
      label: "Modify User",
      value: "modify-admin-user",
      checked: false,
    },
    {
      id: 4,
      label: "Disable User",
      value: "disable-admin-user",
      checked: false,
    },
    {
      id: 5,
      label: "Register Compliance Officer",
      value: "register-compliance-officer",
      checked: false,
    },
    {
      id: 6,
      label: "Modify Compliance Officer",
      value: "modify-compliance-officer",
      checked: false,
    },
    {
      id: 7,
      label: "Disable Compliance Officer",
      value: "disable-compliance-officer",
      checked: false,
    },
    {
      id: 8,
      label: "View Compliance Officers",
      value: "view-compliance-officer",
      checked: false,
    },
    {
      id: 9,
      label: "Assign ANPR",
      value: "assign-anpr",
      checked: false,
    },
    {
      id: 10,
      label: "Unassign ANPR",
      value: "unassign-anpr",
      checked: false,
    },
    {
      id: 11,
      label: "Modify ANPR",
      value: "modify-anpr",
      checked: false,
    },
    {
      id: 12,
      label: "Enable/Disable ANPR",
      value: "disable-anpr",
      checked: false,
    },
    {
      id: 13,
      label: "Register Formation",
      value: "register-formation",
      checked: false,
    },
    {
      id: 14,
      label: "Modify Formation",
      value: "modify-formation",
      checked: false,
    },
    {
      id: 15,
      label: "Disable Formation",
      value: "disable-formation",
      checked: false,
    },
    {
      id: 16,
      label: "View Formation",
      value: "view-formation",
      checked: false,
    },
    {
      id: 17,
      label: "Report Stolen Vehicle",
      value: "report-stolen-vehicle",
      checked: false,
    },
    {
      id: 18,
      label: "Make Flash Report",
      value: "make-flash-report",
      checked: false,
    },
    {
      id: 19,
      label: "Stolen Vehicle",
      value: "approve-stolen-vehicle",
      checked: false,
    },
    {
      id: 20,
      label: "CMR Information",
      value: "approve-cmr-information",
      checked: false,
    },
    {
      id: 21,
      label: "Change of Ownership",
      value: "approve-change-of-ownership",
      checked: false,
    },
    {
      id: 22,
      label: "Change of Engine",
      value: "approve-change-of-engine",
      checked: false,
    },
    {
      id: 23,
      label: "SPY Plate Number Permit",
      value: "approve-spy-plate-permit",
      checked: false,
    },
    {
      id: 24,
      label: "Change of Body/Chassis",
      value: "approve-change-of-body",
      checked: false,
    },
    {
      id: 25,
      label: "International Driving Permit",
      value: "approve-international-driving-permit",
      checked: false,
    },
    {
      id: 26,
      label: "International Certificate for Vehicle",
      value: "approve-international-certificate-for-vehicle",
      checked: false,
    },
    {
      id: 27,
      label: "Change of Color",
      value: "approve-change-of-color",
      checked: false,
    },
    {
      id: 28,
      label: "Tint Permit",
      value: "approve-tint-permit",
      checked: false,
    },
    {
      id: 29,
      label: "Stolen Vehicle",
      value: "view-stolen-vehicle-analytic",
      checked: false,
    },
    {
      id: 30,
      label: "CMR Information",
      value: "view-cmr-information-analytic",
      checked: false,
    },
    {
      id: 31,
      label: "Change of Ownership",
      value: "view-change-of-ownership-analytic",
      checked: false,
    },
    {
      id: 32,
      label: "Change of Engine",
      value: "view-change-of-engine-analytic",
      checked: false,
    },
    {
      id: 33,
      label: "SPY Plate Number Permit",
      value: "view-spy-plate-permit-analytic",
      checked: false,
    },
    {
      id: 34,
      label: "Change of Body/Chassis",
      value: "view-change-of-body-analytic",
      checked: false,
    },
    {
      id: 35,
      label: "International Driving Permit",
      value: "view-international-driving-permit-analytic",
      checked: false,
    },
    {
      id: 36,
      label: "International Certificate for Vehicle",
      value: "view-international-certificate-for-vehicle-analytic",
      checked: false,
    },
    {
      id: 37,
      label: "Change of Color",
      value: "view-change-of-color-analytic",
      checked: false,
    },
    {
      id: 38,
      label: "Tint Permit",
      value: "view-tint-permit-analytic",
      checked: false,
    },
    {
      id: 39,
      label: "Make Enquiry",
      value: "make-enquiry",
      checked: false,
    },
    {
      id: 40,
      label: "Onboard CMR Assist Full Access",
      value: "onboard-assist-full-access",
      checked: false,
    }
  ]);

  const buildPermissions = (e, id, permissionsState, setPermissionsState) => {
    const { checked, value } = e.target;

    setPermissionsState((prevState) => {
      const updatedState = prevState.map((obj) => {
        if (obj.id === id) {
          return { ...obj, checked: checked };
        }
        return obj;
      });

      setUserPermissions(
        updatedState.filter((obj) => obj.checked).map((obj) => obj.value)
      );

      return updatedState;
    });
  };

  const editPermissonsCall = () => {
    setEditLoading(true);
    let token = getAuth.token;
    let url = base_url + edit_admin_perm + admin_id;

    let data = {
      roles: [adminRoles],
      permissions: userPermissions,
      location: currentLocation,
    };

    console.log(data);
    try {
      fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          Swal.fire({
            title: "Success!",
            text: "Admin Details Edit Successfully",
            icon: "success",
          }).then(() => {
            setEditLoading(false);
          });
        });
    } catch (e) {
      console.log(e);
      setEditLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    let url = base_url + get_admin + admin_id;
    let token = getAuth.token;
    try {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          setRequestDetails(jsonResp.data);

          const currentPermissions = jsonResp.data.permissions;
          // console.log(jsonResp.data);
          setAdminRoles(jsonResp.data.roles[0]);
          // console.log(jsonResp.data.roles[0]);
          setCurrentLocation(jsonResp.data.location_id);

          const updateStateAndPermissions = (
            prevState,
            setState,
            permissionsArray,
            userPermissions
          ) => {
            const updatedState = prevState.map((obj) => {
              const checked = permissionsArray.includes(obj.value);
              return { ...obj, checked };
            });

            setState(updatedState);

            const updatedPermissions = updatedState
              .filter((obj) => obj.checked)
              .map((obj) => obj.value);

            userPermissions.push(...updatedPermissions);
          };

          // Update userPermissionsState
          updateStateAndPermissions(
            userPermissionsState,
            setUserPermissionsState,
            currentPermissions,
            userPermissions
          );

          //   console.log(userPermissions);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let token = getAuth.token;
    try {
      fetch(base_url + get_locations, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          setLocations(jsonResp.data);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR User Management - Edit Admin" />
        <div className="bg-white mt-6 pt-8">
          {loading ? (
            <>
              <div className="px-8 mt-1 w-full text-lg leading-9 overflow-x-auto">
                <p className="text-2xl font-bold mb-5">Edit Admin</p>
                <div className="flex justify-center my-20">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <div className="px-8 mt-1 w-full text-lg leading-9 overflow-x-auto">
              <p className="text-2xl font-bold mb-5">Edit Admin</p>
              <button
                onClick={() => {
                  window.history.back();
                }}
                className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
              >
                &larr; Go Back
              </button>
              <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
                Officer Details
              </div>
              <div className="table-container">
                <div className="table">
                  <table>
                    <tbody>
                      <tr>
                        <td className="table-headers">First Name:</td>
                        <td className="text-blue-800 font-semibold">
                          {requestDetails.first_name}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-headers">Command:</td>
                        <td className="text-blue-800 font-semibold">
                          {requestDetails.command}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-headers">UserId:</td>
                        <td className="text-blue-800 font-semibold">
                          {" "}
                          {requestDetails.username}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table">
                  <table>
                    <tbody>
                      <tr>
                        <td className="table-headers">Last Name:</td>
                        <td className="text-blue-800 font-semibold">
                          {requestDetails.last_name}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-headers">Rank:</td>
                        <td className="text-blue-800 font-semibold">
                          {requestDetails.rank}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-headers">Roles:</td>
                        <td className="text-blue-800 font-semibold">
                          {requestDetails.roles}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table">
                  <table>
                    <tbody>
                      <tr>
                        <td className="table-headers">Phone No.:</td>
                        <td className="text-blue-800 font-semibold">
                          {requestDetails.phone}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-headers">Email:</td>
                        <td className="text-blue-800 font-semibold">
                          {requestDetails.email}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
                Edit Role, Location & Permissions
              </div>

              <div>
                <label
                  htmlFor="sub_services"
                  className="block text-gray-900 font-bold text-md mt-3"
                >
                  Select Admin User Role
                  <span className="text-red-500 text-lg"> *</span>
                </label>
                <select
                  value={adminRoles}
                  onChange={(e) => {
                    setAdminRoles(() => [e.target.value]);
                  }}
                  className="rounded p-1 w-full"
                >
                  <option value="">Select Admin User Role</option>
                  <option value="super-admin">Super Admin</option>
                  <option value="admin">Admin</option>
                  <option value="ccc-head">CCC Head</option>
                  <option value="ccc-analyser">CCC Analyser</option>
                  <option value="cic-head">CIC Head</option>
                  <option value="cic-operative">CIC Operative</option>
                  <option value="dashboard">Dashboard</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="sub_services"
                  className="block text-gray-900 font-bold text-md mt-3"
                >
                  Select CMR Formation
                  <span className="text-red-500 text-lg"> *</span>
                </label>
                <select
                  value={currentLocation}
                  onChange={(e) => {
                    setCurrentLocation(e.target.value);
                  }}
                  className="w-full rounded-lg border-2 h-12 text-lg border-blue-400 py-1 px-2"
                >
                  <option value="">Select CMR Formation</option>
                  {locations &&
                    locations.length > 0 &&
                    locations.map((location, index) => {
                      return (
                        <option key={index} value={location.id}>
                          {location.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div>
                <div className="flex mt-6">
                  <div className="flex-1 mr-3 px-3 py-1 border-2 rounded-lg border-blue-400">
                    <div className="font-semibold">Users Permission</div>
                    <div className="leading-loose">
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[0].value}
                            checked={userPermissionsState[0].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[0].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Add User
                        </label>
                      </div>

                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[1].value}
                            checked={userPermissionsState[1].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[1].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          View User
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[2].value}
                            checked={userPermissionsState[2].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[2].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Modify User
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[3].value}
                            checked={userPermissionsState[3].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[3].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Disable User
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 px-3 py-1 border-2 rounded-lg border-blue-400">
                    <div className="font-semibold">CMR Compliance Officer</div>
                    <div className="leading-loose">
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[4].value}
                            checked={userPermissionsState[4].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[4].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Register Compliance Officer
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[5].value}
                            checked={userPermissionsState[5].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[5].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Modify Compliance Officer
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[6].value}
                            checked={userPermissionsState[6].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[6].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Disable Compliance Officer
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[7].value}
                            checked={userPermissionsState[7].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[7].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          View Compliance Officers
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="flex-1 px-3 py-1 ml-3 border-2 rounded-lg border-blue-400">
                    <div className="font-semibold">ANPR</div>
                    <div className="leading-loose">
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[8].value}
                            checked={userPermissionsState[8].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[8].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Assign ANPR
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[9].value}
                            checked={userPermissionsState[9].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[9].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Unassign ANPR
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[10].value}
                            checked={userPermissionsState[10].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[10].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Modify ANPR
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[11].value}
                            checked={userPermissionsState[11].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[11].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Enable/Disable ANPR
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex mt-6">
                  <div className="flex-1 px-3 py-1 ml-3 border-2 rounded-lg border-blue-400">
                    <div className="font-semibold">CMR Formation</div>
                    <div className="leading-loose">
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[12].value}
                            checked={userPermissionsState[12].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[12].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Register Formation
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[13].value}
                            checked={userPermissionsState[13].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[13].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Modify Formation
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[14].value}
                            checked={userPermissionsState[14].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[14].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Disable Formation
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[15].value}
                            checked={userPermissionsState[15].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[15].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          View Formations
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="flex-1 px-3 py-1 ml-3 border-2 rounded-lg border-blue-400">
                    <div className="font-semibold">
                      Flash/Stolen/Vehicle Permissions
                    </div>
                    <div className="leading-loose">
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[16].value}
                            checked={userPermissionsState[16].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[16].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Report Stolen Vehicle
                        </label>
                      </div>
                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[17].value}
                            checked={userPermissionsState[17].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[17].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Make Flash Report
                        </label>
                      </div>

                      <div>
                        <label className="">
                          <input
                            type="checkbox"
                            className="mr-1 pr-2"
                            value={userPermissionsState[38].value}
                            checked={userPermissionsState[38].checked}
                            onChange={(e) =>
                              buildPermissions(
                                e,
                                userPermissionsState[38].id,
                                userPermissionsState,
                                setUserPermissionsState
                              )
                            }
                          />
                          Make Enquiry
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex mt-4">
                  <div className="mr-3 px-3 py-2 flex-1 rounded-lg border-blue-400 border-2">
                    <div className="font-semibold">Approval Permission</div>
                    <div className="flex">
                      <div className="flex-1">
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[18].value}
                              checked={userPermissionsState[18].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[18].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Stolen Vehicle
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[19].value}
                              checked={userPermissionsState[19].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[19].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            CMR Information
                          </label>
                        </div>

                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[20].value}
                              checked={userPermissionsState[20].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[20].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Change of Ownership
                          </label>
                        </div>

                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[21].value}
                              checked={userPermissionsState[21].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[21].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Change of Engine
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[22].value}
                              checked={userPermissionsState[22].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[22].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            SPY Plate Number Permit
                          </label>
                        </div>
                      </div>
                      <div className="flex-1">
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[23].value}
                              checked={userPermissionsState[23].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[23].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Change of Body/Chassis
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[24].value}
                              checked={userPermissionsState[24].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[24].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            International Driving Permit
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[25].value}
                              checked={userPermissionsState[25].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[25].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            International Certificate for Vehicle
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[26].value}
                              checked={userPermissionsState[26].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[26].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Change of Color
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[27].value}
                              checked={userPermissionsState[27].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[27].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Tint Permit
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                  <div className="px-3 py-2 flex-1 rounded-lg border-blue-400 border-2">
                    <div className="font-semibold">Analytics</div>
                    <div className="flex">
                      <div className="flex-1">
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[28].value}
                              checked={userPermissionsState[28].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[28].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Stolen Vehicle
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[29].value}
                              checked={userPermissionsState[29].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[29].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            CMR Information
                          </label>
                        </div>

                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[30].value}
                              checked={userPermissionsState[30].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[30].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Change of Ownership
                          </label>
                        </div>

                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[31].value}
                              checked={userPermissionsState[31].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[31].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Change of Engine
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[32].value}
                              checked={userPermissionsState[32].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[32].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            SPY Plate Number Permit
                          </label>
                        </div>
                      </div>
                      <div className="flex-1">
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[33].value}
                              checked={userPermissionsState[33].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[33].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Change of Body/Chassis
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[34].value}
                              checked={userPermissionsState[34].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[34].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            International Driving Permit
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[35].value}
                              checked={userPermissionsState[35].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[35].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            International Certificate for Vehicle
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[36].value}
                              checked={userPermissionsState[36].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[36].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Change of Color
                          </label>
                        </div>
                        <div>
                          <label className="">
                            <input
                              type="checkbox"
                              className="mr-1 pr-2"
                              value={userPermissionsState[37].value}
                              checked={userPermissionsState[37].checked}
                              onChange={(e) =>
                                buildPermissions(
                                  e,
                                  userPermissionsState[37].id,
                                  userPermissionsState,
                                  setUserPermissionsState
                                )
                              }
                            />
                            Tint Permit
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-4">
          <div className="px-3 py-2 flex-1 rounded-lg border-red-900 border-2">
              <div className="font-semibold">Other Permissions</div>
              <div className="flex">
                  <div className="flex-1">
                  <label className="">
                    <input
                      type="checkbox"
                      className="mr-1 pr-2"
                      value={userPermissionsState[39].value}
                      checked={userPermissionsState[39].checked}
                      onChange={(e) =>
                        buildPermissions(
                          e,
                          userPermissionsState[39].id,
                          userPermissionsState,
                          setUserPermissionsState
                        )
                      }
                    />
                      CMR Assist Full Access
                    </label>
                  </div>

                  <div className="flex-1">
                    {/* <label>
                      <input
                        type="checkbox"
                        className="pr-2 mr-1"
                        name="permissions"
                        value="onboard-assist-full-access"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      CMR Assist Full Access
                    </label> */}
                  </div>
                  <div className="flex-1">
                    {/* <label>
                      <input
                        type="checkbox"
                        className="pr-2 mr-1"
                        name="permissions"
                        value="onboard-assist-full-access"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      CMR Assist Full Access
                    </label> */}
                  </div>
                  <div className="flex-1">
                    {/* <label>
                      <input
                        type="checkbox"
                        className="pr-2 mr-1"
                        name="permissions"
                        value="onboard-assist-full-access"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      CMR Assist Full Access
                    </label> */}
                  </div>
                  <div className="flex-1">
                    {/* <label>
                      <input
                        type="checkbox"
                        className="pr-2 mr-1"
                        name="permissions"
                        value="onboard-assist-full-access"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      CMR Assist Full Access
                    </label> */}
                  </div>
              </div>
          </div>
      </div>
              <button
                onClick={() => editPermissonsCall()}
                className="hidden-print bg-blue-400 text-white px-2 py-1 my-7 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
              >
                {editLoading && <Loader />}
                {!editLoading && "Edit Permissions"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditAdmin;

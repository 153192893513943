import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  base_url,
  states as stateUrl,
  lgs as lgsUrl,
  create_location as creatLocUrl,
} from "constants/endpoints";
import Swal from "sweetalert2";
import { useAdminAuth } from "store/AdminAuth";
import Loader from "components/Loader";
import { input } from "@material-tailwind/react";
import chipCloseButtonColors from "@material-tailwind/react/theme/components/chip/chipCloseButtonColors";

export default function CreateLocation() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const getAuth = useAdminAuth((state) => state.auth);
  const [states, setStates] = useState([]);
  const [lgss, setLocalGovernments] = useState([]);
  const [inputs, setInputs] = useState({
    state: "",
    lga: "",
  });
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (inputs) => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;
    // console.log(JSON.parse(token));
    let data = {
      name: inputs.name,
      state: inputs.state,
      lg: inputs.lgs,
      address: inputs.address,
      phone: inputs.phone,
      email: inputs.email,
      zone: inputs.loc_zone,
      type: inputs.formation_type,
    };
    console.log(data);
    let url = base_url + creatLocUrl;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      });

      let jsonResponse = await response.json();
      console.log(jsonResponse);
      if (jsonResponse.statusCode == 0) {
        Swal.fire({
          title: "Success!",
          text: "CMR Formation created successfully!",
          icon: "success",
        }).then(() => {
          reset();
          setLoading(false);
        });
      } else if (jsonResponse.statusCode === 6) {
        Swal.fire({
          title: "Formation already added...!",
          text: "",
          icon: "info",
        }).then(() => {
          setLoading(false);
          return;
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Error creating CMR Formation!",
          icon: "error",
        });
        setLoading(false);
      }
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: e,
        icon: "error",
      }).then(() => {
        setLoading(false);
      });
    }
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.name);
    if (e.target.name == "state" || e.target.name == "stolenState") {
      getLgs(e.target.value);
    }
  };

  const getLgs = async (state) => {
    const url = base_url + lgsUrl + state;
    console.log(url);
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.statusCode == 0) {
          setLocalGovernments(resJson.data);
        }
      });
  };

  useEffect(() => {
    console.log(base_url + stateUrl);
    fetch(base_url + stateUrl)
      .then((res) => res.json())
      .then((resJson) => {
        setStates(resJson.data);
      });
  }, []);

  return (
    <div className="px-8 text-sm">
      <form className="flex justify-center items-center mt-8">
        <div className="grid grid-cols-2 gap-4">
          <p className="text-lg text-gray-900 font-bold col-span-2">
            Create CMR Formation
          </p>
          <div>
            <label htmlFor="name" className="block text-gray-900 text-md">
              Name of Formation
            </label>
            <input
              type="text"
              {...register("name", { required: true })}
              className="w-full h-12 text-md rounded-lg border-2 hover:border-green-800 py-1 px-2"
              aria-invalid={errors.name ? "true" : "false"}
            />
            {errors.name?.type === "required" && (
              <p role="alert" className="text-sm text-red-500">
                Name is required
              </p>
            )}
          </div>

          <div>
            <label htmlFor="state" className=" block text-gray-900 text-md">
              State
            </label>
            <select
              name="state"
              {...register("state", { required: true })}
              aria-invalid={errors.state ? "true" : "false"}
              onChange={handleChange}
              className="w-full rounded border-2 hover:border-green-800 py-1 px-2 h-12 text-md"
            >
              <option value="">Select State</option>
              {states.map((val, index) => {
                return (
                  <option key={index} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
            {errors.state?.type === "required" && (
              <p role="alert" className="text-sm text-red-500">
                State is required
              </p>
            )}
          </div>

          <div className="col-span-2">
            <label htmlFor="type" className="mt-3 block text-gray-900 text-md">
              Local Government
            </label>
            <select
              name="lgs"
              {...register("lgs", { required: true })}
              aria-invalid={errors.lga ? "true" : "false"}
              className="w-full rounded border-2 hover:border-green-800 py-1 px-2 h-12 text-md"
            >
              <option value="">Select Local Government</option>
              {lgss.map((val, index) => {
                return (
                  <option key={index} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
            {errors.lga?.type === "required" && (
              <p role="alert" className="text-sm text-red-500">
                Local Government is required
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="address"
              className="block mt-3 text-gray-900 text-md"
            >
              Address
            </label>
            <input
              type="text"
              {...register("address", { required: true })}
              className="w-full h-12 text-md rounded-lg border-2 hover:border-green-800 py-1 px-2"
              aria-invalid={errors.address ? "true" : "false"}
            />
            {errors.address?.type === "required" && (
              <p role="alert" className="text-sm text-red-500">
                Address is required
              </p>
            )}
          </div>

          <div>
            <label htmlFor="phone" className="block mt-3 text-gray-900 text-md">
              Phone
            </label>
            <input
              type="text"
              {...register("phone", { required: true })}
              className="w-full h-12 text-md rounded-lg border-2 hover:border-green-800 py-1 px-2"
              aria-invalid={errors.phone ? "true" : "false"}
            />
            {errors.phone?.type === "required" && (
              <p role="alert" className="text-sm text-red-500">
                Phone is required
              </p>
            )}
          </div>

          <div>
            <label htmlFor="name" className="block mt-3 text-gray-900 text-md">
              Email
            </label>
            <input
              type="email"
              {...register("email", { required: true })}
              className="w-full h-12 text-md rounded-lg border-2 hover:border-green-800 py-1 px-2"
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email?.type === "required" && (
              <p role="alert" className="text-sm text-red-500">
                Email is required
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="loc_zone"
              className="mt-3 block text-gray-900 text-md"
            >
              Formation Zone
            </label>
            <select
              name="loc_zone"
              {...register("loc_zone", { required: true })}
              aria-invalid={errors.loc_zone ? "true" : "false"}
              className="w-full rounded border-2 hover:border-green-800 py-1 px-2 h-12 text-md"
            >
              <option value="">Select a zone</option>
              <option value="North Central">North Central</option>
              <option value="North West">North West</option>
              <option value="North East">North East</option>
              <option value="South West">South West</option>
              <option value="South South">South South</option>
              <option value="South East">South East</option>
            </select>
            {errors.loc_zone?.type === "required" && (
              <p role="alert" className="text-sm text-red-500">
                Formation zone is required
              </p>
            )}
          </div>

          <div className="col-span-2">
            <label
              htmlFor="loc_zone"
              className="mt-3 block text-gray-900 text-md"
            >
              CMR Formation Type
            </label>
            <select
              name="loc_zone"
              {...register("formation_type", { required: true })}
              aria-invalid={errors.formation_type ? "true" : "false"}
              className="w-full rounded border-2 hover:border-green-800 py-1 px-2 h-12 text-md"
            >
              <option value="">Select CMR Formation Type</option>
              <option value="cc">Command Centre</option>
              <option value="cic">CMR Information Center</option>
            </select>
            {errors.formation_type?.type === "required" && (
              <p role="alert" className="text-sm text-red-500">
                CMR Formation Type is required
              </p>
            )}
          </div>
          <div
            className={`bg-lightBlue-700 rounded-lg w-32 cursor-pointer h-12 pt-3 text-center mt-3 text-gray-100 px-3 py-1`}
            onClick={handleSubmit(onSubmit)}
          >
            Submit {isLoading && <Loader />}
          </div>
        </div>
      </form>
    </div>
  );
}

import * as yup from "yup";

export const addAgentSchema = yup
  .object({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    middleName: yup.string(),
    currentPhoneNumber: yup
      .string()
      .matches(/^[0-9]{11}$/, "Phone number must be 11 digits")
      .required(),
    pulledEmail: yup.string().email(),
    currentEmailAddress: yup.string().email().required(),
    registeredPhoneNumber: yup
      .string()
      .matches(/^[0-9]{11}$/, "Phone number must be 11 digits"),
    registeredState: yup.string().required(),
    regsteredLg: yup.string().required(),
    registeredAddress: yup.string(),

    currentState: yup.string().required(),
    currentLga: yup.string().required(),
    currentAddress: yup.string().required(),
    gender: yup.string(),

    nin: yup
      .string()
      .matches(/^\d{10,}$/, "NIN must be a number with at least 10 digits")
      .required()
  })
  .required();

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  base_url,
  correct_cmr_initiate,
  states as stateUrl,
  vehicle_types,
  manufacturers as manufacturerUrl,
  models as modelUrl,
} from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";
import useModalStore from "store/ModalState";
import Loader from "components/Loader";
import { useOldCorrectionStore } from "store/OldValuesStore";
import colors from "constants/colors";

const CmrCorrectionModal = () => {
  const { id } = useParams();
  const getAuth = useAdminAuth((state) => state.auth);
  const correctionDetails = useOldCorrectionStore(
    (state) => state.correctionDetails
  );
  // const toggleModal = useModalStore((state) => state.toggleModal);
  // const showModal = useModalStore((state) => state.showModal);
  
  const { modals, toggleModal, setModalState } = useModalStore();
  
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [correctionType, setCorrectionType] = useState("");
  const [CorrectionObject, setCorrectionObject] = useState({
    vehicle_id: id,
    property: "",
    old_value: "",
    new_value: "",
    reason: "",
  });

  const vehicleStatus = [
    "Valid",
    "Conflict",
    "Stolen",
    "Smuggled",
    "Incident"
  ];
  
  const vehicleUsage =[
    "Private",
    "Commercal",
    "Government"
  ];

  const handleToggleModal = (id) => {
    toggleModal(id);
  };
  
  useEffect(() => {
    //set the correctionDetails old value when the correctionType is already determined
    //And this should happend everytime the correctionType changes
    setCorrectionObject((prevObj) => ({
      ...prevObj,
      old_value: correctionDetails[correctionType],
    }));

    console.log(CorrectionObject)
  }, [correctionType]);

  const handleCorrectionInputChange = (key, value) => {
    setCorrectionObject((prevObj) => ({
      ...prevObj,
      [key]: value,
    }));
  };

  const [states, setStates] = useState([]);
  const [vehicleTypes, setvehicleTypes] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  let selectInputCorrectionType = [
    "vehicle_type",
    "mla",
    "year",
    "color",
    "manufacturer",
    "model",
    "vehicle_usage",
    // "license_plate",
    // "chassis_number"
    // "vehicle_status"
  ];
  const currentYear = new Date().getFullYear();
  const startYear = 1987;
  const yearsArray = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => currentYear - index
  );

  const correctCmrInfo = () => {
    setConfirmationLoading(true);
    let token = getAuth.token;
    let url = base_url + correct_cmr_initiate;
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(CorrectionObject),
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        console.log(jsonResp);
        if (jsonResp.statusCode === 0) {
          Swal.fire({
            title: "Successful",
            text: "",
            icon: "success",
          }).then(() => {
            setConfirmationModal(false);
            handleToggleModal("correction");
            // window.location.reload();
          });
        }
        if (jsonResp.statusCode === 6) {
          Swal.fire({
            title: "Failed",
            text: "Value already exists",
            icon: "error",
          }).then(() => {
            setConfirmationModal(false);
            handleToggleModal("correction");
            // window.location.reload();
          });
        }
        setConfirmationLoading(false);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error Occured",
          text: "Could not make correction happen!",
          icon: "error",
        }).then(() => {
          setConfirmationModal(false);
        });
        setConfirmationLoading(false);
      });
  };

  useEffect(() => {
    fetch(base_url + stateUrl)
      .then((res) => res.json())
      .then((resJson) => {
        setStates(resJson.data);
      });
  }, []);
  useEffect(() => {
    fetch(base_url + vehicle_types + "motorvehicle")
      .then((res) => res.json())
      .then((resJson) => {
        setvehicleTypes(resJson.data);
      });
  }, []);
  useEffect(() => {
    fetch(base_url + manufacturerUrl + "motorvehicle")
      .then((res) => res.json())
      .then((resJson) => {
        console.log(resJson.data);
        setManufacturers(resJson.data);
      });
  }, []);
  useEffect(() => {
    fetch(
      base_url + modelUrl + correctionDetails["manufacturer"] + "/motorvehicle"
    )
      .then((res) => res.json())
      .then((resJson) => {
        console.log(resJson.data);
        setModels(resJson.data);
      });
  }, [correctionDetails["manufacturer"]]);
  return (
    <>
      {/* Correction Modals */}
      {modals.correction && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <span
                    className="float-right font-bold text-2xl cursor-pointer"
                    onClick={() => handleToggleModal("correction")}
                  >
                    &#x2715;
                  </span>
                  <h1 className="font-bold">Vehicle Data Correction</h1>
                  <form className="flex flex-col gap-y-4 mt-10">
                    <div>
                      <label>Select Data Field</label>
                      <select
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        value={correctionType}
                        onChange={(e) => {
                          setCorrectionType(e.target.value);
                          handleCorrectionInputChange(
                            "property",
                            e.target.value
                          );
                          handleCorrectionInputChange("new_value", "");
                        }}
                      >
                        <option value="">Select an Option</option>
                        <option value="license_plate">License Number</option>
                        <option value="chassis_number">Chassis Number/VN</option>
                        <option value="engine_number">Engine Number</option>
                        <option value="manufacturer">Make/Manufacturer</option>
                        <option value="model">Model</option>
                        <option value="vehicle_type">Type</option>
                        <option value="year">Year of Manufacture</option>
                        <option value="color">Colour</option>
                        <option value="vehicle_usage">Vehicle Usage</option>
                        <option value="mla">Licencing Authority State</option>
                        
                        
                        {/* <option value="vehicle_status">Watchlist Status</option> */}
                      </select>
                    </div>

                    {correctionType !== "" && (
                      <>
                        <div>
                          <label>Old Value</label>
                          <input
                            className="rounded-md h-10 inline w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-black bg-gray-200 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                            value={correctionDetails[correctionType]}
                            disabled={true}
                          />
                        </div>
                        <div>
                          <label>New Value</label>

                          {selectInputCorrectionType.includes(
                            correctionType
                          ) ? (
                            <select
                              className="rounded-md h-10 inline w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                              value={CorrectionObject?.new_value}
                              onChange={(e) => {
                                handleCorrectionInputChange(
                                  "new_value",
                                  e.target.value
                                );
                              }}
                            >
                              <option>Select an option</option>
                              {correctionType === "vehicle_type" &&
                                vehicleTypes.map((types, index) => {
                                  return (
                                    <option key={index} value={types}>
                                      {types}
                                    </option>
                                  );
                                })}
                              {correctionType === "year" &&
                                yearsArray.map((years, index) => {
                                  return (
                                    <option key={index} value={years}>
                                      {years}
                                    </option>
                                  );
                                })}
                              {correctionType === "mla" &&
                                states.map((state, index) => {
                                  return (
                                    <option key={index} value={state}>
                                      {state}
                                    </option>
                                  );
                                })}
                              {correctionType === "color" &&
                                colors.map((color, index) => {
                                  return (
                                    <option key={index} value={color}>
                                      {color}
                                    </option>
                                  );
                                })}
                              {correctionType === "manufacturer" &&
                                manufacturers.map((items, index) => {
                                  return (
                                    <option key={index} value={items}>
                                      {items}
                                    </option>
                                  );
                                })}
                              {correctionType === "model" &&
                                models.map((items, index) => {
                                  return (
                                    <option key={index} value={items}>
                                      {items}
                                    </option>
                                  );
                                })}

                              {correctionType === "vehicle_status" &&
                                vehicleStatus.map((items, index) => {
                                  return (
                                    <option key={index} value={items}>
                                      {items}
                                    </option>
                                  );
                                })}

                              {correctionType === "vehicle_usage" &&
                                vehicleUsage.map((items, index) => {
                                  return (
                                    <option key={index} value={items}>
                                      {items}
                                    </option>
                                  );
                                })}
                            </select>
                          ) : (
                            <input
                              className="rounded-md h-10 inline w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                              value={CorrectionObject?.new_value}
                              onChange={(e) => {
                                handleCorrectionInputChange(
                                  "new_value",
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        </div>
                        <div>
                          <label>Reason for Correction</label>
                          <input
                            className="rounded-md h-10 inline w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                            value={CorrectionObject?.reason}
                            onChange={(e) => {
                              handleCorrectionInputChange(
                                "reason",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <div>
                          <button
                            className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                            onClick={(e) => {
                              e.preventDefault();
                              console.log(CorrectionObject);
                              setConfirmationModal(true);
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Correction Modals */}

      {/* Confirmation Modal */}
      {confirmationModal === true && (
        <div
          className="relative z-20"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <p>Are you sure you want to proceed?</p>
                  <div className="mt-5">
                  <button
                      className="hidden-print border border-blue-400 bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                      onClick={() => {
                        correctCmrInfo();
                      }}
                    >
                      {confirmationLoading ? <Loader /> : "Yes"}
                    </button>
                    <button
                      className="hidden-print border mr-2 border-black text-black px-2 py-1 rounded hover:text-black ml-3"
                      onClick={() => {
                        setConfirmationModal(false);
                      }}
                    >
                      No
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Confirmation Modal */}
    </>
  );
};

export default CmrCorrectionModal;

import { HasAccess } from "@permify/react-role";
import { useFetchFunc } from "Hooks/fetchFunc";
import { useAuth } from "Hooks/useAuth";
import ChangeOwnershipRequestTable from "components/admin/DataTable/ChangeOwnership/Admin/ChangeOwnershipRequestTable";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import { get_admin_cow, get_ccc_cow } from "constants/endpoints";
import { useEffect, useMemo, useState } from "react";
import { formatDateNew } from "utils/date_format";
import GeneralChangeOwnershipTable, {
  SelectColumnFilter,
  StatusPill,
} from "../../components/admin/DataTable/ChangeOwnership/Head/GeneralChangeOwnershipTable";

export default function AssignedCMRRequests() {
  const [loading, setLoading] = useState(false);
  const [adminCow, setadminCow] = useState([]);
  const [cccCow, setcccCow] = useState([]);
  const masterTableData = useMemo(() => adminCow, [adminCow]);
  const commandCenterTableData = useMemo(() => cccCow, [cccCow]);
  useAuth();
  const fetchFunc = useFetchFunc();

  useEffect(() => {
    fetchFunc(`${get_admin_cow}?status=Assigned`, setadminCow, setLoading);
    fetchFunc(`${get_ccc_cow}?status=Assigned`, setcccCow, setLoading);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: () => <div className="w-full">Request Number</div>,
        accessor: "request_number",
      },
      {
        Header: "Current Owner Name",
        accessor: "current_owner_name",
      },
      {
        Header: "New Owner Name",
        accessor: "new_owner_name",
      },
      {
        Header: "Licence Number",
        accessor: "license_plate",
      },
      {
        Header: "Chassis Number",
        accessor: "chassis_number",
      },
      {
        Header: "Make/Model",
        accessor: "vehicle_make",
      },
      {
        Header: "Request State",
        accessor: "request_state",
      },
      {
        Header: "Watchlist",
        accessor: "vehicle_status",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Command Centre",
        accessor: "command_center",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Analyzer",
        accessor: "assigned_to",
      },
      {
        Header: "Assigned Date",
        accessor: "assigned_at",
        Cell: ({ cell }) => (
          <span>{formatDateNew(cell.row.values.AssignedAt)}</span>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Change of Ownership Request List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <ChangeOwnershipRequestTable
                columns={columns}
                data={masterTableData}
                action="link"
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-head", "cic-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <GeneralChangeOwnershipTable
                columns={columns}
                data={commandCenterTableData}
                action="link"
                loading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import Sidebar from "components/admin/SideBars/Sidebar";
import Header from "components/admin/Headers/Header";
import FinalizedCMRTable, {
  StatusPill,
  SelectColumnFilter,
} from "../../components/admin/DataTable/FinalizedCMRTable";
import PreCMRTableAdmin, {
  StatusPill as StatusP,
  SelectColumnFilter as SelectColumnF,
} from "../../components/admin/DataTable/PreCMRTableAdmin";
import { HasAccess } from "@permify/react-role";
import {
  base_url,
  assigned_cmr_requests,
  pre_cmr_by_cc,
  get_ccc_request,
  get_admin_request,
} from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";
import { formatDateNew } from "utils/date_format";
import PreCMRTable from "components/admin/DataTable/PreCMRTable";

export default function AssignedCMRRequests() {
  const [loading, setLoading] = useState(false);
  const [precmrs, setPreCMRs] = useState([]);
  const [cccprecmrs, setcccPrecmrs] = useState([]);
  const masterTableData = useMemo(() => precmrs, [precmrs]);
  const commandCenterTableData = useMemo(() => cccprecmrs, [cccprecmrs]);
  const getAuth = useAdminAuth((state) => state.auth);

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      // fetch(base_url + assigned_cmr_requests, {
      fetch(`${base_url}${get_admin_request}?status=assigned`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          setLoading(false);
          if (jsonResp.data != null) {
            setPreCMRs(jsonResp.data);
            console.log(jsonResp.data);
          }
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      setLoading(true);
      // fetch(base_url + pre_cmr_by_cc, {
      fetch(`${base_url}${get_ccc_request}?status=assigned`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          if (jsonResp.data != null) {
            setcccPrecmrs(jsonResp.data);
            setLoading(false);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: () => <div className="w-22">Request Number</div>,
        accessor: "RequestNumber",
      },
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Licence Number",
        accessor: "LicensePlate",
      },
      {
        Header: "Chassis Number",
        accessor: "ChassisNumber",
      },
      {
        Header: "Make/Model",
        accessor: "Model",
      },
      {
        Header: "Vehicle Status",
        accessor: "VehicleStatus",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Analyzer",
        accessor: "AssignedTo",
      },
      {
        Header: "Assigned Date",
        accessor: "AssignedAt",
        Cell: ({ cell }) => (
          <span>{formatDateNew(cell.row.values.AssignedAt)}</span>
        ),
      },
      {
        Header: "ID Status",
        accessor: "OwnerStatus",
        Filter: SelectColumnFilter,
        Cell: StatusPill,
      },
      {
        Header: "Command Centre",
        accessor: "CommandCenter",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "CMREntryId",
        Cell: ({ cell }) => (
          <>
            <div className="flex flex-row">
              <a
                className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
                // value={cell.row.values.id}
                disabled={true}
                // onClick={() => {}}
                href={"/admin/precmr/" + cell.row.values.CMREntryId}
              >
                Details
              </a>
            </div>
          </>
        ),
      },
      // {
      //   Header: "Actions",
      //   accessor: "CMREntryId",
      //   Cell: ({ cell }) => (
      //     <>
      //       <button
      //         className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
      //         value={cell.row.values.id}
      //         disabled={true}
      //         onClick={() => {}}
      //       >
      //         Invoice
      //       </button>
      //       <button
      //         className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900 ml-3`}
      //         value={cell.row.values.id}
      //         disabled={true}
      //         onClick={() => {}}
      //       >
      //         View
      //       </button>
      //     </>
      //   ),
      // },
    ],
    []
  );

  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Information Requests List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <PreCMRTable
                columns={columns}
                data={masterTableData}
                action="link"
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <FinalizedCMRTable
                columns={columns}
                data={commandCenterTableData}
                action="link"
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-analyser"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Assigned table */}
              <FinalizedCMRTable
                columns={columns}
                data={commandCenterTableData}
                action="link"
                loading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

const vehicle_types = [
  "Bus",
  "Convertible",
  "Coupe",
  "Hatchback",
  "Mini-van",
  "Motorcycle",
  "Pickup",
  "Saloon/Sedan",
  "Scooter",
  "Space Bus",
  "Sports Car",
  "Station Wagon",
  "SUV",
  "Trailer",
  "Tricycle",
];

export default vehicle_types;

import React from "react";
import AddModel from "../../components/admin/AddModel";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";

function Model() {
  return (
    <>
      <div className="">
        <Sidebar />
        <div className="md:ml-64 bg-blueGray-600">
          <Header title="Add Vehicle Model" />
        </div>
        <div className="pl-64 mx-auto">
          <AddModel />
        </div>
      </div>
    </>
  );

  // <Profile />;
}

export default Model;

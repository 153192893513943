import { HasAccess } from "@permify/react-role";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import { classNames } from "../../shared/Utils";
import {
  base_url,
  get_analyser_request,
  admin_idp_entries,
  correct_cmr_list,
  correct_cmr_approve,
  confirm_cmr,
} from "constants/endpoints";
import { useEffect, useMemo, useState } from "react";
import { useAdminAuth } from "store/AdminAuth";
import Table, {
  StatusPill,
  SelectColumnFilter,
} from "../../components/admin/DataTable/Table";
import PreCMRTableAdmin from "../../components/admin/DataTable/PreCMRTableAdmin";
import PreCMRTable from "../../components/admin/DataTable/PreCMRTable";
import InputInline from "components/InputInline";
import Select from "components/admin/Forms/Select";
import Loader from "components/Loader";
import Swal from "sweetalert2";

export default function CorrectionApproval() {
  const [loading, setLoading] = useState(false);
  const [precmrs, setPreCMRs] = useState([]);
  const masterTableData = useMemo(() => precmrs, [precmrs]);
  const getAuth = useAdminAuth((state) => state.auth);
  const [enquiryState, setEnquiryState] = useState("CMR Information");
  const [correctionStatus, setCorrectionStatus] = useState("Pending");

  const [vehicleId, setVehicleId] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationType, setConfirmationType] = useState("Approved");
  const [confirmationLoading, setconfirmationLoading] = useState(false);
  const [approvalObject, setApprovalObject] = useState({
    approval_note: "NA",
  });
  const handleCorrectionInputChange = (key, value) => {
    setApprovalObject((prevObj) => ({
      ...prevObj,
      [key]: value,
    }));
  };

  const approveCorrection = () => {
    setconfirmationLoading(true);
    let token = getAuth.token;
    try {
      fetch(
        base_url + correct_cmr_approve + vehicleId + "/" + confirmationType +  "?reason=" + approvalObject.approval_note,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          // body: JSON.stringify(approvalObject)
        }
      )
        .then((response) => {
          console.log(response);
          if (response.ok) {
            console.log("repsonse ok", response);
            return response.json();
          } else {
            console.log("repsonse not ok");
          }
        })
        .then((jsonResp) => {
          console.log(jsonResp);
          Swal.fire({
            title: "Successfully Corrected",
            text: "Your Correction have been Updated",
            icon: "success",
          }).then(() => {
            setConfirmationModal(false);
            window.location.reload();
          });
          setconfirmationLoading(false);
        });
    } catch (e) {
      console.log(e);
      setconfirmationLoading(false);
      Swal.fire({
        title: "Error Occured",
        text: "",
        icon: "error",
      }).then(() => {
        setConfirmationModal(false);
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        // Header: () => (
        //   <div className="w-16">
        //     Licence Number
        //   </div>
        // ),
        Header: "Licence Number",
        accessor: "license_plate",
      },
      {
        Header: "Chassis Number",
        accessor: "chassis_number",
      },
      {
        Header: "Correction Type",
        accessor: "property",
      },
      {
        Header: "Old Value",
        accessor: "old_value",
      },
      {
        Header: "New Value",
        accessor: "new_value",
      },
      {
        Header: "Licence State",
        accessor: "request_state",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Requested By",
        accessor: (row) =>
          `${row.requested_by_firsty_name} ${row.requested_by_last_name}`,
        Cell: ({ cell }) => <p>{cell.value}</p>,
      },
      {
        Header: "Approved By",
        accessor: (row) =>
          `${row.approved_by_firsty_name} ${row.approved_by_last_name}`,
        Cell: ({ cell }) => <p>{cell.value}</p>,
      },
      // {
      //   Header: "Watchlist Status",
      //   accessor: "vehicle_status",
      //   // Cell: StatusPill,
      //   Cell: ({ cell }) => (
      //     <>
      //       <a
      //         value={cell.row.values.vehicle_status}
      //         className={classNames(
      //           "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
      //           cell.row.values.vehicle_status === "Valid"
      //             ? "bg-green-100 text-green-700"
      //             : null,
      //           cell.row.values.vehicle_status === "Conflict"
      //             ? "bg-red-100 text-red-700"
      //             : null,
      //           cell.row.values.vehicle_status === "Incident"
      //             ? "bg-yellow-500 text-red-800"
      //             : null,
      //           cell.row.values.vehicle_status === "Stolen"
      //             ? "bg-red-700 text-red-100"
      //             : null
      //         )}
      //         href="#"
      //       >
      //         {cell.row.values.vehicle_status}
      //       </a>
      //     </>
      //   ),
      //   Filter: SelectColumnFilter,
      // },
      {
        Header: "Actions",
        accessor: "id",
        show: correctionStatus !== "Pending" ? false : false,
        Cell: ({ cell }) => (
          <>
            <button
              className="bg-blue-400 text-white py-2 px-3 rounded-md h-12 ml-2"
              onClick={() => {
                setConfirmationType("Approved");
                setVehicleId(cell.value);
                setConfirmationModal(true);
              }}
            >
              Approve
            </button>
            <button
              className="bg-red-400 text-white py-2 px-3 rounded-md h-12 ml-2"
              onClick={() => {
                setConfirmationType("Declined");
                setVehicleId(cell.value);
                setConfirmationModal(true);
              }}
            >
              Reject
            </button>
          </>
        ),
      },
    ],
    [correctionStatus]
  );

  const updatedColumns =
    correctionStatus !== "Pending" ? columns.slice(0, -1) : columns;

  useEffect(() => {
    setLoading(true);
    let token = getAuth.token;
    try {
      fetch(base_url + correct_cmr_list + correctionStatus, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.ok) {
            console.log("repsonse ok");
            return response.json();
          } else {
            console.log("repsonse not ok");
          }
        })
        .then((jsonResp) => {
          console.log(jsonResp?.data);
          jsonResp?.data !== null && setPreCMRs(jsonResp?.data);
          jsonResp?.data === null && setPreCMRs([]);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [correctionStatus]);

  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title={`Corections ${enquiryState}`} />
        <div className="bg-white mt-6 pt-8">
          <div className="mb-4 ml-5">
            <button
              onClick={() => setCorrectionStatus("Pending")}
              className={`${
                correctionStatus === "Pending"
                  ? "bg-blue-400 text-white"
                  : "border border-blue-400 text-blue-400"
              } py-2 px-3 rounded-md h-12 ml-2`}
            >
              Pending
            </button>
            <button
              onClick={() => setCorrectionStatus("Approved")}
              className={`${
                correctionStatus === "Approved"
                  ? "bg-green-400 text-white"
                  : "border border-green-400 text-green-400"
              } py-2 px-3 rounded-md h-12 ml-2`}
            >
              Approved
            </button>
            <button
              onClick={() => setCorrectionStatus("Declined")}
              className={`${
                correctionStatus === "Declined"
                  ? "bg-red-400 text-white"
                  : "border border-red-400 text-red-400"
              } py-2 px-3 rounded-md h-12 ml-2`}
            >
              Declined
            </button>
          </div>
          <div className="px-8 w-full">
            <HasAccess
              roles={["super-admin", "admin", "ccc-head", "ccc-analyser"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <PreCMRTable
                columns={updatedColumns}
                data={masterTableData}
                loading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
      {/* Confirmation Modal */}
      {confirmationModal === true && (
        <div
          className="relative z-20"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <p className="mb-4 font-semibold">
                    {confirmationType.slice(0, -1)} Correction
                  </p>
                  {confirmationType === "Approved" && (
                    <p>Are you sure?</p>
                  )}
                  {confirmationType === "Declined" && (
                    <textarea
                      className="rounded-md h-10 inline w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                      placeholder="Enter Reason for Declining Correction"
                      rows={6}
                      value={approvalObject?.approval_note}
                      onChange={(e) => {
                        handleCorrectionInputChange(
                          "approval_note",
                          e.target.value
                        );
                      }}
                    />
                  )}
                  <div className="mt-5">
                  <button
                      className="hidden-print border border-blue-400 bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 disabled:bg-blue-200 disabled:border-blue-200 cursor-pointer hover:text-black"
                      disabled={
                        !approvalObject?.approval_note &&
                        confirmationType === "Declined"
                          ? true
                          : false
                      }
                      onClick={() => {
                        approveCorrection();
                      }}
                    >
                      {confirmationLoading ? <Loader /> : "Yes"}
                    </button>

                    <button
                      className="hidden-print border mr-2 border-black text-black px-2 py-1 rounded hover:text-black"
                      onClick={() => {
                        setConfirmationModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Confirmation Modal */}
    </div>
  );
}

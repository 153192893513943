import React, { useState, useEffect } from "react";
import {
  base_url,
  get_permissions,
  create_permission,
} from "constants/endpoints";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import Loader from "components/Loader";
import { useAdminAuth } from "store/AdminAuth";

export default function RegisterPermission() {
  const [loadingState, setLoadingState] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const getAuth = useAdminAuth((state) => state.auth);

  const onSubmit = (data) => {
    setLoadingState(true);
    let token = getAuth.token;
    try {
      fetch(base_url + create_permission, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          if (jsonResp.statusCode === 0) {
            Swal.fire({
              title: "Success!",
              text: "Permission Created successfully!",
              icon: "success",
            }).then(() => {
              setLoadingState(false);
              reset();
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="px-8 pt-2 text-sm">
      <form>
        <div className="lg:w-1/3">
          <p className="text-xl text-gray-900 font-semi-bold mb-3">
            Create Permission
          </p>
          <label
            htmlFor="permission_name"
            className="block text-gray-900 text-md"
          >
            Name
          </label>
          <input
            type="text"
            {...register("desc", { required: true })}
            className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
            aria-invalid={errors.name ? "true" : "false"}
          />
          {errors.name?.type === "required" && (
            <p role="alert" className="text-sm text-red-500">
              Name is required
            </p>
          )}

          <label
            htmlFor="location_id"
            className="mt-3 block text-gray-900 text-md"
          >
            Permission
          </label>
          <input
            type="text"
            {...register("name", { required: true })}
            className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
            aria-invalid={errors.desc ? "true" : "false"}
          />
          {errors.desc?.type === "required" && (
            <p role="alert" className="text-sm text-red-500">
              Permission is required
            </p>
          )}
          <div className="flex">
            <div
              className={`flex-1 h-8 bg-lightBlue-700 rounded-lg cursor-pointer pt-1 text-center mt-3 text-gray-100 `}
              onClick={handleSubmit(onSubmit)}
            >
              Submit {loadingState && <Loader />}
            </div>
            <div className="flex-1"></div>
            <div className="flex-1"></div>
          </div>
        </div>
      </form>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { base, get_invoice } from "../constants/urls";
import { base_url, get_invoice } from "constants/endpoints";
import { useInvoiceStore } from "store/InvoiceStore";
import Swal from "sweetalert2";
import { number_format } from "utils/number_format";
import { formatDateNew } from "utils/date_format";
import { vatPercentage } from "constants/others";
import { useAdminAuth } from "store/AdminAuth";
import { RiPrinterLine } from "react-icons/ri";

export default function InvoiceDetails() {
  const { reference } = useParams();
  // const invoice = useInvoiceStore((state) => state.invoiceDetails);
  const [invoice, setInvoice] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [vat, setVat] = useState(0);
  const [qty, setQty] = useState(1);
  const getAuth = useAdminAuth((state) => state.auth);

  useEffect(() => {
    let url = base_url + get_invoice + reference;
    let token = getAuth.token;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((respJson) => {
        console.log(respJson);
        setInvoice({
          firstName: respJson.data.first_name,
          middleName: respJson.data.middle_name,
          lastname: respJson.data.last_name,
          address: respJson.data.address,
          invoiceNumber: respJson.data.invoice_number,
          initiatedDate: respJson.data.initiated_date,
          amount: respJson.data.amount,
          vat: respJson.data.amount * vatPercentage,
          vin: respJson.data.vin,
          requestNumber: respJson.data.request_number,
          licenceNumber: respJson.data.license,
          narration: respJson.data.narration,
          make: respJson.data.make,
        });
      });

    // let calculatedVat = vatPercentage * invoice.amount;
    // setVat(calculatedVat);
    // alert(vat_rate);
  }, []);

  return (
    <div className="pt-3 xl:pt-10 bg-gray-100 rounded-lg h-full">
      <div className="px-4 py-7 mt-4 sm:mx-28 sm:px-0 md:mx-20 lg:mx-52 lg:my-10">
        <div className="flex flex-col">
          <div className="">
            <img
              src="/logodark.png"
              alt="Police Logo"
              className="h-100 w-100"
            />
          </div>
          <div className="mt-4 xl:text-3xl md:text-2xl text-md font-normal">
            Motor Vehicle Information Request
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <div className="flex space-x-2">
            <button
              onClick={() => {
                window.history.back();
              }}
              className="hidden-print bg-blue-400 text-black-800 px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-white"
            >
              &larr; Go Back
            </button>
            <button
              className="hidden-print flex space-x-2 items-center border px-3 py-1 rounded border-red-500"
              onClick={() => {
                window.print();
              }}
            >
              <RiPrinterLine />
              <p>Print</p>
            </button>
          </div>
          <div className="font-bold xl:text-3xl md:text-2xl text-lg">
            INVOICE
          </div>
        </div>
      </div>

      <div className="invoice-details-cont px-7 py-7 mt-4 sm:mx-28 md:mx-20 md:px-10 lg:mx-52 lg:my-10 lg:px-20">
        <div className="invoice-details">
          {" "}
          <div className="mb-4">
            <div>Name: </div>
            <div>
              <span className="font-bold">
                {invoice.firstName} {invoice.middleName} {invoice.lastName}
              </span>
            </div>
          </div>
          <div className="mb-4">
            <div className="">Invoice Number: </div>
            <div>
              <span className="font-bold">CI{invoice.invoiceNumber}</span>
            </div>
          </div>
          <div className="mb-4">
            <div className="">Address: </div>
            <div>
              <span className="font-bold">{invoice.address}</span>
            </div>
          </div>
          <div className="mb-4">
            <div className="">Date: </div>
            <div>
              <span className="font-bold">
                {formatDateNew(invoice.initiatedDate)}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <hr />
          <div className="mt-6 mb-4 xl:text-3xl md:text-2xl text-lg font-bold">
            SUMMARY
          </div>
          <table className="summary-cont text-sm sm:text-lg">
            <tbody>
              <tr>
                <th className="summary-table" scope="row">
                  Description
                </th>
                <td className="summary-table">
                  <p className="font-bold">
                    Non-Refundable fee for Motor Vehicle Information Request:
                  </p>{" "}
                  Licence Number:{" "}
                  <b>{invoice.licenceNumber || "Not Yet Issued"}</b> <br />
                  VIN/Chassis Number: <b>{invoice.vin}</b>
                  <br />
                  Make/Model: <b>{invoice.make}</b>
                </td>
              </tr>
              <tr>
                <th className="summary-table" scope="row">
                  Quantity
                </th>
                <td className="summary-table">1</td>
              </tr>
              <tr>
                <th className="summary-table" scope="row">
                  Unit Cost
                </th>
                <td className="summary-table">
                  &#8358;{number_format(invoice.amount, 2, ".", ",")}
                </td>
              </tr>
              <tr>
                <th className="sm:text-xl">PAYMENT</th>
                <td className="summary-table">
                  <div className="flex justify-between mt-4">
                    <div className="font-normal sm:text-lg">Amount</div>
                    <div className="">
                      &#8358;{number_format(invoice.amount, 2, ".", ",")}
                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                    <div className="font-normal sm:text-lg">VAT</div>
                    <div className="">
                      &#8358;{number_format(vat, 2, ".", ",")}
                    </div>
                  </div>
                  <div className="flex justify-between mt-4 border border-y-black py-2">
                    <div className="font-bold sm:text-lg">TOTAL</div>
                    <div className="">
                      &#8358;
                      {number_format(
                        parseInt(invoice.amount) + parseInt(vat),
                        2,
                        ".",
                        ","
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

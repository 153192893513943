import React, { useEffect, useState, useRef } from "react";
import {
  base_url,
  upload_passport,
  get_idp_details,
  approval_layer1,
  approval_layer2,
} from "constants/endpoints";
import { useParams } from "react-router-dom";
import Sidebar from "components/admin/SideBars/Sidebar";
import Swal from "sweetalert2";
import { useAdminAuth } from "store/AdminAuth";
import Header from "components/admin/Headers/Header";
import { formatDateNew } from "utils/date_format";
import Loader from "components/Loader";

export default function IDPUpdateDetails() {
  const { id } = useParams();
  const getAuth = useAdminAuth((state) => state.auth);
  const [requestDetails, setRequestDetails] = useState({});
  const [syncing, setSyncing] = useState(false);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [camModal, setcamModal] = useState(false);
  const [camPreview, setcamPreview] = useState("");
  const [fileData, setfileData] = useState(null);
  const [uploadLoading, setuploadLoading] = useState(false);

  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(stream);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  };

  const stopWebcam = () => {
    if (stream) {
      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });

      setStream(null);
    }
  };

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      const imageDataURL = canvas.toDataURL("image/png");
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], "captured-image.png", {
            type: "image/png",
          });
          console.log("Captured Image File:", file);
          setfileData(file);
        }
      }, "image/png");

      // console.log("Captured Image Data URL:", imageDataURL);
      setcamPreview(imageDataURL);
    }
  };

  const updatePassport = () => {
    setuploadLoading(true);
    let token = getAuth.token;
    console.log(base_url + upload_passport);
    let formData = new FormData();
    formData.set("id", id);
    formData.set("health_report", fileData, fileData.name);
    formData.set("document_title", "IDP Passport");

    console.log(fileData.name);

    try {
      fetch(base_url + upload_passport, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            console.log("Response ok", response);
            return response.json();
          } else {
            console.log("Response not ok");
            Swal.fire({
              title: "Error!",
              text: "Error fetching data from the server!",
              icon: "error",
            });
          }
          setuploadLoading(false);
        })
        .then((jsonResp) => {
          console.log(jsonResp);
          Swal.fire({
            title: "Success!",
            text: "Uploading Passport Successful!",
            icon: "success",
          }).then(() => {
            setcamPreview("");
            setcamModal(false);
            stopWebcam();
          });
          setuploadLoading(false);
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error uploading Passport!",
        icon: "error",
      });
      setuploadLoading(false);
    }
  };

  const SyncroniseOwner = () => {
    setSyncing(true);
    alert("testing");
  };
  useEffect(() => {
    let token = getAuth.token;
    console.log(token);
    console.log(base_url + get_idp_details + id);
    try {
      fetch(base_url + get_idp_details + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          // console.log(response.json().data);
          if (response.ok) {
            console.log("repsonse ok");
            return response.json();
          } else {
            console.log("repsonse not ok");
            Swal.fire({
              title: "Error!",
              text: "Error fetching data from the server!",
              icon: "error",
            }).then(() => {
              window.history.back();
            });
          }
        })
        .then((jsonResp) => {
          console.log(jsonResp.data);
          setRequestDetails({
            firstName: jsonResp.data?.owner?.first_name,
            middleName: jsonResp.data?.owner?.middle_name,
            lastName: jsonResp.data?.owner?.last_name,
            gender: jsonResp.data?.owner?.gender,
            phone: jsonResp.data?.owner?.phone,
            email: jsonResp.data?.owner?.email,
            state: jsonResp.data.residentialState,
            residentialState: jsonResp.data.request_state,
            residentialLg: jsonResp.data?.owner?.lga,
            residentialAddress: jsonResp.data?.owner?.present_address,
            profileType: jsonResp.data?.owner?.type,
            dateOfBirth: jsonResp.data.date_of_birth,
            countryOfBirth: jsonResp.data.country_of_birth,
            entryDate: jsonResp.data.entry_date,
            certNo: jsonResp.data.certificate_number,
            licenseClass: jsonResp.data.license_class,
            height: jsonResp.data.height,
            eyeColor: jsonResp.data.eye_color,
            paymentStatus: jsonResp.data.payment_status,
            mla: jsonResp.data.mla,
            requestNumber: jsonResp.data.request_number,
            reference: jsonResp.data.reference,
            commandCenter: jsonResp.data.command_center,
            status: jsonResp.data.status,
          });
          // setUserInputVehicle(jsonResp.data);
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error fetching data from the server!",
        icon: "error",
      });
    }
  }, []);

  if (!getAuth.token || getAuth.token === "" || getAuth.token === undefined) {
    window.location = "/admin/login";
    return;
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="International Driving Permit Request Details" />
        {/* <ul className="ml-8 flex flex-wrap text-lg pb-4 font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"> */}
        {/* <li className="mr-2 cursor-pointer"></li>
          <li className="mr-2"></li> */}
        {/* </ul> */}

        <div className="bg-white mt-6 py-8">
          <div className="px-8 mt-1 w-full text-lg leading-9 overflow-x-auto">
            <button
              onClick={() => {
                window.history.back();
              }}
              className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
            >
              &larr; Go Back
            </button>
            <div className="my-4">
              <button
                className="bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                onClick={() => {
                  setcamModal(true);
                  startWebcam();
                }}
              >
                Capture Passport
              </button>
            </div>
            {camModal && (
              <div
                className="relative z-10"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <h1>IDP Image Capture</h1>
                        <video
                          ref={videoRef}
                          width="640"
                          height="480"
                          autoPlay
                        ></video>
                        <div>
                          {" "}
                          <button
                            className="my-4 bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                            onClick={captureImage}
                          >
                            Capture Image
                          </button>
                          <button
                            className="my-4 ml-4 bg-red-400 text-white px-2 py-1 rounded hover:bg-red-600 cursor-pointer hover:text-black"
                            onClick={() => {
                              setcamPreview("");
                              setcamModal(false);
                              stopWebcam();
                            }}
                          >
                            Close
                          </button>
                          {fileData !== null && (
                            <button
                              className="my-4 ml-4 bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                              onClick={() => updatePassport()}
                            >
                              {uploadLoading ? <Loader /> : "Upload Image"}
                            </button>
                          )}
                        </div>
                        <canvas
                          ref={canvasRef}
                          width="640"
                          height="480"
                          style={{ display: "none" }}
                        ></canvas>
                        {camPreview !== "" && (
                          <>
                            <p className="">Image Preview</p>
                            <img src={camPreview} alt="Preview" />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Owner Details |{" "}
              <span
                className="cursor-pointer hover:text-black"
                onClick={() => {
                  !syncing && SyncroniseOwner();
                }}
              >
                {syncing ? "Syncing..." : "Syncronise"}
              </span>
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">First Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.firstName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Profile Type:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.profileType === "individual" &&
                          "Personal"}
                        {requestDetails.profileType === "organisation" &&
                          "Organisation"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Phone:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.phone}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Address:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialAddress}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Last Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Gender:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.gender}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">State:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialState}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Middle Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.middleName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Email:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.email}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">L.G.A:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialLg}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              IDP Details
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Request Date:</td>
                      <td className="text-blue-800 font-semibold">
                        {formatDateNew(requestDetails.entryDate)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">License Clas:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.licenseClass}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Height:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.height}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">DOB:</td>
                      <td className="text-blue-800 font-semibold">
                        {formatDateNew(requestDetails.dateOfBirth)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Cert No.:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.certNo}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Eye Color:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.eyeColor}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Country of Birth:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.countryOfBirth}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Payment Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.paymentStatus}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Request Details
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Request Date:</td>
                      <td className="text-blue-800 font-semibold">
                        {formatDateNew(requestDetails.entryDate)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Request State:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialState}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Command Centre:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.commandCenter}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Request Number:</td>
                      <td className="text-blue-800 font-semibold whitespace-nowrap">
                        {requestDetails.requestNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Request Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.status}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}

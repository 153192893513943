import { HasAccess } from "@permify/react-role";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import { classNames } from "../../shared/Utils";
import Loader from "components/Loader";
import Swal from "sweetalert2";
import {
  base_url,
  get_analyser_request,
  admin_idp_entries,
  pending_reports,
  get_stolen_vehicles,
  confirm_cmr,
} from "constants/endpoints";
import { useEffect, useMemo, useState } from "react";
import { useAdminAuth } from "store/AdminAuth";
import Table, {
  StatusPill,
  SelectColumnFilter,
} from "../../components/admin/DataTable/Table";
import ProfileTable, {
  ProfileTableStatusPill,
  ProfileTableSelectColumnFilter,
} from "../../components/admin/DataTable/ProfileTable";
import { search_profile } from "constants/endpoints";
import { reset_cmris_user_password } from "constants/endpoints";
// import PreCMRTableAdmin from "../../components/admin/DataTable/PreCMRTableAdmin";
// import PreCMRTable from "../../components/admin/DataTable/PreCMRTable";
// import InputInline from "components/InputInline";
// import Select from "components/admin/Forms/Select";

export default function Enquiry() {
  const [loading, setLoading] = useState(false);
  const [precmrs, setPreCMRs] = useState([]);
  const [cccprecmrs, setcccPrecmrs] = useState([]);
  const [cccAnalysersRequests, setcccAnalysersRequests] = useState([]);
  const masterTableData = useMemo(() => precmrs, [precmrs]);
  const commandCenterTableData = useMemo(() => cccprecmrs, [cccprecmrs]);
  const analysersRequests = useMemo(
    () => cccAnalysersRequests,
    [cccAnalysersRequests]
  );
  const getAuth = useAdminAuth((state) => state.auth);
  const [enquiryState, setEnquiryState] = useState("CMR Information");
  const [reported_stolens, setReportedStolen] = useState([]);
  const data = useMemo(() => reported_stolens, [reported_stolens]);
  const [url, setUrl] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const handleChange = (e) => {
    setsearchValue(e.target.value);
  };

  useEffect(() => {
    if(enquiryState=="CMR Information"){
      // setUrl("/admin/enquiry/vehicle/");
      sessionStorage.setItem("eq_url", "/admin/enquiry/vehicle/");
    }else if(enquiryState=="Stolen Vehicle"){
      sessionStorage.setItem("eq_url", "/admin/stolens/pending/");
    }else if(enquiryState == "Incident"){

    } 
  }, [enquiryState]);

  const searchVehicle = () => {
    setPreCMRs([])
    setLoading(true);
    let token = getAuth.token;
    let url ="";
    if(enquiryState === "CMR Information"){
      url = `${base_url}${confirm_cmr}?search=${searchValue}`;
    }else if(enquiryState === "Stolen Vehicle"){
      url = `${base_url}${get_stolen_vehicles}?search=${searchValue}`;
    }
    try {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp.data);
          if (jsonResp.data == null) {
            setLoading(false);
          }
          if (jsonResp.data != null) {
            setPreCMRs(jsonResp.data);
            setLoading(false);
          }
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const searchProfile = () => {
    setPreCMRs([])
    setLoading(true);
    let token = getAuth.token;
    let url ="";
    if(enquiryState === "CMRIS User Profile"){
      url = `${base_url}${search_profile}${searchValue}`;
    }
    try {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp.data);
          if (jsonResp.data == null) {
            setLoading(false);
          }
          if (jsonResp.data != null) {
            setPreCMRs(jsonResp.data);
            setLoading(false);
          }
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        // Header: () => (
        //   <div className="w-16">
        //     Licence Number
        //   </div>
        // ),
        Header: "Licence Number",
        accessor: "license_plate",
      },
      {
        Header: "Chassis Number",
        accessor: "chassis_number",
      },
      {
        Header: "Engine Number",
        accessor: "engine_number",
      },
      {
        Header: "Make/Model/Type",
        accessor: "make",
      },
      {
        Header:"Colour",
        accessor:"color"
      },
      {
        Header:"Licence State",
        accessor:"mla",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Watchlist Status",
        accessor: "vehicle_status",
        // Cell: StatusPill,
        Cell: ({ cell }) => (
          <>
            <a
              value={cell.row.values.vehicle_status}
              className={classNames(
                "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                cell.row.values.vehicle_status === "Valid"
                  ? "bg-green-100 text-green-700"
                  : null,
                cell.row.values.vehicle_status === "Conflict"
                  ? "bg-red-100 text-red-700"
                  : null,
                cell.row.values.vehicle_status === "Incident"
                  ? "bg-yellow-500 text-red-800"
                  : null,
                cell.row.values.vehicle_status === "Stolen"
                  ? "bg-red-700 text-red-100"
                  : null
              )}
              href="#"
            >
              {cell.row.values.vehicle_status}
            </a>
          </>
        ),
        Filter: SelectColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell,  url }) => (
          <>
            <a
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              disabled={true}
              href={sessionStorage.getItem("eq_url")+cell.row.values.id}
            >
              Details
            </a>
          </>
        ),
      },
    ],
    []
  );

  const profile_columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Id number",
        accessor: "id_number",
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Middle Name",
        accessor: "middle_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header:"Email",
        accessor:"email"
      },
      {
        Header:"Phone",
        accessor:"phone",
        // Filter: SelectColumnFilter,
      },
      {
        Header:"Id Number Name",
        accessor:"id_number_name",
        // Filter: SelectColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "Id",
        Cell: ({ cell,  url }) => (
          <>
            {/* <a
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              disabled={true}
              href={sessionStorage.getItem("eq_url")+cell.row.values.id}
            >
              Details
            </a> */}
            <button
              className={`mr-2 bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              onClick={()=>{
                window.location.href="/admin/cmrisusers/"+cell.row.values.id_number
                // alert(cell.row.values.Id)
              }}
            >
              Details
            </button>

            <button
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              // href={sessionStorage.getItem("eq_url")+cell.row.values.id}
              onClick={() => 
                Swal.fire({
                  title: "Are you sure?",
                  text: "Are you sure you want to reset this user's password?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.isConfirmed) {
                    ResetPassword(cell.row.values.Id, cell.row.values.phone )
                  }})
              }
            >
              Reset Password
            </button>
          </>
        ),
      },
    ],
    []
  );


  const ResetPassword = (id, phone) => {
    let url = base_url + reset_cmris_user_password + id + "/" + phone;
    let token = getAuth.token;
    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        if (jsonResp.statusCode === 0){
          Swal.fire("Resetted!", "Password resetted.", "success");
        }else{
          Swal.fire("Error!", "Can not reset password.", "error");
        }
        
      });
  }

  const stolenVcolumns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Licence Plate Number ",
        accessor: "LicensePlate",
      },
      {
        Header: "Make",
        accessor: "Manufacturer",
      },
      {
        Header: "Model",
        accessor: "Model",
      },
      {
        Header: "Type",
        accessor: "Type",
        // Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Color",
        accessor: "Color",
        // Cell: StatusPill,
        // Filter: SelectColumnFilter,
      },
      {
        Header: "State Where Stolen",
        accessor: "State",
        Filter: SelectColumnFilter, // new
      },
      {
        Header: "Date Reported",
        accessor: "DateReported",
      },
      {
        Header: "CMR Status",
        accessor: "VehicleStatus",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      // {
      //   Header: "Chassis Number",
      //   accessor: "ChassisNumber",
      // },
      // {
      //   Header: "Engine Number",
      //   accessor: "EngineNumber",
      // },

      {
        Header: "CMR Info Center",
        accessor: "CommandCenter",
        // Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
    ],
    []
  );

  useEffect(() => {
    let token = getAuth.token;
    try {
      fetch(base_url + pending_reports, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          if (jsonResp.data != null) {
            setReportedStolen(jsonResp.data);
            // console.log(jsonResp.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title={`Enquiry -  ${enquiryState}`} />
        <div className="bg-white mt-6 pt-8">
          <div className="mb-4 container md:w-1/3 rounded-lg mx-12">
            <label className="font-bold text-xl">Enquiry Type</label>
            <select
              className="rounded p-1 mt-2 w-full"
              value={enquiryState}
              onChange={(e)=>{
                setEnquiryState(e.target.value)
                setsearchValue("")
                setPreCMRs([]);
              }}
            >
              <option value="CMR Information">CMR Information</option>
              <option value="Stolen Vehicle">Stolen Vehicle</option>
              <option value="CMRIS User Profile">CMRIS User Profile</option>
              <option value="Incident">Incident</option>
            </select>
            <p className="font-bold text-xl mt-4">{enquiryState}</p>
          </div>
          {enquiryState === "CMR Information" && (
            <>
              {" "}
              <div className="container md:w-1/3 pb-6 rounded-lg mx-12">
                <input
                  className="rounded-md appearance-none relative h-12 inline w-2/3 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                  placeholder="Enter License or Chassis Number"
                  onChange={handleChange}
                  value={searchValue}
                  labelText="Search Vehicle"
                  labelFor="cmr_confirm"
                  type="text"
                  // isRequired={true}
                />
                <button
                  onClick={() => searchVehicle()}
                  className="bg-blue-400 text-white py-2 px-3 rounded-md h-12 ml-2"
                >
                  {loading ?  <Loader /> : "Search"}
                </button>
              </div>
              <div className="px-8 w-full">
                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="view-formation"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  {/**Master Table */}
                  <Table
                    columns={columns}
                    data={masterTableData}
                    loading={loading}
                  />
                </HasAccess>
              </div>
            </>
          )}

          {enquiryState === "Stolen Vehicle" && (
            <>
              {" "}
              <div className="container md:w-1/3 pb-6 rounded-lg mx-12">
                <input
                  className="rounded-md appearance-none relative h-12 inline w-2/3 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                  placeholder="Enter License or Chassis Number"
                  onChange={handleChange}
                  value={searchValue}
                  labelText="Search Vehicle"
                  labelFor="cmr_confirm"
                  type="text"
                  // isRequired={true}
                />
                <button
                  onClick={() => searchVehicle()}
                  className="bg-blue-400 text-white py-2 px-3 rounded-md h-12 ml-2"
                >
                  Search
                </button>
              </div>
              <div className="px-8 w-full">
                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="view-formation"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  {/**Master Table */}
                  <Table
                    columns={columns}
                    data={masterTableData}
                    loading={loading}
                  />
                </HasAccess>
              </div>
            </>
          )}

          {enquiryState === "CMRIS User Profile" && (
            <>
              {" "}
              <div className="container md:w-1/3 pb-6 rounded-lg mx-12">
                <input
                  className="rounded-md appearance-none relative h-12 inline w-2/3 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                  placeholder="Enter Email, ID or Phone Number"
                  onChange={handleChange}
                  value={searchValue}
                  labelText="Search Profile"
                  labelFor="cmr_confirm"
                  type="text"
                  // isRequired={true}
                />
                <button
                  onClick={() => searchProfile()}
                  className="bg-blue-400 text-white py-2 px-3 rounded-md h-12 ml-2"
                >
                  {loading ?  <Loader /> : "Search"}
                </button>
              </div>
              <div className="px-8 w-full">
                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="view-formation"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  {/**Master Table */}
                  <ProfileTable
                    columns={profile_columns}
                    data={masterTableData}
                    loading={loading}
                  />
                </HasAccess>
              </div>
            </>
          )}  
          {/* {enquiryState === "Stolen Vehicle" && (
            <>
              <div className="px-8 mt-3 w-full">
                <Table
                  columns={stolenVcolumns}
                  data={data}
                  loading={loading}
                  action="link"
                />
              </div>
            </>
          )} */}
        </div>
      </div>
    </div>
  );
}

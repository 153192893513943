import React, { useEffect, useState } from "react";
import {
  base_url,
  stolen_details,
  pre_cmr_details,
  approval_layer1,
  approval_layer2,
} from "constants/endpoints";
import { useParams } from "react-router-dom";
import Sidebar from "components/admin/SideBars/Sidebar";
import Swal from "sweetalert2";
import { useAdminAuth } from "store/AdminAuth";
import Header from "components/admin/Headers/Header";
import { formatDateNew } from "utils/date_format";
import Loader from "components/Loader";

export default function PreCMREntryDetailsForAdmin() {
  const { id } = useParams();
  const getAuth = useAdminAuth((state) => state.auth);
  const [requestDetails, setRequestDetails] = useState({});
  const [syncing, setSyncing] = useState(false)

  const SyncroniseOwner = () => {
    setSyncing(true)
    alert("testing");
  }
  useEffect(() => {
    let token = getAuth.token;
    try {
      fetch(base_url + pre_cmr_details + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          // console.log(response.json().data);
          if (response.ok) {
            console.log("repsonse ok");
            return response.json();
          } else {
            console.log("repsonse not ok");
            Swal.fire({
              title: "Error!",
              text: "Error fetching data from the server!",
              icon: "error",
            }).then(() => {
              window.history.back();
            });
          }
        })
        .then((jsonResp) => {
          console.log(jsonResp.data);
          setRequestDetails({
            firstName: jsonResp.data.firstName,
            middleName: jsonResp.data.middleName,
            lastName: jsonResp.data.lastName,
            gender: jsonResp.data.gender,
            phone: jsonResp.data.phone,
            email: jsonResp.data.email,
            state: jsonResp.data.residentialState,
            residentialState: jsonResp.data.residentialState,
            residentialLg: jsonResp.data.residentialLg,
            residentialAddress: jsonResp.data.residentialAddress,
            profileType: jsonResp.data.profileType,
            licensePlate: jsonResp.data.licensePlate,
            chassisNumber: jsonResp.data.chassisNumber,
            engineNumber: jsonResp.data.engineNumber,
            manufacturer: jsonResp.data.manufacturer,
            model: jsonResp.data.model,
            year: jsonResp.data.year,
            vehicleType: jsonResp.data.vehicleType,
            color: jsonResp.data.color,
            vehicleUsage: jsonResp.data.vehicleUsage,
            vehicleStatus: jsonResp.data.vehicleStatus,
            mla: jsonResp.data.mla,
            entryDate: jsonResp.data.entryDate,
            requestNumber: jsonResp.data.requestNumber,
            reference: jsonResp.data.reference,
            commandCenter: jsonResp.data.cc,
            status: jsonResp.data.status,
            ownerStatus: jsonResp.data.ownerStatus,
            ownerId: jsonResp.data.ownerId,
            idNumber:jsonResp.data.idNumber
          });
          // setUserInputVehicle(jsonResp.data);
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error fetching data from the server!",
        icon: "error",
      });
    }
  }, []);

  if (!getAuth.token || getAuth.token === "" || getAuth.token === undefined) {
    window.location = "/admin/login";
    return;
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Information Request Details" />
        {/* <ul className="ml-8 flex flex-wrap text-lg pb-4 font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"> */}
        {/* <li className="mr-2 cursor-pointer"></li>
          <li className="mr-2"></li> */}
        {/* </ul> */}

        <div className="bg-white mt-6 py-8">
          <div className="px-8 mt-1 w-full text-lg leading-9 overflow-x-auto">
            <button
              onClick={() => {
                window.history.back();
              }}
              className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
            >
              &larr; Go Back
            </button>
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Owner Details | <span className="cursor-pointer hover:text-black" onClick={()=>{!syncing && SyncroniseOwner()}}>{syncing ? 'Syncing...' : 'Syncronise'}</span> | <a href={`/admin/cmrisusers/${requestDetails.idNumber}`}>View Profile</a>
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">First Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.firstName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Profile Type:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.profileType === "individual" &&
                          "Personal"}
                        {requestDetails.profileType === "organisation" &&
                          "Organisation"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Phone:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.phone}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Address:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialAddress}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Last Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Gender:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.gender}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">State:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialState}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Owner Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.ownerStatus} {requestDetails.ownerStatus=="Not Verified" && <button className="bg-blue-500 text-white text-sm rounded-lg p-1 hover:bg-black">{requestDetails.ownerStatus == "Not Verified" && "Verify"}</button>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Middle Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.middleName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Email:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.email}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">L.G.A:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialLg}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Vehicle Details
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Licence Plate:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.licensePlate}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Manufacturer:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.manufacturer}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Vehicle Type:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.vehicleType}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Watchlist Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.vehicleStatus}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Chassis Number:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.chassisNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Model:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.model}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Color:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.color}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">License State:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.mla}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Engine Number:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.engineNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Year:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.year}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Vehicle Usage:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.vehicleUsage}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Request Details
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Request Date:</td>
                      <td className="text-blue-800 font-semibold">
                        {formatDateNew(requestDetails.entryDate)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Request State:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.state}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Command Centre:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.commandCenter}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Request Number:</td>
                      <td className="text-blue-800 font-semibold whitespace-nowrap">
                        {requestDetails.requestNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Request Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.status}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import Sidebar from "components/admin/SideBars/Sidebar";
import { base_url, posted_reports } from "constants/endpoints";
import Table, {
  StatusPill,
  SelectColumnFilter,
} from "../../components/admin/DataTable/Table";
import { HasAccess } from "@permify/react-role";

export default function PostedStolenReports() {
  const [recompute, setRecompute] = useState([
    // {
    //   VehicleId: 0,
    //   ReportId: 0,
    //   ChassisNumber: "NA",
    //   LicensePlate: "NA",
    //   Location: "NA",
    //   EntryDate: "NA",
    //   State: "NA",
    //   Lg: "NA",
    //   Time: "NA",
    //   ExtractNumber: "NA",
    //   Description: "NA",
    //   Status: "NA",
    // },
  ]);
  const [reported_stolens, setReportedStolen] = useState([]);
  const data = useMemo(() => reported_stolens, [reported_stolens]);

  useEffect(() => {
    let token = localStorage.getItem("cmr_admin_token");
    try {
      fetch(base_url + posted_reports, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(token),
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          if (jsonResp.data != null) {
            setReportedStolen(jsonResp.data);
            setRecompute(jsonResp.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Chassis Number",
        accessor: "ChassisNumber",
      },
      {
        Header: "Licence Plate",
        accessor: "LicensePlate",
        // Cell: StatusPill,
      },
      {
        Header: "Request Date",
        accessor: "EntryDate",
        // Cell: StatusPill, // new
      },
      {
        Header: "Extract Number",
        accessor: "ExtractNumber",
        // Filter: SelectColumnFilter, // new
        // filter: "includes",
      },
      {
        Header: "State",
        accessor: "State",
      },
      {
        Header: "Status",
        accessor: "Status",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
    ],
    []
  );
  return (
    <div>
      <Sidebar />
      <div className="relative md:ml-64 bg-lightBlue-700">
        <ul className="ml-8 flex flex-wrap text-lg pb-4 font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
          {/* <li className="mr-2 cursor-pointer"></li>
          <li className="mr-2"></li> */}
        </ul>
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin111"]}
              permissions="approve-services-layer1111"
              renderAuthFailed={
                <p className="text-red-700 text-4xl mx-8">Can not</p>
              }
              // isLoading={<Spinner />}
            >
              <Table columns={columns} data={data} action="link" />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

import Sidebar from "components/admin/SideBars/Sidebar";
import {
  base_url,
  stolen_details
} from "constants/endpoints";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";
import { formatDateNew } from "../../utils/date_format";

export default function PendingReportDetails() {
  const { id } = useParams();
  const [reportDetails, setReportDetails] = useState({});
  const getAuth = useAdminAuth((state) => state.auth);
  const [readNote, setReadNote] = useState(false)
  useEffect(() => {
    const token = getAuth.token;

    try {
      fetch(base_url + stolen_details + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            Swal.fire({
              title: "Error!",
              text: "Error fetching data from the server!",
              icon: "error",
            }).then(() => {
              window.history.back();
            });
          }
        })
        .then((jsonResp) => {
          console.log(jsonResp.data)
          setReportDetails(jsonResp.data);
        });
    } catch (e) {
      console.log({ e });
      Swal.fire({
        title: "Error!",
        text: "Error fetching data from the server!",
        icon: "error",
      });
    }
  }, []);

  return (
    <div className="pb-10">
      <Sidebar />
      <div className="relative md:ml-64 bg-lightBlue-700">
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-1 w-full text-lg leading-9">
            <button
              onClick={() => {
                window.history.back();
              }}
              className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
            >
              &larr; Go Back
            </button>

            <div className="mt-4 rounded-lg text-xl font-bold text-gray-50 py-2 px-4 bg-lightBlue-900">
              Owner Details
            </div>
            <div className="columns-3">
              <div className="">
                First Name:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.firstName}
                </span>
              </div>
              <div>
                Last Name
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.lastName}
                </span>
              </div>
              <div>
                Middle Name
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.middleName}
                </span>
              </div>
            </div>
            <div className="columns-3">
              <div>
                Profile Type:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.type}
                </span>
              </div>
              <div>
                Gender:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.gender}
                </span>
              </div>
              <div>
                Email:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.email}
                </span>
              </div>
            </div>

            <div className="columns-3">
              <div>
                Phone:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.phone}
                </span>
              </div>
              <div>
                State:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.residentialState}
                </span>
              </div>
              <div>
                Local Government:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.residentialLg}
                </span>
              </div>
            </div>
            <div className="columns-3">
              <div>
                Address:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.residentialAddress}
                </span>
              </div>
              {/* <div>
                Next of Kin:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.nokPhone}
                </span>
              </div> */}
            </div>
            <hr />

            <div className="mt-4 rounded-lg text-xl font-bold text-gray-50 py-2 px-4 bg-lightBlue-900">
              Vehicle Details
            </div>
            <div className="columns-3">
              <div>
                Licence Plate:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.licensePlate}
                </span>
              </div>
              <div>
                Chassis Number
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.chassisNumber}
                </span>
              </div>
              <div>
                Engine Number
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.engineNumber}
                </span>
              </div>
            </div>
            <div className="columns-3">
              <div>
                Manufacturer:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.manufacturer}
                </span>
              </div>
              <div>
                Model
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.model}
                </span>
              </div>
              <div>
                Year
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.year}
                </span>
              </div>
            </div>

            <div className="columns-3">
              <div>
                Vehicle Type:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.vehicleType}
                </span>
              </div>
              <div>
                Color:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.color}
                </span>
              </div>
              <div>
                Vehicle Usage:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.vehicleUsage}
                </span>
              </div>
            </div>

            <div className="columns-3">
              <div>
                Vehicle Status:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.vehicleStatus}
                </span>
              </div>
            </div>

            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-50 py-2 px-4 bg-lightBlue-900">
              Report Details
            </div>
            <div className="columns-3">
              <div>
                Request Date:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.entryDate}
                </span>
              </div>
              <div>
                State:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.state}
                </span>
              </div>
              <div>
                Local Government:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.lg}
                </span>
              </div>
            </div>

            <div className="columns-3">
              <div>
                Location:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.location}
                </span>
              </div>
              <div>
                Date and Time Stolen:
                <span className="ml-4 text-blue-800 font-semibold">
                  {formatDateNew(reportDetails?.time)}
                </span>
              </div>
              <div>
                Extract Number:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.extractNumber}
                </span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1">
                Police Formation :
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.policeFormation}
                </span>
              </div>
              <div className="flex-1">
                Mode of Stealing :
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.modeOfStealing}
                </span>
              </div>
              <div className="flex-1">
                <span className="ml-3">Date Reported</span>
                <span className="ml-4 text-blue-800 font-semibold">
                  {formatDateNew(reportDetails?.dateReported)} - <span className="cursor-pointer" onClick = {()=> setReadNote(true)}>Read Note</span>
                </span>
              </div>
              {readNote && (
              <div
                className="relative z-10"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 mr-10">
                      {reportDetails?.description}                      
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 cursor-pointer" onClick={()=>{setReadNote(false)}}>Close</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            </div>
            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-50 py-2 px-4 bg-lightBlue-900">
              Documents
            </div>
            <div className="flex">
              <div className="flex-1">
                {reportDetails?.proof!="" && <>Proof of ownership
                <a href={reportDetails?.proof} target="__blank">
                  <img
                    src={reportDetails?.proof}
                    alt="Proof of Ownership"
                    className="h-20 w-20"
                  />
                </a></> }
              </div>
              <div className="flex-1">
                {reportDetails?.extract !="" && <>Police Extract
                <a href={reportDetails?.extract} target="__blank">
                  <img
                    src={reportDetails?.extract}
                    alt="Police Extract"
                    className="h-20 w-20"
                  />
                </a></>}
              </div>

              <div className="flex-1">
                {reportDetails?.affidavit != "" && <>Affidavit
                <a href={reportDetails?.affidavit} target="__blank">
                  <img
                    src={reportDetails?.affidavit}
                    alt="Affidavit"
                    className="h-20 w-20"
                  />
                </a></>}
              </div>
            </div>
            <hr className="mt-5" />
          </div>
        </div>
      </div>
    </div>
  );
}

import { base_url, verifyuser } from "constants/endpoints";

async function verifyUser(user_id, token) {
    const url = `${base_url}${verifyuser}${user_id}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error verifying user: ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Verification response:", data);
    return data; 

  } catch (error) {
    console.error("Error verifying user:", error);
    throw error; 
  }
}

export default verifyUser;

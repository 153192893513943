/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AdminLogout } from "../auth/AdminLogout";

import NotificationDropdown from "../DropDowns/TableDropdown";
import UserDropdown from "../DropDowns/UserDropdown";
import { HasAccess } from "@permify/react-role";
import { useSideMenu } from "store/SideMenuStore";

export default function Sidebar() {
  // const [menuState, setMenuState] = useState("");
  const setMenuState = useSideMenu((state) => state.setMenu);
  const menuState = useSideMenu((state) => state.menu);
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-blueGray-800 flex flex-wrap items-center justify-between relative md:w-64 z-10 py-1 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-center text-blueGray-100 mr-0 inline-block whitespace-nowrap text-xl font-semibold p-4 px-0"
            to="/admin/dashboard"
          >
            <div className="flex">
              <div className="flex-1"></div>
              <div>
                <img alt="" className="h-24 w-20" src="/logo.png" />
              </div>
              <div className="flex-1"></div>
            </div>
            <span className="">Admin Portal</span>
          </Link>

          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    CMR
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>
            {/* Divider */}
            <hr className="md:min-w-full" />
            {/* Heading */}
            {/* <h6 className="md:min-w-full text-blueGray-100 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Administration
            </h6> */}
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "bg-gray-100 text-green-500 rounded-t-lg mt-2 hover:text-blueGray-900"
                      : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mb-2 hover:rounded-t-lg")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>
              <HasAccess
                roles={["super-admin", "admin"]}
                permissions="view-formation"
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
              >
                <li className="items-center">
                  <Link
                    className={
                      "text-sm py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/locations") !== -1
                        ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                        : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                    }
                    to="/admin/locations"
                  >
                    <i
                      className={
                        "fas fa-tools mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/locations") !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    CMR Formations
                  </Link>
                </li>
              </HasAccess>

              <HasAccess
                roles={["super-admin", "admin"]}
                permissions="view-anpr"
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
                // isLoading={<Spinner />}
              >
                <li className="items-center">
                  <Link
                    className={
                      "text-sm py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/cameras") !== -1
                        ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                        : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                    }
                    to="/admin/cameras"
                  >
                    <i
                      className={
                        "fas fa-table mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/cameras") !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    ANPR Manager
                  </Link>
                </li>
              </HasAccess>

              <HasAccess
                roles={["super-admin", "admin", "cic-head", "ccc-head"]}
                permissions="make-enquiry"
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
                // isLoading={<Spinner />}
              >
                <li className="items-center">
                  <Link
                    className={
                      "text-sm py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/vehicle/enquiry") !== -1
                        ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                        : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                    }
                    to="/admin/vehicle/enquiry"
                  >
                    <i
                      className={
                        "fas fa-table mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/vehicle/enquiry") !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Enquiry/Investigation
                  </Link>
                </li>
              </HasAccess>
              {/* <HasAccess
                roles={["super-admin", "admin"]}
                permissions="view-compliance-officer"
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
              ></HasAccess> */}
            </ul>
            {/* Divider */}
            {/*Requests  Starts**/}
            <HasAccess
              roles={[
                "super-admin",
                "admin",
                "ccc-head",
                "ccc-analyser",
                "cic-head",
                "cic-operative",
              ]}
              // permissions="approve-cmr-information"
              // renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <hr className="md:min-w-full" />
              {/* Heading */}
              <h6
                onClick={() => {
                  if (menuState !== "requests") {
                    setMenuState("requests");
                  } else {
                    setMenuState("");
                  }
                }}
                className={`cursor-pointer hover:bg-gray-300  md:min-w-full text-blue-400 text-lg font-bold block pt-1 no-underline`}
              >
                Requests
              </h6>

              <ul
                className={`${
                  menuState === "requests" ? "flex" : "hidden"
                } md:flex-col md:min-w-full flex-col list-none md:mb-4`}
              >
                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "ccc-analyser"]}
                  permissions="approve-cmr-information"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="items-center">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/precmr") !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/precmr"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/precmr") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Motor Vehicle Information
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={[
                    "super-admin",
                    "admin",
                    "cic-head",
                    "cic-operative",
                    "ccc-head",
                    "ccc-analyser",
                  ]}
                  permissions="approve-change-of-ownership"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/cow/pending") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/cow/pending"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/cow/pending"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Ownership
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin"]}
                  permissions="approve-change-of-body"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/cob/pending") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/stolens/pending"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Chassis/Body
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin"]}
                  permissions="approve-change-of-engine"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/coe/pending") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/stolens/pending"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Engine
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin"]}
                  permissions="approve-change-of-color"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/coc/pending") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/stolens/pending"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Colour
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "cic-operative", "ccc-head", "ccc-analyser", "cic-head"]}
                  // permissions="approve-international-driving-permit"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/idp/pending") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/idp/pending"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/idp/pending"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Int'l Driving Permit
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin"]}
                  permissions="approve-international-certificate-for-vehicle"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/icv/pending") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/stolens/pending"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Int'l Cert for Vehicle
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin"]}
                  permissions="approve-change-of-body"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/spy/pending") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/stolens/pending"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      SPY Plate Number Permit
                    </Link>
                  </li>
                </HasAccess>
                {/* 
              <HasAccess
                roles={["super-admin", "admin"]}
                permissions="approve-stolen-vehicle"
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
                // isLoading={<Spinner />}
              >
                <li className="mr-2 cursor-pointer">
                  <Link
                    className={
                      "text-sm py-3 font-bold block " +
                      (window.location.href.indexOf(
                        "/admin/stolens/pending"
                      ) !== -1
                        ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                        : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                    }
                    to="/admin/stolens/pending"
                  >
                    <i
                      className={
                        "fas fa-map-marked mr-2 text-sm " +
                        (window.location.href.indexOf(
                          "/admin/stolens/pending"
                        ) !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Stolen Vehicles
                  </Link>
                </li>
              </HasAccess> */}
              </ul>
            </HasAccess>
            {/*Requests  Ends**/}

            {/*Assigned  Starts**/}
            <HasAccess
              roles={["super-admin", "admin", "ccc-head", "cic-head"]}
              // permissions="view-change-of-body-analytic"
              // renderAuthFailed={
              //   <div className="text-red-700 h-screen text-center text-4xl mx-8"></div>
              // }
            >
              <hr className="md:min-w-full" />
              {/* Heading */}
              <h6
                onClick={() => {
                  if (menuState !== "assigned") {
                    setMenuState("assigned");
                  } else {
                    setMenuState("");
                  }
                }}
                className={`cursor-pointer hover:bg-gray-300  md:min-w-full text-blue-400 text-lg font-bold block pt-1 no-underline`}
              >
                Assigned
              </h6>

              <ul
                className={`${
                  menuState === "assigned" ? "flex" : "hidden"
                } md:flex-col md:min-w-full flex-col list-none md:mb-4`}
              >
                <HasAccess
                  roles={["super-admin", "admin", "ccc-head"]}
                  // permissions="approve-cmr-information"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="items-center">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/assignedcmr") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/assignedcmr"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/assignedcmr"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Motor Vehicle Information
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-ownership"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/cow/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/cow/assigned"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/cow/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Ownership
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-body"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/cob/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/cob/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Chassis/Body
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-engine"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/coe/pending") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/coe/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Engine
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-color"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/coc/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/coc/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Color
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-international-driving-permit"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/idp/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/idp/assigned"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/idp/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Int'l Driving Permit
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-international-certificate-for-vehicle"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/icv/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/icv/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Int'l Cert for Vehicle
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-tint-permit"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/tint/assigned"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/tint/assigned"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/tint/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Tint Permit
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-body"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/spy/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/stolens/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      SPY Plate Number Permit
                    </Link>
                  </li>
                </HasAccess>

                {/* <HasAccess
                roles={["super-admin", "admin", "ccc-head"]}
                // permissions="approve-stolen-vehicle"
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
                // isLoading={<Spinner />}
              >
                <li className="mr-2 cursor-pointer">
                  <Link
                    className={
                      "text-sm py-3 font-bold block " +
                      (window.location.href.indexOf(
                        "/admin/stolens/assigned"
                      ) !== -1
                        ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                        : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                    }
                    to="/admin/stolens/assigned"
                  >
                    <i
                      className={
                        "fas fa-map-marked mr-2 text-sm " +
                        (window.location.href.indexOf(
                          "/admin/stolens/assigned"
                        ) !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Stolen Vehicles
                  </Link>
                </li>
              </HasAccess> */}
              </ul>
            </HasAccess>
            {/*Assigned end **/}

            {/* Processed starts here */}
            <HasAccess
              roles={["super-admin", "admin", "ccc-head", "cic-head"]}
              // permissions="view-change-of-body-analytic"
              // renderAuthFailed={
              //   <div className="text-red-700 h-screen text-center text-4xl mx-8"></div>
              // }
            >
              <hr className="md:min-w-full" />
              {/* Heading */}
              <h6
                onClick={() => {
                  if (menuState !== "processed") {
                    setMenuState("processed");
                  } else {
                    setMenuState("");
                  }
                }}
                className={`cursor-pointer hover:bg-gray-300  md:min-w-full text-blue-400 text-lg font-bold block pt-1 no-underline`}
              >
                Processed
              </h6>

              <ul
                className={`${
                  menuState === "processed" ? "flex" : "hidden"
                } md:flex-col md:min-w-full flex-col list-none md:mb-4`}
              >
                <HasAccess
                  roles={["super-admin", "admin", "ccc-head"]}
                  // permissions="approve-cmr-information"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="items-center">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/processedcmr") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/processedcmr"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/processedcmr"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Motor Vehicle Information
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-ownership"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/cow/processed"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/cow/processed"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/cow/processed"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Ownership
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-body"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/cob/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/cob/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Chassis/Body
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-engine"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/coe/pending") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/coe/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Engine
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-color"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/coc/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/coc/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Color
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-international-driving-permit"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/idp/processed"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/idp/processed"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/idp/processed"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Int'l Driving Permit
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-international-certificate-for-vehicle"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/icv/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/icv/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Int'l Cert for Vehicle
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-tint-permit"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/tint/processed"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/tint/processed"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/tint/processed"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Tint Permit
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-body"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/spy/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/stolens/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      SPY Plate Number Permit
                    </Link>
                  </li>
                </HasAccess>

                {/* <HasAccess
                roles={["super-admin", "admin", "ccc-head"]}
                // permissions="approve-stolen-vehicle"
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
                // isLoading={<Spinner />}
              >
                <li className="mr-2 cursor-pointer">
                  <Link
                    className={
                      "text-sm py-3 font-bold block " +
                      (window.location.href.indexOf(
                        "/admin/stolens/assigned"
                      ) !== -1
                        ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                        : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                    }
                    to="/admin/stolens/assigned"
                  >
                    <i
                      className={
                        "fas fa-map-marked mr-2 text-sm " +
                        (window.location.href.indexOf(
                          "/admin/stolens/assigned"
                        ) !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Stolen Vehicles
                  </Link>
                </li>
              </HasAccess> */}
              </ul>
            </HasAccess>
            {/* Processed ends here */}

            {/* Finalized starts here */}
            <HasAccess
              roles={["super-admin", "admin", "ccc-head", "cic-head"]}
              // permissions="view-change-of-body-analytic"
              // renderAuthFailed={
              //   <div className="text-red-700 h-screen text-center text-4xl mx-8"></div>
              // }
            >
              <hr className="md:min-w-full" />
              {/* Heading */}
              <h6
                onClick={() => {
                  if (menuState !== "finalized") {
                    setMenuState("finalized");
                  } else {
                    setMenuState("");
                  }
                }}
                className={`cursor-pointer hover:bg-gray-300  md:min-w-full text-blue-400 text-lg font-bold block pt-1 no-underline`}
              >
                Finalized
              </h6>
              <ul
                className={`${
                  menuState === "finalized" ? "flex" : "hidden"
                } md:flex-col md:min-w-full flex-col list-none md:mb-4`}
              >
                <HasAccess
                  roles={["super-admin", "admin", "ccc-head"]}
                  // permissions="approve-cmr-information"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="items-center">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/finalizedcmr") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/finalizedcmr"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/finalizedcmr"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Motor Vehicle Information
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-ownership"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/cow/finalized"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/cow/finalized"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/cow/finalized"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Ownership
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-body"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/cob/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/cob/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Chassis/Body
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-engine"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/coe/pending") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/coe/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Engine
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-color"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/coc/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/coc/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Change of Color
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-international-driving-permit"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/idp/finalized"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/idp/finalized"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/idp/finalized"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Int'l Driving Permit
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-international-certificate-for-vehicle"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/icv/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/icv/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Int'l Cert for Vehicle
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-tint-permit"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/tint/finalized"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/tint/finalized"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/tint/finalized"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Tinted Glass Permit
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="approve-change-of-body"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/spy/assigned") !==
                        -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="#"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/stolens/assigned"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      SPY Plate Number Permit
                    </Link>
                  </li>
                </HasAccess>

                {/* <HasAccess
                roles={["super-admin", "admin", "ccc-head"]}
                // permissions="approve-stolen-vehicle"
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
                // isLoading={<Spinner />}
              >
                <li className="mr-2 cursor-pointer">
                  <Link
                    className={
                      "text-sm py-3 font-bold block " +
                      (window.location.href.indexOf(
                        "/admin/stolens/assigned"
                      ) !== -1
                        ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                        : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                    }
                    to="/admin/stolens/assigned"
                  >
                    <i
                      className={
                        "fas fa-map-marked mr-2 text-sm " +
                        (window.location.href.indexOf(
                          "/admin/stolens/assigned"
                        ) !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Stolen Vehicles
                  </Link>
                </li>
              </HasAccess> */}
              </ul>
            </HasAccess>
            {/* Finalized ends here */}

            {/* User Management Starts here */}
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="create-auth"
              // renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
              // isLoading={<Spinner />}
            >
              <hr className="md:min-w-full" />
              {/* Heading */}
              <h6
                className={`cursor-pointer hover:bg-gray-300  md:min-w-full text-blue-400 text-lg font-bold block pt-1 no-underline`}
                onClick={() => {
                  if (menuState !== "user") {
                    setMenuState("user");
                  } else {
                    setMenuState("");
                  }
                }}
              >
                User Manager
              </h6>
              {/* Navigation */}

              {/* Divider */}

              <ul
                className={`${
                  menuState === "user" ? "flex" : "hidden"
                } md:flex-col md:min-w-full flex-col list-none md:mb-4`}
              >
                {/* <HasAccess
                roles={["super-admin", "admin"]}
                permissions="add-permission"
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
                // isLoading={<Spinner />}
              >
                <li className="mr-2 cursor-pointer">
                  <Link
                    className={
                      "text-sm py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/permissions") !== -1
                        ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                        : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                    }
                    to="/admin/permissions"
                  >
                    <i
                      className={
                        "fas fa-map-marked mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/permissions") !==
                        -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Roles/Permissions
                  </Link>
                </li>
              </HasAccess> */}
                <HasAccess
                  roles={["super-admin", "admin"]}
                  permissions="create-admin"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                  // isLoading={<Spinner />}
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/users") !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/users"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/users") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      User Management
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin"]}
                  permissions="create-admin"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                  // isLoading={<Spinner />}
                >
                  <li className="items-center">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/cpos") !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/cpos"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/cpos") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Enforcement Officers
                    </Link>
                  </li>
                </HasAccess>
              </ul>
            </HasAccess>
            {/* User Management ends here */}

            <HasAccess
              roles={["super-admin", "admin", "cic-head", "cic-operative"]}
              // permissions=["report-incident"]
              // renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
              // isLoading={<Spinner />}
            >
              <hr className="md:min-w-full" />
              {/* Heading */}
              <h6
                className={`cursor-pointer hover:bg-gray-300  md:min-w-full text-blue-400 text-lg font-bold block pt-1 no-underline`}
                onClick={() => {
                  if (menuState !== "incidentEntries") {
                    setMenuState("incidentEntries");
                  } else {
                    setMenuState("");
                  }
                }}
              >
                Incident Entries
              </h6>

              {/* Incident reports ends here */}
              <ul
                className={`${
                  menuState === "incidentEntries" ? "flex" : "hidden"
                } md:flex-col md:min-w-full flex-col list-none md:mb-4`}
              >
                <HasAccess
                  roles={["super-admin", "admin", "cic-head", "cic-operative"]}
                  // permissions="report-incident"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                  // isLoading={<Spinner />}
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/report/flash-report"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/report/flash-report"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/report/flash-report"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Flash Report
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "cic-head", "cic-operative"]}
                  // permissions="report-stolen_vehicle"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                  // isLoading={<Spinner />}
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/report/full-report"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/report/full-report"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/report/full-report"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Full Report
                    </Link>
                  </li>
                </HasAccess>
              </ul>
            </HasAccess>
            {/*Report Management ends here */}

            {/* <HasAccess
              roles={["super-admin", "admin", "cic-head", "cic-operative"]}
              // permissions=["report-incident"]
              // renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
              // isLoading={<Spinner />}
            >
              <hr className="md:min-w-full" /> */}
              {/* Heading */}
              {/* <h6
                className={`cursor-pointer hover:bg-gray-300  md:min-w-full text-blue-400 text-lg font-bold block pt-1 no-underline`}
                onClick={() => {
                  if (menuState !== "incidentReport") {
                    setMenuState("incidentReport");
                  } else {
                    setMenuState("");
                  }
                }}
              >
                Incident Report
              </h6> */}

              {/* Incident reports ends here */}
              {/* <ul
                className={`${
                  menuState === "incidentReport" ? "flex" : "hidden"
                } md:flex-col md:min-w-full flex-col list-none md:mb-4`}
              >
                <HasAccess
                  roles={["super-admin", "admin", "cic-head", "cic-operative"]}
                  permissions="report-incident"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                  // isLoading={<Spinner />}
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/stolens/pending"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/stolens/pending"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/stolens/pending"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Stolen Motor Vehicle
                    </Link>
                  </li>
                </HasAccess> */}
                {/* <HasAccess
                  roles={["super-admin", "admin", "cic-head", "cic-operative"]}
                  permissions="report-stolen_vehicle"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                  // isLoading={<Spinner />}
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/report/stolen"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/report/stolen"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/report/stolen"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Motor Vehicle-Related
                    </Link>
                  </li>
                </HasAccess>
              </ul>
            </HasAccess> */}
            {/*Report Management ends here */}

            {/* agent start here */}

            <HasAccess
              // roles={["super-admin", "admin", "ccc-head", "cic-head", "cic-operative", "ccc-operative"]}
              roles={[
                "super-admin",
                "admin",
                "ccc-head",
                "ccc-analyser",
                "cic-head",
                "cic-operative",
              ]}
              // permissions=["report-incident"]
              // renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
              // isLoading={<Spinner />}
            >
              <hr className="md:min-w-full" />
              {/* Heading */}
              <h6
                className={`cursor-pointer hover:bg-gray-300  md:min-w-full text-blue-400 text-lg font-bold block pt-1 no-underline`}
                onClick={() => {
                  if (menuState !== "agent") {
                    setMenuState("agent");
                  } else {
                    setMenuState("");
                  }
                }}
              >
                CMRIS Assistant
              </h6>

              {/* Incident reports ends here */}
              <ul
                className={`${
                  menuState === "agent" ? "flex" : "hidden"
                } md:flex-col md:min-w-full flex-col list-none md:mb-4`}
              >
                <HasAccess
                  roles={[
                    "super-admin",
                    "admin",
                    "ccc-analyser",
                    "cic-operative",
                    // "ccc-head"
                  ]}
                  // permissions="report-incident"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                  // isLoading={<Spinner />}
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/cmrisassist/add"
                        ) !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/cmrisassist/add"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/cmrisassist/add/"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>
                      Add CMRIS Assistant
                    </Link>
                  </li>
                </HasAccess>

                <HasAccess
                  roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                  // permissions="report-incident"
                  renderAuthFailed={
                    <p className="text-red-700 text-4xl mx-8"></p>
                  }
                  // isLoading={<Spinner />}
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/assist") !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/assist"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/assist") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>
                      Approve CMRIS Assistant
                    </Link>
                  </li>
                </HasAccess>
              </ul>
            </HasAccess>
            {/* agent ends here */}

            {/* Enquiry here */}
            {/* <HasAccess
              roles={[
                "super-admin",
                "admin",
                "ccc-head",
                "cic-head",
                "cic-operative",
                "ccc-operative",
              ]}
              permissions={["enquiry"]}
            >
              <hr className="md:min-w-full" /> */}
              {/* Heading */}
              {/* <h6
                className={`cursor-pointer hover:bg-gray-300  md:min-w-full text-blue-400 text-lg font-bold block pt-1 no-underline`}
                onClick={() => {
                  if (menuState !== "enquiry") {
                    setMenuState("enquiry");
                  } else {
                    setMenuState("");
                  }
                }}
              >
                Enquiry
              </h6> */}

              {/* Incident reports ends here */}
              {/* <ul
                className={`${
                  menuState === "enquiry" ? "flex" : "hidden"
                } md:flex-col md:min-w-full flex-col list-none md:mb-4`}
              >
                <HasAccess
                  roles={[
                    "super-admin",
                    "admin",
                    "ccc-head",
                    "ccc-analyser",
                    "cic-head",
                    "cic-operative",
                    "ccc-operative",
                  ]}
                  permissions="add-model"
                  // renderAuthFailed={
                  //   <p className="text-red-700 text-4xl mx-8"></p>
                  // }
                  // isLoading={<Spinner />}
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/cmr/confirmcmr") !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/cmr/confirmcmr"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/cmr/confirmcmr") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>
                      Confirm CMRIS
                    </Link>
                  </li>
                </HasAccess>
              </ul>
            </HasAccess> */}
            {/* Enquiry ends here */}

            {/* Settings here */}
            <HasAccess
              // roles={["super-admin", "admin", "ccc-head", "cic-head", "cic-operative", "ccc-operative"]}
              roles={["super-admin"]}
              permissions={["access-settings"]}
            >
              <hr className="md:min-w-full" />
              {/* Heading */}
              <h6
                className={`cursor-pointer hover:bg-gray-300  md:min-w-full text-blue-400 text-lg font-bold block pt-1 no-underline`}
                onClick={() => {
                  if (menuState !== "settings") {
                    setMenuState("settings");
                  } else {
                    setMenuState("");
                  }
                }}
              >
                Settings
              </h6>

              {/* Incident reports ends here */}
              <ul
                className={`${
                  menuState === "settings" ? "flex" : "hidden"
                } md:flex-col md:min-w-full flex-col list-none md:mb-4`}
              >
                <HasAccess
                  roles={["super-admin"]}
                  permissions="add-model"
                  // renderAuthFailed={
                  //   <p className="text-red-700 text-4xl mx-8"></p>
                  // }
                  // isLoading={<Spinner />}
                >
                  <li className="mr-2 cursor-pointer">
                    <Link
                      className={
                        "text-sm py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/model") !== -1
                          ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                          : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                      }
                      to="/admin/model"
                    >
                      <i
                        className={
                          "fas fa-map-marked mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/model") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>
                      Add Vehicle Model
                    </Link>
                  </li>
                </HasAccess>
                <HasAccess
                  roles={["super-admin"]}
                  permissions="approve-correction"
                >
                <li className="items-center">
                  <Link
                    className={
                      "text-sm py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/correction/approval") !== -1
                        ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                        : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                    }
                    to="/admin/correction/approval"
                  >
                    <i
                      className={
                        "fas fa-table mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/correction/approval") !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Approve/Reject Corrections
                  </Link>
                </li>
                </HasAccess>
                {/* <HasAccess
                roles={["super-admin", "admin", "ccc-head", "cic-head"]}
                permissions="report-incident"
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
                // isLoading={<Spinner />}
              >
                <li className="mr-2 cursor-pointer">
                  <Link
                    className={
                      "text-sm py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/assist") !== -1
                        ? "bg-gray-100 text-green-500 rounded-t-lg hover:text-blueGray-900"
                        : "text-gray-200 hover:text-red-100 hover:bg-red-400 hover:mt-2 hover:rounde-t-lg hover:mb-2")
                    }
                    to="/admin/assist"
                  >
                    <i
                      className={
                        "fas fa-map-marked mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/assist") !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>
                    Approve CMRIS Assistant
                  </Link>
                </li>
              </HasAccess> */}
              </ul>
            </HasAccess>
            {/* settings ends here */}
          </div>
        </div>
      </nav>
    </>
  );
}

import create from "zustand";
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  correctionDetails: {
    vehicle_type: "",
    engine_number: "",
    mla: "",
    year: "",
    color: "",
    model: "",
    manufacturer: "",
    vehicle_status: "",
    vehicle_usage:"",
    license_plate:"",
    chassis_number:""
  },

  setCorrectionDetails: (correction_details) =>
    set(
      produce((temp) => {
        temp.correctionDetails = {
          vehicle_type: correction_details.vehicle_type,
          engine_number: correction_details.engine_number,
          mla: correction_details.mla,
          year: correction_details.year,
          color: correction_details.color,
          model: correction_details.model,
          manufacturer: correction_details.manufacturer,
          vehicle_status: correction_details.vehicle_status,
          vehicle_usage: correction_details.vehicle_usage,
          license_plate: correction_details.license_plate,
          chassis_number: correction_details.chassis_number
        };
      })
    ),

  reset: (correctionDetails) => set(correctionDetails),
});

// store = devtools(store);
store = persist(store, { name: "oldValues" });
export const useOldCorrectionStore = create(store);

import { useMemo } from "react";
import {
  SelectColumnFilter,
  StatusPill,
} from "../components/admin/DataTable/ProcessedPreCMRTable";

export const useCowColumns = (type) => {
  const rawColumn = [
    {
      Header: "S/No",
      id: "index",
      accessor: (row, i) => i + 1,
    },
    {
      Header: "Current Owner Name",
      accessor: "current_owner_name",
    },
    {
      Header: "New Owner Name",
      accessor: "new_owner_name",
    },
    {
      Header: "Request Number",
      accessor: "request_number",
    },
    {
      Header: "Licence Number",
      accessor: "license_plate",
    },
    {
      Header: "Chassis Number",
      accessor: "chassis_number",
    },
    {
      Header: "Engine Number",
      accessor: "engine_number",
    },

    {
      Header: "Recommendation",
      accessor: "recommendations",
      Cell: ({ cell }) => (
        <span
          className={`${
            cell.row.values.recommendations === "Cleared"
              ? "bg-green-400 rounded px-3 py-1 text-black-400"
              : "bg-red-500 rounded px-3 py-1 text-black-400"
          } rounded p-1`}
        >
          {cell.row.values.recommendations}
        </span>
      ),
    },

    {
      Header: "Make/Model",
      accessor: "vehicle_make",
    },
    {
      Header: "Watchlist",
      accessor: "vehicle_status",
      Cell: StatusPill,
      Filter: SelectColumnFilter,
    },
    {
      Header: "Command Centre",
      accessor: "command_center",
      Filter: SelectColumnFilter,
    },

    // {
    //   Header: "Actions",
    //   accessor: "CMREntryId",
    //   Cell: ({ cell }) => (
    //     <>
    //       <div className="flex flex-row">
    //         <a
    //           className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
    //           // value={cell.row.values.id}
    //           disabled={true}
    //           onClick={() => {}}
    //           href={"/admin/precmr/" + cell.row.values.CMREntryId}
    //         >
    //           Details
    //         </a>
    //       </div>
    //     </>
    //   ),
    // },
  ];
  //   if (type === "admin") {
  //     rawColumn.splice(6, 1);
  //   }
  const columns = useMemo(() => rawColumn);
  return columns;
};

import React, { useEffect, useState, useMemo } from "react";
import Sidebar from "../../components/admin/SideBars/Sidebar";
import Table, { SelectColumnFilter } from "components/Table";
import { HasAccess } from "@permify/react-role";
import Header from "components/admin/Headers/Header";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";

import {
  base_url,
  set_agent_status,
  agents as agent_url,
} from "constants/endpoints";

export default function Locations() {
  const [agents, setAgents] = useState([]);
  const data = useMemo(() => agents, [agents]);
  const getAuth = useAdminAuth((state) => state.auth);
  const token = getAuth.token;

  const toggleAgentStatus = (id, status) => {
    if (status == 1) {
      disableAgent(id);
    } else {
      enableAgent(id);
    }
  };

  const disableAgent = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to deactivate this agent?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = base_url + set_agent_status + id + "/0";
        fetch(url, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((jsonResp) => {
            Swal.fire("Disabled!", "Agent Deativated.", "success");
          });
      }
    });
  };

  const enableAgent = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to activate this agent?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = base_url + set_agent_status + id + "/1";
        console.log(url);
        fetch(url, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((jsonResp) => {
            console.log(jsonResp);
            Swal.fire("Enabled!", "User Activated.", "success");
          });
      }
    });
  };

  useEffect(() => {
    console.log(base_url + agent_url);
    let token = getAuth.token;
    try {
      fetch(base_url + agent_url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          setAgents(jsonResp.data);
          console.log(jsonResp);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "first_name",
      },
      // {
      //   Header: "Middle Name",
      //   accessor: "middle_name",
      //   // Cell: StatusPill,
      // },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Operating State",
        accessor: "operation_state",
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        Cell: ({ cell }) => {
          if (cell.row.values.status == 1) {
            return <span className="text-green-700">Active</span>;
          } else {
            return <span className="text-red-700">Inactive</span>;
          }
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell }) => (
          <HasAccess
            roles={["cic-head", "ccc-head", "super-admin"]}
            // permissions="report-incident"
            renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            // isLoading={<Spinner />}
          >
            <div className="flex justify-between space-x-2">
              <button
                className={`${
                  cell.row.values.status == 1 ? "bg-red-300" : "bg-green-300"
                } rounded px-1`}
                value={cell.row.values.id}
                onClick={() => {
                  toggleAgentStatus(cell.row.values.id, cell.row.values.status);
                }}
              >
                {cell.row.values.status == 1 ? "Deactivate" : "Activate"}
              </button>

              {/* <button
              className={`ml-3 bg-green-300 rounded`}
              value={cell.row.values.id}
              onClick={() => {
                window.location = "/admin/locations/" + cell.row.values.id;
              }}
            >
              Details
            </button> */}
            </div>
          </HasAccess>
        ),
      },
    ],
    []
  );
  if (
    getAuth.token === "" ||
    getAuth.token === undefined ||
    getAuth.auth === false
  ) {
    window.location = "/admin/login";
  }
  return (
    <div className="">
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMRIS ASSISTANT  " />
        <div className="flex mx-4 py-1 w-2/6"></div>

        <div className="bg-white pt-2">
          <div className="px-8 mt-3 w-full">
            <Table columns={columns} data={data || []} />
          </div>
        </div>
      </div>
    </div>
  );
}

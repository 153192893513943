const colors = [
  "Ash",
  "Aquamarine",
  "Azure",
  "Bamboo Pearl",
  "Biege",
  "Black",
  "Blue",
  "Brown",
  "Charcoal",
  "Coral",
  "Cream",
  "Crimson",
  "Cyan",
  "Fuchsia",
  "Gold",
  "Gray",
  "Green",
  "Hot pink",
  "Ivory",
  "Khaki",
  "Lime",
  "Magenta",
  "Maroon",
  "Magnetic Grey Metalic",
  "Navy blue",
  "Olden",
  "Olive",
  "Orange",
  "Pea green",
  "Plum",
  "Purple",
  "Red",
  "Silver",
  "Sky Blue",
  "Teal",
  "Wheat",
  "White",
  "Wine",
  "Yellow",
];

export default colors;

import React, { useState } from "react";
import { base_url, admin_logout } from "../../../constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";
// import { AdminLogout } from "../auth/AdminLogout";
import { Menu, Transition } from "@headlessui/react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

export default function Header({ title }) {
  let user = localStorage.getItem("__permifyUser");
  let userObj = JSON.parse(user);
  let userId = userObj.id;
  const getAuth = useAdminAuth((state) => state.auth);
  const cleanAuth = useAdminAuth((state) => state.reset);
  const [isOpen, setIsOpen] = useState(false);

  const Logout = async () => {
    let token = getAuth.token;
    let url = base_url + admin_logout;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        cleanAuth();
        localStorage.removeItem("__permifyUser");
        localStorage.removeItem("adminAuth");
        window.location = "/admin/login";
      });
  };

  return (
    <div>
      <div className="font-bold bg-blue-300 py-4 px-7 text-xl text-black flex justify-between">
        <h1 className="my-auto">{title}</h1>
        <div className="">
          <Menu className="">
            <div className="relative">
              <Menu.Button
                className="flex  justify-end items-center text-gray-600  focus:outline-none bg-gray-200 py-2 px-2 rounded-xl"
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className="text-base ml-2 font-semibold">
                  Welcome {getAuth.name}
                </div>
                {isOpen ? (
                  <RiArrowDropUpLine className="w-5 h-5" />
                ) : (
                  <RiArrowDropDownLine className="w-5 h-5" />
                )}
              </Menu.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              ></Transition>
              <Menu.Items>
                <div className="absolute right-0 z-10 w-48 px-2 py-1 mt-1 text-gray-700 bg-white border rounded-md shadow text-sm">
                  <Menu.Item>
                    <a
                      href="/admin/profile"
                      className="flex items-center space-x-3 px-3 py-2.5 text-md hover:bg-gray-200 hover:rounded cursor-pointer"
                    >
                      Profile
                    </a>
                  </Menu.Item>
                  <hr />
                  <Menu.Item>
                    <a
                      href="#"
                      onClick={() => {
                        Logout();
                      }}
                      className="flex items-center space-x-3 px-3 py-2.5 text-md hover:bg-gray-200 hover:rounded cursor-pointer"
                    >
                      Logout
                    </a>
                  </Menu.Item>
                  <hr />
                </div>
              </Menu.Items>
            </div>
          </Menu>
        </div>
      </div>
    </div>
  );
}

const EachMenu = ({ url, diff_url }) => {
  return (
    <>
      <Menu.Item>
        <span
          // href={`/${diff_url ? diff_url : url.toLowerCase()}`}
          className="flex items-center space-x-3 px-3 py-2.5 text-md hover:bg-gray-200 hover:rounded cursor-pointer"
          // onClick={(url, diff_url) => handleRouting(url, diff_url)}
        >
          {url}
        </span>
      </Menu.Item>
      <hr />
    </>
  );
};

const menus = ["Logout", "Change Password"];

import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import showFunction from "utils/showFunction";
import {
  approval_layer,
  base_url,
  pre_cmr_details,
  get_mvaa,
  ng_customs,
} from "constants/endpoints";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";
import { FaSync } from "react-icons/fa";
import verifyUser from "utils/VerifyUser";
import Loader from "components/Loader";

export default function PreCMREntryDetailsForAnalyser() {
  const { id } = useParams();
  const [userInputVehicle, setUserInputVehicle] = useState([]);
  const getAuth = useAdminAuth((state) => state.auth);
  const [comment, setComment] = useState("");
  const [mvaaLicensingData, setMvaaLicensingData] = useState([]);
  const [ngCustomsData, setNgCustomsData] = useState({});
  const [loadMvaa, setLoadMvaa] = useState(false);
  const [loadncs, setLoadncs] = useState(false);
  const navigate = useNavigate();
  const [isVerifying, setIsVerifying]=useState(false)

  const Approve = (status) => {
    let token = getAuth.token;
    const data = {
      request_id: id,
      comment,
      recommendation: status,
    };
    try {
      fetch(base_url + approval_layer, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            Swal.fire({
              title: status + "...!!!",
              text: "",
              icon: "success",
            }).then(() => {
              setComment("");
              navigate("/admin/precmr");
            });

            return response.json();
          } else {
            Swal.fire({
              title: "Error!",
              text: "Error approving resouce!",
              icon: "error",
            });
          }
        })
        .then((jsonResp) => {
          // window.location.reload();
          console.log(jsonResp);
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error approving resouce!",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    let token = getAuth.token;
    try {
      fetch(base_url + pre_cmr_details + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            Swal.fire({
              title: "Error!",
              text: "Error fetching data from the server!",
              icon: "error",
            }).then(() => {
              window.history.back();
            });
          }
        })
        .then((jsonResp) => {
          setUserInputVehicle(jsonResp.data);
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error fetching data from the server!",
        icon: "error",
      });
    }
  }, []);

  const getNCSData = async () => {
    const token = getAuth.token;
    let url = base_url + ng_customs;
    setLoadncs(true);
    // console.log(userInputVehicle);

    // let request_data = {
    //   vin: "5J6TF2H5XBL000302",
    //   plateNumber: "-",
    //   vehicleModel: "-",
    //   vehicleMake: "-",
    //   phone: "08002201001",
    //   tin: "-",
    //   email: "-",
    // };
    let request_data = {
      vin: userInputVehicle.chassisNumber,
      plateNumber: userInputVehicle.licensePlate || "-",
      vehicleModel: userInputVehicle.model,
      vehicleMake: userInputVehicle.manufacturer,
      phone: userInputVehicle.phone || "-",
      tin: "-",
      email: "-",
    };
    // alert(request_data.vin);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(request_data),
      });
      const responseData = await response.json();
      const { data, statusCode } = responseData;
      if (statusCode == 0 || statusCode == 6) {
        setNgCustomsData(data.data);
        console.log(data.data);
      } else {
        setNgCustomsData({});
        Swal.fire({
          title: "Error!",
          text: `Not Found!`,
          icon: "error",
        });
      }
      setLoadncs(false);
    } catch (e) {
      setNgCustomsData({});
      Swal.fire({
        title: "Error!",
        text: `Error fetching data from the server!`,
        icon: "error",
      });
      setLoadncs(false);
    }
  };

  const getMVAALicensingData = async () => {
    const token = getAuth.token;
    setLoadMvaa(true);
    try {
      const response = await fetch(
        `${base_url}${get_mvaa}?vin=${userInputVehicle.chassisNumber}&state=${userInputVehicle.residentialState}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const responseData = await response.json();
      const { data, statusCode } = responseData;
      if (statusCode === 0) {
        setMvaaLicensingData(data);
      } else {
        Swal.fire({
          title: "Error!",
          text: `Error fetching data from the server!`,
          icon: "error",
        });
      }
      setLoadMvaa(false);
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: `Error fetching data from the server!`,
        icon: "error",
      });
      setLoadMvaa(false);
    }
  };

  if (!getAuth.authenticated === true) {
    window.location = "/admin/login";
    return;
  }

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Information Request Analysis" />
        {/* <div className="flex mx-4 py-1">Navigation here</div> */}
        <div className="bg-white mt-6 pt-1">
          <div className="overflow-x-auto">
            <div className="p-5">
              <div className="flex justify-between">
                <button
                  onClick={() => {
                    window.history.back();
                  }}
                  className="hidden-print bg-blue-400 text-white px-2 py-1 mb-4 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                >
                  &larr; Go Back
                </button>
                <div>
                  <button
                    onClick={getNCSData}
                    className="hidden-print bg-lightBlue-700 text-white px-2 py-1 mb-4 mr-2 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                    disabled={loadncs}
                  >
                    <div className="flex items-center">
                      {loadncs ? (
                        <FaSync className="animate-spin mr-2" />
                      ) : (
                        <FaSync className="mr-2" />
                      )}
                      {loadncs ? "Fetching data..." : "Nigeria Customs"}
                    </div>
                  </button>
                  <button
                    onClick={getMVAALicensingData}
                    className="hidden-print bg-lightBlue-700 text-white px-2 py-1 mb-4 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                    disabled={loadMvaa}
                  >
                    <div className="flex items-center">
                      {loadMvaa ? (
                        <FaSync className="animate-spin mr-2" />
                      ) : (
                        <FaSync className="mr-2" />
                      )}
                      {loadMvaa ? "Fetching data..." : "MVAA"}
                    </div>
                  </button>
                </div>
              </div>

              <table className="analyzer-table">
                <tbody>
                  <tr className="analyzer-table-header">
                    <td>Data Field</td>
                    <td>Applicant Data</td>
                    <td>Licensing Authority Data</td>
                    <td>Nigeria Customs Data</td>
                    <td>CMRIS Report</td>
                  </tr>
                  <tr>
                    <td>Name:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.firstName}{" "}
                      {userInputVehicle?.middleName}{" "}
                      {userInputVehicle?.lastName}
                    </td>
                    <td>
                      {showFunction(
                        mvaaLicensingData.firstname
                          ? `${mvaaLicensingData?.firstname} ${mvaaLicensingData?.lastname} ${mvaaLicensingData?.middlename}`
                          : null
                      )}
                    </td>

                    <td>{"-"}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>Address:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.residentialAddress ?? "-"}
                    </td>
                    <td>{showFunction(mvaaLicensingData.address)}</td>
                    <td>{"-"}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>License Number:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.licensePlate}
                    </td>
                    <td>{showFunction(mvaaLicensingData.plate_no)}</td>
                    <td>{"-"}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>Chasis Number:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.chassisNumber}
                    </td>
                    <td>{showFunction(mvaaLicensingData.chassis)}</td>
                    <td className="text-red-600">
                      {showFunction(ngCustomsData?.VIN)}
                    </td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>Engine Number:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.engineNumber}
                    </td>
                    <td>{showFunction(mvaaLicensingData.engine_number)}</td>
                    <td>{"-"}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>Vehicle Make:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.manufacturer}
                    </td>
                    <td>{showFunction(mvaaLicensingData.model)}</td>
                    <td>{showFunction(ngCustomsData?.Vehicle_Make)}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>Vehicle Model:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.model}
                    </td>
                    <td>{showFunction(mvaaLicensingData.model)}</td>
                    <td>{showFunction(ngCustomsData?.Vehicle_Model)}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>Vehicle Type:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.vehicleType}
                    </td>
                    <td>{showFunction(mvaaLicensingData.type)}</td>
                    <td>{"-"}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>Year of Manufacture:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.year}
                    </td>
                    <td>{"-"}</td>
                    <td>{showFunction(ngCustomsData?.Vehicle_Year)}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>Color:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.color}
                    </td>
                    <td>{showFunction(mvaaLicensingData.color)}</td>
                    <td>{"-"}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>Vehicle Usage:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.vehicleUsage}
                    </td>
                    <td>{"-"}</td>
                    <td>{"-"}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>MVLA:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.mla}
                    </td>
                    <td>{"-"}</td>
                    <td>{"-"}</td>
                    <td>{"-"}</td>
                  </tr>
                  <tr>
                    <td>Watchlist Status:</td>
                    <td className="text-blue-800 font-semibold">
                      {userInputVehicle?.vehicleStatus}
                    </td>
                    <td>{showFunction(mvaaLicensingData.issued_status)}</td>
                    <td>{showFunction(ngCustomsData?.Payment_Status)}</td>
                    <td>{"-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* {ngCustomsData || "-"} */}
          <hr className="mt-4 mb-4" />
          <div className="flex flex-row mb-10">
            <div className="w-1/2">
              <label htmlFor="comment" className="ml-4">
                Comment
              </label>
              <div className="mx-4">
                <textarea
                  size="1000"
                  className="w-full"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                ></textarea>
              </div>
            </div>
            {(userInputVehicle.ownerStatus=="Verified") && <>
            <div className="mt-2">
              <button
                id="approve"
                onClick={() => {
                  Approve("Cleared");
                }}
                className="ml-4 text-gray-100 bg-lightBlue-700 rounded px-4 py-1 mr-4 mt-4"
              >
                Cleared &#10003;
              </button>
              <button
                id="decline"
                onClick={() => {
                  Approve("Error");
                }}
                className="text-gray-100 bg-red-500 rounded px-4 py-1 mr-4 mt-4"
              >
                Not Cleared &#x2715;
              </button>
            </div>
            </>}
            {
              userInputVehicle.ownerStatus == "Not Verified" && 
              <>
              <p className="mt-9 text-red-500">This profile is not verified yet. Click</p>
              <button 
                className="bg-blue-600 text-white px-1 h-8 mt-8 ml-2 rounded-lg hover:bg-black"
                onClick={() => {setIsVerifying(true); verifyUser(userInputVehicle.ownerId, getAuth.token)
                  .then(data => {
                    console.log("Verification successful:", data);
                    if(data.statusCode===0){
                      Swal.fire({
                        title: "Success!",
                        text: "User verified",
                        icon: "success",
                      }).then(() => {
                        setIsVerifying(false);
                        window.location.reload();
                      })
                    }else{
                      Swal.fire({
                        title: "Failed!",
                        text: "User verification failed",
                        icon: "error",
                      }).then(() => {
                        setIsVerifying(false);
                      })
                    }
                    // Handle successful verification (e.g., update UI)
                  })
                  .catch(error => {
                    console.error("Verification failed:", error);
                    // Handle verification errors
                    setIsVerifying(false);
                  })}}>
                  {isVerifying==false ? 'Verify':<Loader />}
                  </button>
              </>
            }
          </div>
          {/* </div>  */}
        </div>
      </div>
    </div>
  );
}

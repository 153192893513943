import { HasAccess } from "@permify/react-role";
import { useFetchFunc } from "Hooks/fetchFunc";
import { useAuth } from "Hooks/useAuth";
import ChangeOwnershipRequestTable from "components/admin/DataTable/ChangeOwnership/Admin/ChangeOwnershipRequestTable";
import PreChangeOwnershipTable from "components/admin/DataTable/ChangeOwnership/Common/PreChangeOwnershipTable";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import {
  get_admin_cow,
  get_analyser_cow,
  get_ccc_cow,
} from "constants/endpoints";
import { useEffect, useMemo, useState } from "react";
import { formatDateNew } from "utils/date_format";
import {
  SelectColumnFilter,
  StatusPill,
} from "../../components/admin/DataTable/ChangeOwnership/Admin/ChangeOwnershipRequestTable";

export default function ChangeOwnershipEntries() {
  const [loading, setLoading] = useState(false);
  const [precow, setPrecow] = useState([]);
  const [cccPrecow, setcccPrecow] = useState([]);
  const [cccAnalysersRequests, setcccAnalysersRequests] = useState([]);
  const masterTableData = useMemo(() => precow, [precow]);
  const commandCenterTableData = useMemo(() => cccPrecow, [cccPrecow]);
  const analysersRequests = useMemo(
    () => cccAnalysersRequests,
    [cccAnalysersRequests]
  );

  // console.log({ cccPrecow, precow, cccAnalysersRequests });
  useAuth();
  const fetchFunc = useFetchFunc();

  useEffect(() => {
    fetchFunc(`${get_admin_cow}?status=Pending`, setPrecow, setLoading);
    fetchFunc(`${get_ccc_cow}?status=Pending`, setcccPrecow, setLoading);
    fetchFunc(
      `${get_analyser_cow}?status=Assigned`,
      setcccAnalysersRequests,
      setLoading
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: () => <div className="w-22">Request Number</div>,
        accessor: "request_number",
      },
      // {
      //   Header: "Request Date",
      //   accessor: "entry_date",
      //   Cell: ({ cell }) => formatDateNew(cell.row.values.entry_date),
      // },
      {
        Header: "Current Owner",
        accessor: "current_owner_name",
      },
      {
        Header: "New Owner",
        accessor: "new_owner_name",
      },
      // {
      //   Header: "Request State",
      //   accessor: "request_state",
      // },
      {
        Header: () => <div className="w-full">Licence Number</div>,
        accessor: "license_plate",
      },
      {
        Header: "Chassis Number",
        accessor: "chassis_number",
      },
      // {
      //   Header: "Certificate Number",
      //   accessor: "certificate_number",
      // },

      // {
      //   Header: "Reference",
      //   accessor: "reference",
      //   // enableHiding: true,
      // },
      // {
      //   Header: "Recommendation",
      //   accessor: "recommendations",
      // },
      {
        Header: "Vehicle Make",
        accessor: "vehicle_make",
      },
      {
        Header: "Command Centre",
        accessor: "command_center",
        Filter: SelectColumnFilter,
      },

      {
        Header: "Watchlist",
        accessor: "vehicle_status",
        Cell: StatusPill,
      },
      // {
      //   Header: "Vehicle Type",
      //   accessor: "vehicle_type",
      // },

      // {
      //   Header: "Colour",
      //   accessor: "color",
      // },
      {
        Header: "Actions",
        accessor: "cow_id",
        Cell: ({ cell }) => (
          <>
            <a
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              disabled={true}
              href={`/admin/cow/details/${cell.row.values.cow_id}`}
            >
              Details
            </a>
          </>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Change of Ownership Request List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <ChangeOwnershipRequestTable
                columns={columns}
                data={masterTableData}
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-head", "cic-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <PreChangeOwnershipTable
                columns={columns}
                data={commandCenterTableData}
                // data={cccprecmrs}
                setcccPrecow={setcccPrecow}
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-analyser", "cic-operative"]}
              // permissions=""
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Assigned table */}
              <PreChangeOwnershipTable
                columns={columns}
                data={analysersRequests}
                loading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

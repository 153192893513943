import { useMemo } from "react";
import {
  SelectColumnFilter,
  StatusPill,
} from "../components/admin/DataTable/ProcessedPreCMRTable";

export const useColums = (type) => {
  const rawColumn = [
    {
      Header: "S/No",
      id: "index",
      accessor: (row, i) => i + 1,
    },
    {
      Header: "Name",
      accessor: "Name",
    },
    {
      Header: "Request Number",
      accessor: "RequestNumber",
    },
    {
      Header: "Licence Number",
      accessor: "LicensePlate",
    },
    {
      Header: "Chassis Number",
      accessor: "ChassisNumber",
    },
    {
      Header: "Engine Number",
      accessor: "EngineNumber",
    },

    {
      Header: "Recommendation",
      accessor: "Recommendation",
      Cell: ({ cell }) => (
        <span
          className={`${
            cell.row.values.Recommendation === "Cleared"
              ? "bg-green-400 rounded px-3 py-1 text-black-400"
              : "bg-red-500 rounded px-3 py-1 text-black-400"
          } rounded p-1`}
        >
          {cell.row.values.Recommendation}
        </span>
      ),
    },

    {
      Header: "Make/Model",
      accessor: "Model",
    },
    {
      Header: "Vehicle Status",
      accessor: "VehicleStatus",
      Cell: StatusPill,
      Filter: SelectColumnFilter,
    },
    {
      Header: "Command Centre",
      accessor: "CommandCenter",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Actions",
      accessor: "CMREntryId",
      Cell: ({ cell }) => (
        <>
          <div className="flex flex-row">
            <a
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              // value={cell.row.values.id}
              disabled={true}
              onClick={() => {}}
              href={"/admin/precmr/" + cell.row.values.CMREntryId}
            >
              Details
            </a>
          </div>
        </>
      ),
    },
  ];
  if (type === "admin") {
    rawColumn.splice(6, 1);
  }
  const columns = useMemo(() => rawColumn);
  return columns;
};

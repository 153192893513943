import { HasAccess } from "@permify/react-role";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import {
  base_url,
  get_admin_request,
  get_ccc_request,
  tint_entries,
} from "constants/endpoints";
import { useColums } from "Hooks/useColums";
import { useEffect, useMemo, useState } from "react";
import { useAdminAuth } from "store/AdminAuth";
import ProcessedPreCMRTable, {
  SelectColumnFilter,
  StatusPill,
} from "../../components/admin/DataTable/ProcessedPreCMRTable";
import PreCMRTable from "../../components/admin/DataTable/PreCMRTable";
import { formatDateNew } from "utils/date_format";
import PreTintTable from "components/admin/DataTable/PreTintTable";
import ProcessedPreTintTable from "components/admin/DataTable/ProcessedPreTintTable";

export default function ProcessedTintPermitRequests() {
  const [loading, setLoading] = useState(false);
  const [precmrs, setPreCMRs] = useState([]);
  const [cccprecmrs, setcccPrecmrs] = useState([]);
  const masterTableData = useMemo(() => precmrs, [precmrs]);
  const commandCenterTableData = useMemo(() => cccprecmrs, [cccprecmrs]);
  const getAuth = useAdminAuth((state) => state.auth);
  const column_admin = useColums("admin");
  const column = useColums("");

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      //   fetch(base_url + assigned_cmr_requests, {
      fetch(`${base_url}${tint_entries}?status=processed`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp)
          if (jsonResp.data != null) {
            setPreCMRs(jsonResp.data);
            setLoading(false);
            // console.log(jsonResp.data);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      //   fetch(base_url + pre_cmr_by_cc, {
      fetch(`${base_url}${tint_entries}?status=processed`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp)
          if (jsonResp.data != null) {
            setcccPrecmrs(jsonResp.data);
            setLoading(false);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: () => <div className="w-22">Request Number</div>,
        accessor: "request_number",
      },
      {
        Header: "Name",
        accessor: "owner",
      },
      {
        Header: "Licence Number",
        accessor: "license_plate",
      },
      {
        Header: "Chassis Number",
        accessor: "chassis_number",
      },
      {
        Header: "Make/Model",
        accessor: "model",
      },
      {
        Header: "Vehicle Status",
        accessor: "vehicle_status",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Tint Status",
        accessor: "status",
      },
      {
        Header: "Processed Date",
        accessor: "processed_at",
        Cell: ({ cell }) => (
          <span>{formatDateNew(cell.row.values.processed_at)}</span>
        ),
      },
      {
        Header: "Command",
        accessor: "request_state",
        Filter: SelectColumnFilter,
      },
    ],
    []
  );

  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Tint Permit Processed List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <PreTintTable
                columns={columns}
                data={masterTableData}
                action="link"
                setPreCMRs={setPreCMRs}
                setcccPrecmrs={setcccPrecmrs}
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <ProcessedPreTintTable
                columns={columns}
                data={commandCenterTableData}
                action="link"
                setcccPrecmrs={setcccPrecmrs}
                setPreCMRs={setPreCMRs}
                userloading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import Sidebar from "components/admin/SideBars/Sidebar";
import Header from "components/admin/Headers/Header";
import { base_url, pending_reports } from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";
import Table, {
  StatusPill,
  SelectColumnFilter,
} from "../../components/admin/DataTable/Table";
import { HasAccess } from "@permify/react-role";

export default function PendingStolenReports() {
  const [reported_stolens, setReportedStolen] = useState([]);
  const data = useMemo(() => reported_stolens, [reported_stolens]);
  const getAuth = useAdminAuth((state) => state.auth);

  useEffect(() => {
    let token = getAuth.token;
    try {
      fetch(base_url + pending_reports, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          if (jsonResp.data != null) {
            setReportedStolen(jsonResp.data);
            // console.log(jsonResp.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Licence Plate Number ",
        accessor: "LicensePlate",
      },
      {
        Header: "Make",
        accessor: "Manufacturer",
      },
      {
        Header: "Model",
        accessor: "Model",
      },
      {
        Header: "Type",
        accessor: "Type",
        // Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Color",
        accessor: "Color",
        // Cell: StatusPill,
        // Filter: SelectColumnFilter,
      },
      {
        Header: "State Where Stolen",
        accessor: "State",
        Filter: SelectColumnFilter, // new
      },
      {
        Header: "Date Reported",
        accessor: "DateReported",
      },
      {
        Header: "CMR Status",
        accessor: "VehicleStatus",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      // {
      //   Header: "Chassis Number",
      //   accessor: "ChassisNumber",
      // },
      // {
      //   Header: "Engine Number",
      //   accessor: "EngineNumber",
      // },

      {
        Header: "CMR Info Center",
        accessor: "CommandCenter",
        // Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
    ],
    []
  );

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="Reported Stolen Vehicles Pending Processing" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <Table columns={columns} data={data} action="link" />
          </div>
        </div>
      </div>
    </div>
  );
}

import { HasAccess } from "@permify/react-role";
import { useFetchFunc } from "Hooks/fetchFunc";
import { useAuth } from "Hooks/useAuth";
import ChangeOwnershipRequestTable from "components/admin/DataTable/ChangeOwnership/Admin/ChangeOwnershipRequestTable";
import GeneralChangeOwnershipTable from "components/admin/DataTable/ChangeOwnership/Head/GeneralChangeOwnershipTable";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import { get_admin_cow, get_ccc_cow, public_base } from "constants/endpoints";
import { useEffect, useMemo, useState } from "react";
import { formatDateNew } from "utils/date_format";
import { SelectColumnFilter } from "../../components/admin/DataTable/FinalizedCMRTable";

export default function FinalizedChangeOwnershipRequests() {
  const [loading, setLoading] = useState(false);
  const [adminCow, setadminCow] = useState([]);
  const [cccCow, setcccCow] = useState([]);
  const masterTableData = useMemo(() => adminCow, [adminCow]);
  const commandCenterTableData = useMemo(() => cccCow, [cccCow]);
  useAuth();
  const fetchFunc = useFetchFunc();

  useEffect(() => {
    fetchFunc(`${get_admin_cow}?status=finalized`, setadminCow, setLoading);
    fetchFunc(`${get_ccc_cow}?status=finalized`, setcccCow, setLoading);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: () => <div className="w-full">Request Number</div>,
        accessor: "request_number",
      },
      {
        Header: "Previous Owner",
        accessor: "current_owner_name",
      },
      {
        Header: "New Owner",
        accessor: "new_owner_name",
      },
      {
        Header: "Licence Number",
        accessor: "license_plate",
      },
      {
        Header: "Chassis Number",
        accessor: "chassis_number",
      },
      {
        Header: "Make/Model",
        accessor: "vehicle_make",
      },

      {
        Header: "Request State",
        accessor: "request_state",
      },
      {
        Header: "Certificate Number",
        accessor: "certificate_number",
      },
      {
        Header: "Finalized Date",
        accessor: "finalized_at",
        Cell: ({ cell }) => (
          <span>{formatDateNew(cell.row.values.finalized_at)}</span>
        ),
      },
      {
        Header: "Command Centre",
        accessor: "command_center",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => (
          <span
            className={`${
              cell.row.values.status === "Approved"
                ? "border-2 border-green-500 rounded px-3 py-2 text-green-500"
                : "border-2 border-red-500 rounded px-3 py-2 text-red-500"
            } rounded p-1`}
          >
            {cell.row.values.status}
            {cell.row.values.status === "Approved" ? (
              <span>&#10003;</span>
            ) : (
              <span>&#x2715;</span>
            )}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "cow_id",
        Cell: ({ cell }) => (
          <>
            {/* <button
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              value={cell.row.values.CMREntryId}
              disabled={false}
              onClick={() => {}}
            >
              View Reciept
            </button> */}

            <button
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900 ml-3`}
              value={cell.row.values.cow_id}
              disabled={false}
              onClick={() => {
                let url = public_base+"/cowcert/"+cell.row.values.cow_id
                window.open(url,'_blank', 'rel=noopener noreferrer')
              }}
            >
              View Certificates
            </button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Change of Ownership Request List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <ChangeOwnershipRequestTable
                columns={columns}
                data={masterTableData}
                // action="link"
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-head", "cic-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <GeneralChangeOwnershipTable
                columns={columns}
                data={commandCenterTableData}
                action="link"
                loading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

import { HasAccess } from "@permify/react-role";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import {
  base_url,
  get_admin_request,
  get_ccc_request,
} from "constants/endpoints";
import { useColums } from "Hooks/useColums";
import { useEffect, useMemo, useState } from "react";
import { useAdminAuth } from "store/AdminAuth";
import ProcessedPreCMRTable, {
  SelectColumnFilter,
  StatusPill,
} from "../../components/admin/DataTable/ProcessedPreCMRTable";
import PreCMRTable from "../../components/admin/DataTable/PreCMRTable";

export default function ProcessedCMRRequests() {
  const [loading, setLoading] = useState(false);
  const [precmrs, setPreCMRs] = useState([]);
  const [cccprecmrs, setcccPrecmrs] = useState([]);
  const masterTableData = useMemo(() => precmrs, [precmrs]);
  const commandCenterTableData = useMemo(() => cccprecmrs, [cccprecmrs]);
  const getAuth = useAdminAuth((state) => state.auth);
  const column_admin = useColums("admin");
  const column = useColums("");

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      //   fetch(base_url + assigned_cmr_requests, {
      fetch(`${base_url}${get_admin_request}?status=processed`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          if (jsonResp.data != null) {
            setPreCMRs(jsonResp.data);
            setLoading(false);
            // console.log(jsonResp.data);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      //   fetch(base_url + pre_cmr_by_cc, {
      fetch(`${base_url}${get_ccc_request}?status=processed`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          if (jsonResp.data != null) {
            setcccPrecmrs(jsonResp.data);
            setLoading(false);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Request Number",
        accessor: "RequestNumber",
      },
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Licence Number",
        accessor: "LicensePlate",
      },
      {
        Header: "Chassis Number",
        accessor: "ChassisNumber",
      },
      {
        Header: "Engine Number",
        accessor: "EngineNumber",
      },
      {
        Header: "Recommendation",
        accessor: "Recommendation",
        Cell: ({ cell }) => (
          <span
            className={`${
              cell.row.values.Recommendation === "Cleared"
                ? "bg-green-400 rounded px-3 py-1 text-black-400"
                : "bg-red-500 rounded px-3 py-1 text-black-400"
            } rounded p-1 ml-3`}
          >
            {cell.row.values.Recommendation}
          </span>
        ),
      },
      {
        Header: "Make/Model",
        accessor: "Model",
      },
      {
        Header: "Vehicle Status",
        accessor: "VehicleStatus",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Analyzer",
        accessor: "AssignedTo",
      },
      {
        Header: "Owner Status",
        accessor: "OwnerStatus",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Command Centre",
        accessor: "CommandCenter",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "CMREntryId",
        Cell: ({ cell }) => (
          <>
            <div className="flex flex-row">
              <a
                className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
                // value={cell.row.values.id}
                disabled={true}
                onClick={() => {}}
                href={"/admin/precmr/" + cell.row.values.CMREntryId}
              >
                Details
              </a>
            </div>
          </>
        ),
      },
    ],
    []
  );

  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Information Requests List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <PreCMRTable
                columns={column_admin}
                data={masterTableData}
                action="link"
                setPreCMRs={setPreCMRs}
                setcccPrecmrs={setcccPrecmrs}
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <ProcessedPreCMRTable
                columns={columns}
                data={commandCenterTableData}
                action="link"
                setcccPrecmrs={setcccPrecmrs}
                setPreCMRs={setPreCMRs}
                userloading={loading}
              />
            </HasAccess>

            {/* <HasAccess
              roles={["ccc-analyser"]}
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              <ProcessedPreCMRTable
                columns={columns}
                data={commandCenterTableData}
                action="link"
              />
            </HasAccess> */}
          </div>
        </div>
      </div>
    </div>
  );
}

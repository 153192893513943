import React from "react";

export default function Input({ placeholder, type, value, onChange, name }) {
  return (
    <input
      className="w-full text-sm rounded-md border-2 border-gray-200 hover:border-gray-300 mt-1"
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
}

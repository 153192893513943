import { base_url } from "constants/endpoints";
import { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { useController, useForm } from "react-hook-form";
import color from "../../../constants/colors";
import vehicle_types from "../../../constants/vehicle_types";
import FormAction from "../../FormAction";
import Input from "./Input";
import Select from "./Select";
import states from "../../../constants/states";

export default function FullReportForm() {
  const [formation, setformation] = useState([]);
  const [vehicleMake, setvehicleMake] = useState([]);
  const [vehicleModel, setvehicleModel] = useState([]);
  const [value, setValue] = useState(new Date());

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
    getValues,
  } = useForm({
    defaultValues: {},
  });

  const state = "Edo";

  const fetchData = async (path_url, query, setter) => {
    const url = `${base_url}/api/${path_url}/${query}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = await response.json();
      setter(data);
      console.log(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData("formations", state, setformation);
    fetchData("manufacturers", "", setvehicleMake);
  }, []);

  const incident = useController({
    name: "incident",
    control,
    defaultValue: "",
  });

  const time = useController({
    name: "time",
    control,
    defaultValue: "",
  });

  const incident2 = useController({
    name: "incident2",
    control,
    defaultValue: "",
  });

  const ownerQuestion = useController({
    name: "ownerQuestion",
    control,
    defaultValue: "",
  });

  const id = useController({
    name: "id",
    control,
    defaultValue: "",
  });

  const onSubmit = (data) => {
    console.log({ data, errors });
  };

  return (
    <div className="">
      <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between  my-7 ">
          <h5 className="whitespace-nowrap mr-2">Incident Type:</h5>
          <div className="flex items-center justify-center gap-2 text-left">
            <input
              type="radio"
              id="vehicle_theft"
              name="incident"
              className=" hover:border-green-800"
              value="option1"
              {...register("exampleRequired", { required: true })}
              {...incident.field}
              checked={incident.field.value === "option1"}
              onChange={() => incident.field.onChange("option1")}
            />
            <label htmlFor="vehicle_theft">Stolen Vehicle</label>
          </div>
          <div className="flex items-center justify-center gap-2 ">
            <input
              type="radio"
              id="vehicle_ass-acident"
              name="incident"
              className=" hover:border-green-800"
              value="option2"
              checked={incident.field.value === "option2"}
              onChange={() => incident.field.onChange("option2")}
            />
            <label htmlFor="vehicle_ass-acident">
              Vehicle-Related Incident
            </label>
          </div>
        </div>
        <h4 className="underline font-semibold">Police Formation Details</h4>

        <Input
          label={"CIC’s State Command"}
          name="command"
          id="command"
          type="text"
          register={register}
          required
          errors={errors.nameOfReporter}
        />

        <h4 className="underline font-semibold my-5">Reporter Details</h4>

        <div className="flex justify-between  mt-5 ">
          <h5 className="whitespace-nowrap mr-2">Mode of Identification:</h5>
          <div className=" grid-cols-6  grid gap-5">
            <div className="col-span-1 ">
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  id="id"
                  className=" hover:border-green-800"
                  name="id"
                  value="nin"
                  checked={id.field.value === "nin"}
                  onChange={() => id.field.onChange("nin")}
                />
                <label htmlFor="id">NIN</label>
              </div>
            </div>

            <div className="col-span-1 ">
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  id="id"
                  name="id"
                  className=" hover:border-green-800"
                  value="ndl"
                  checked={id.field.value === "ndl"}
                  onChange={() => id.field.onChange("ndl")}
                />
                <label htmlFor="id">NDL</label>
              </div>
            </div>
            <div className="col-span-1 ">
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  id="id"
                  name="id"
                  className=" hover:border-green-800"
                  value="bvn"
                  checked={id.field.value === "bvn"}
                  onChange={() => id.field.onChange("bvn")}
                />
                <label htmlFor="id">BVN</label>
              </div>
            </div>
            <div className="col-span-2 ">
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  id="id"
                  name="id"
                  className=" hover:border-green-800"
                  value="intPassport"
                  checked={id.field.value === "intPassport"}
                  onChange={() => id.field.onChange("intPassport")}
                />
                <label htmlFor="id">INT’L PASSPORT</label>
              </div>
            </div>

            <div className="col-span-1 ">
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  id="id"
                  name="id"
                  className=" hover:border-green-800"
                  value="tin"
                  checked={id.field.value === "tin"}
                  onChange={() => id.field.onChange("tin")}
                />
                <label htmlFor="id">TIN</label>
              </div>
            </div>
          </div>
        </div>
        <Input
          label={"Identification Number"}
          name="idReporter"
          id="idReporter"
          type="text"
          register={register}
          required
          errors={errors.idReporter}
        />
        <Input
          label={"Name"}
          name="nameOfReporter"
          id="nameOfReporter"
          type="text"
          register={register}
          required
          errors={errors.nameOfReporter}
        />
        <Input
          label={"Address"}
          name="addressOfReporter"
          id="addressOfReporter"
          type="text"
          register={register}
          required
          errors={errors.addressOfReporter}
        />
        <div className="flex justify-between items-center gap-5">
          <Input
            label={"GSM"}
            id="gsm"
            name="gsm"
            type="text"
            register={register}
            required
            errors={errors.gsm}
          />
          <Input
            label={"Email"}
            id="email"
            name="email"
            type="text"
            register={register}
            required
            errors={errors.email}
          />
        </div>

        <h4 className="underline font-semibold mt-5">Vehicle Details</h4>

        <div className="flex justify-between items-center gap-5">
          <Input
            label={"Licence Plate No."}
            id="licencePlate"
            name="licencePlate"
            type="text"
            register={register}
            required
            errors={errors.licencePlate}
          />
          <Input
            label={"VIN/Chassis No."}
            id="vin_chassis"
            name="vin_chassis"
            type="text"
            register={register}
            required
            errors={errors.vin_chassis}
          />
        </div>

        <div className="flex justify-between items-center gap-5">
          <Input
            label={"Engine No."}
            id="enginNo"
            name="engineNo"
            type="text"
            register={register}
            required
            errors={errors.engineNo}
          />
          <Select
            label="Vehicle Make"
            id="vehicleMake"
            name="vehicleMake"
            options={vehicleMake}
            register={register}
            required
            onChange={(e) =>
              fetchData("models", e.target.value, setvehicleModel)
            }
          />
        </div>

        <div className="flex justify-between items-center gap-5 ">
          <Select
            label="Model"
            id="model"
            name="model"
            options={vehicleModel}
            register={register}
            required
          />
          <Select
            label={"Type"}
            id={"type"}
            name="type"
            options={vehicle_types}
            register={register}
            required
          />
        </div>

        <div className="flex justify-between items-center gap-5">
          <Input
            label={"Vehicle Usage"}
            id="vehicleUsage"
            name="vehicleUsage"
            type="text"
            register={register}
            required
            errors={errors.vehicleUsage}
          />
          <Input
            label={"Year"}
            id="year"
            name="year"
            type="text"
            register={register}
            required
            errors={errors.year}
          />
          <Select
            label={"Color"}
            id={"color"}
            name="color"
            options={color}
            register={register}
            required
          />
        </div>
        <div className="flex justify-between items-center gap-5">
          <Input
            label={"Source of Vehicle"}
            id="sourceVehicle"
            name="sourceVehicle"
            type="text"
            register={register}
            required
            errors={errors.sourceVehicle}
          />
          <Input
            label={"Licensing State"}
            id="licensingState"
            name="licensingState"
            type="text"
            register={register}
            required
            errors={errors.licensingState}
          />
        </div>
        <h4 className="underline font-semibold my-5">Owner Details</h4>

        <div className="flex justify-between  my-7 ">
          <h5 className="whitespace-nowrap mr-2">
            Are you the Owner of the Stolen Vehicle?:
          </h5>
          <div className="flex items-center justify-center gap-2 text-left">
            <input
              type="radio"
              id="yes"
              name="ownerQuestion"
              className=" hover:border-green-800"
              value="yes"
              {...register("exampleRequired", { required: true })}
              {...ownerQuestion.field}
              checked={ownerQuestion.field.value === "yes"}
              onChange={() => ownerQuestion.field.onChange("yes")}
            />
            <label htmlFor="yes">Yes</label>
          </div>
          <div className="flex items-center justify-center gap-2 ">
            <input
              type="radio"
              id="no"
              name="ownerQuestion"
              className=" hover:border-green-800"
              value="no"
              checked={ownerQuestion.field.value === "no"}
              onChange={() => ownerQuestion.field.onChange("no")}
            />
            <label htmlFor="no">No</label>
          </div>
        </div>

        {ownerQuestion.field.value === "no" ? (
          <>
            <div className="flex justify-between  mt-5 ">
              <h5 className="whitespace-nowrap mr-2">
                Type of Identification:
              </h5>
              <div className=" grid-cols-6  grid gap-5">
                <div className="col-span-1 ">
                  <div className="flex items-center justify-center gap-2">
                    <input
                      type="radio"
                      id="id"
                      className=" hover:border-green-800"
                      name="id"
                      value="nin"
                      checked={id.field.value === "nin"}
                      onChange={() => id.field.onChange("nin")}
                    />
                    <label htmlFor="id">NIN</label>
                  </div>
                </div>

                <div className="col-span-1 ">
                  <div className="flex items-center justify-center gap-2">
                    <input
                      type="radio"
                      id="id"
                      name="id"
                      className=" hover:border-green-800"
                      value="ndl"
                      checked={id.field.value === "ndl"}
                      onChange={() => id.field.onChange("ndl")}
                    />
                    <label htmlFor="id">NDL</label>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex items-center justify-center gap-2">
                    <input
                      type="radio"
                      id="id"
                      name="id"
                      className=" hover:border-green-800"
                      value="bvn"
                      checked={id.field.value === "bvn"}
                      onChange={() => id.field.onChange("bvn")}
                    />
                    <label htmlFor="id">BVN</label>
                  </div>
                </div>
                <div className="col-span-2 ">
                  <div className="flex items-center justify-center gap-2">
                    <input
                      type="radio"
                      id="id"
                      name="id"
                      className=" hover:border-green-800"
                      value="intPassport"
                      checked={id.field.value === "intPassport"}
                      onChange={() => id.field.onChange("intPassport")}
                    />
                    <label htmlFor="id">INT’L PASSPORT</label>
                  </div>
                </div>

                <div className="col-span-1 ">
                  <div className="flex items-center justify-center gap-2">
                    <input
                      type="radio"
                      id="id"
                      name="id"
                      className=" hover:border-green-800"
                      value="tin"
                      checked={id.field.value === "tin"}
                      onChange={() => id.field.onChange("tin")}
                    />
                    <label htmlFor="id">TIN</label>
                  </div>
                </div>
              </div>
            </div>
            <Input
              label={"Identification Number"}
              name="idReporter"
              id="idReporter"
              type="text"
              register={register}
              required
              errors={errors.idReporter}
            />
            <Input
              label={"Name of Owner"}
              name="nameOfOwner"
              id="nameOfOwner"
              type="text"
              register={register}
              required
              errors={errors.nameOfOwner}
            />
          </>
        ) : null}

        <h4 className="underline font-semibold my-5">
          Incident Report Details
        </h4>

        <div className="flex justify-between items-center gap-5  ">
          <div className="flex flex-col w-full mt-5">
            <label htmlFor="time">Date</label>
            <DateTimePicker
              onChange={setValue}
              id="time"
              value={value}
              className="w-full rounded bo  rder-2 h-11 text-lg hover:border-green-800"
            />
          </div>

          <div className="w-full">
            <Input
              label={" Place/Location of Occurence"}
              register={register}
              required
              name="occurence"
              errors={errors.occurence}
            />
          </div>
        </div>
        <div className="flex justify-between items-center gap-5">
          <Select
            label={"States"}
            id={"states"}
            name="states"
            options={states}
            register={register}
            required
          />
          <Select
            label={"LGA"}
            id={"lga"}
            name="lga"
            options={[]}
            register={register}
            required
          />
        </div>

        <>
          {incident.field.value === "option1" ? (
            <div className="flex justify-between  mt-5 ">
              <h5 className="whitespace-nowrap mr-2">Mode of Stealing:</h5>
              <div className="flex items-center justify-center gap-2 ">
                <input
                  type="radio"
                  id="vehicle_theft"
                  name="incident_checked"
                  className=" hover:border-green-800"
                  value="vehicle_theft"
                  checked={incident2.field.value === "vehicle_theft"}
                  onChange={() => incident2.field.onChange("vehicle_theft")}
                />
                <label htmlFor="vehicle_theft">Carjacked/Snatched</label>
              </div>
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  id="vehicle_ass-acident"
                  name="incident_checked"
                  className=" hover:border-green-800"
                  value="vehicle_theft"
                  checked={incident2.field.value === "vehicle_ass-acident"}
                  onChange={() =>
                    incident2.field.onChange("vehicle_ass-acident")
                  }
                />
                <label htmlFor="vehicle_ass-acident">
                  Removed from Where Parked
                </label>
              </div>
            </div>
          ) : null}
        </>

        <div className="flex items-center justify-items-center mt-10">
          <label
            htmlFor="incidentDetails"
            className="whitespace-nowrap mr-2 self-start"
          >
            Incident Details:
          </label>
          <textarea
            id="incidentDetails"
            className="w-full border-2 hover:border-green-800  h-40 text-lg"
            {...register("incidentDetails", { required: true })}
          />
          <span>{errors.incidentDetails?.message}</span>
        </div>
        <Input
          label={"Police Division/Station Where Reported"}
          name="divisionReported"
          id="divisionReported"
          type="text"
          register={register}
          required
          errors={errors.divisionReported}
        />
        <div className="flex justify-between items-center gap-5  ">
          <div className="flex flex-col w-full mt-5">
            <label htmlFor="time">Date Reported</label>
            <DateTimePicker
              onChange={setValue}
              id="time"
              value={value}
              className="w-full rounded border-2 h-11 text-lg hover:border-green-800"
            />
          </div>

          <div className="w-full">
            <Input
              label={"Police Diary Entry No"}
              register={register}
              required
              name="diary"
              errors={errors.diary}
            />
          </div>
        </div>

        <div className="flex justify-between  mt-5">
          <h5 className="whitespace-nowrap mr-2">Attached Documents:</h5>
          <div className="flex items-center justify-center gap-2 ">
            <input
              type="checkbox"
              id="proof_ownership"
              name="documents"
              className=" hover:border-green-800"
              value="proof_ownership"
              {...register("proof_ownership", { required: true })}
            />
            <label htmlFor="proof_ownership">Proof of Ownership</label>
          </div>
          <div className="flex items-center justify-center gap-2">
            <input
              type="checkbox"
              id="court_affidavit"
              name="documents"
              className=" hover:border-green-800"
              value="court_affidavit"
              {...register("court_affidavit", { required: true })}
            />
            <label htmlFor="court_affidavit">Court Affidavit</label>
          </div>
          <div className="flex items-center justify-center gap-2">
            <input
              type="checkbox"
              id="police_extract"
              name="documents"
              className=" hover:border-green-800"
              value="police_extract"
              {...register("police_extract", { required: true })}
            />
            <label htmlFor="police_extract">Police Extract</label>
          </div>
        </div>

        <Input
          label={` Documents`}
          name="documents_file"
          id="documents_file"
          type="file"
          register={register}
          required
          errors={errors.documents_file}
        />

        <div className="flex flex-col mt-20 mb-10 ">
          <h4 className="font-semibold mx-auto">Official</h4>
          <hr className="border-2 border-solid border-black"></hr>
        </div>

        <div className="flex flex-col mt-5">
          <label
            htmlFor="dopName"
            className="whitespace-nowrap mr-2 self-start"
          >
            Information Entered By
          </label>
          <div className="w-full flex flex-col">
            <input
              type="text"
              id="dopName"
              name="dopName"
              className=" w-full
            h-auto
            p-2
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              {...register("dopName", { required: true })}
            />
            <span>{errors.dopName?.message}</span>
            <h6 className="block mx-auto">AP/Force No., Rank and Name</h6>
          </div>
        </div>

        <div className="flex flex-col mt-5">
          <label
            htmlFor="dopName"
            className="whitespace-nowrap mr-2 self-start"
          >
            Head of Formation/Division/Station
          </label>
          <div className="w-full flex flex-col">
            <input
              type="text"
              id="dopName"
              name="dopName"
              className=" w-full
            h-auto
            p-2
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              {...register("dopName", { required: true })}
            />
            <span>{errors.dopName?.message}</span>
            <h6 className="block mx-auto">Name and Rank</h6>
          </div>
        </div>
        <h4 className="font-semibold text-center mt-5">
          Acknowledgement by CMR Information Centre (CIC)
        </h4>
        <h6 className="py-3">
          I hereby acknowledge receipt of this Stolen Vehicle and Incident
          Report Form
        </h6>
        <Input
          label={"AP/Force No./Rank/Name"}
          name="acknowledgeName"
          id="acknowledgeName"
          type="text"
          register={register}
          required
          errors={errors.acknowledgeName}
        />
        <Input
          label={"CIC unit"}
          name="cicUnit"
          id="cicUnit"
          type="text"
          register={register}
          required
          errors={errors.cicUnit}
        />

        <FormAction action="submit" text="Submit" type="Button" />
      </form>

      <h6 className="flex justify-center mt-14 text-sm">
        The Nigeria Police Force, Central Motor Registry, Force Headquarters
        Annex Lagos
      </h6>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useAdminAuth } from "store/AdminAuth";
import Loader from "components/Loader";
import {
  base_url,
  get_permissions,
  states as stateUrl,
  get_police_officer,
  get_locations,
  register_admin,
} from "constants/endpoints";
import Swal from "sweetalert2";
import { SelectorIcon } from "@heroicons/react/solid";
import { HasAccess } from "@permify/react-role";

export default function RegisterAdmin() {
  const getAuth = useAdminAuth((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [forceNumber, setForceNumber] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [role, setRole] = useState([]);
  const [states, setStates] = useState([]);
  const [cic, setCIC] = useState([]);
  const [cc, setCC] = useState([]);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("");
  const [user, setUser] = useState({
    force_number: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    alternate_phone: "",
    division: "",
    command: "",
    sex: "",
    state: "",
    rank: "",
    email: "",
    subsection: "",
  });

  useEffect(() => {
    fetch(base_url + stateUrl)
      .then((res) => res.json())
      .then((resJson) => {
        setStates(resJson.data);
      });
  }, []);

  useEffect(() => {
    console.log(base_url + get_locations);
    let token = getAuth.token;
    try {
      fetch(base_url + get_locations, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          let cicArr = [];
          let ccArr = [];
          for (const [key, value] of Object.entries(jsonResp.data)) {
            if (value.type === "cic") {
              cicArr[value.id] = value.name;
            } else if (value.type === "cc") {
              ccArr[value.id] = value.name;
            }
          }
          setCIC(cicArr);
          setCC(ccArr);
          // setLocations(dataArr);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const register = () => {
    setIsLoading(true);
    let token = getAuth.token;
    if (user.phone === undefined || user.phone === "") {
      Swal.fire({
        title: "Error!",
        text: "Phone number is required to add a user",
        icon: "error",
      }).then(() => {
        setIsLoading(false);
      });
      return;
    }

    if (user.email === undefined || user.email === "") {
      Swal.fire({
        title: "Error!",
        text: "Email is required to add a user",
        icon: "error",
      }).then(() => {
        setLoadingState(false);
      });
      return;
    }

    if (role === undefined || role === "") {
      Swal.fire({
        title: "Error!",
        text: "Select a role",
        icon: "error",
      }).then(() => {
        setLoadingState(false);
      });
      return;
    }
    let data = {
      username: forceNumber.toUpperCase(),
      first_name: user.first_name.toUpperCase(),
      last_name: user.last_name.toUpperCase(),
      rank: user.rank.toUpperCase(),
      command: user.command,
      division: user.division,
      subsection: user.subsection || "-",
      email: user.email,
      phone: user.phone,
      altertnate_phone: user.alternate_phone,
      password: user.phone,
      confirm_password: user.phone,
      permissions: userPermissions,
      roles: role,
      location: location,
    };

    fetch(base_url + register_admin, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        if (jsonResp.statusCode === 0) {
          Swal.fire({
            title: "New user added!",
            text: " ",
            icon: "success",
          }).then(() => {
            setIsLoading(false);
            setUser({});
            setUserPermissions([]);
            setForceNumber("");
            setRole([]);
          });
        } else if (jsonResp.statusCode === 6) {
          Swal.fire({
            title: "User exists!",
            text: "This user already added!",
            icon: "info",
          }).then(() => {
            setIsLoading(false);
          });
        } else {
        }
      });
  };
  const buildPermissions = (e) => {
    if (e.target.checked === true) {
      setUserPermissions((prevState) => [...prevState, e.target.value]);
    } else {
      setUserPermissions(
        userPermissions.filter((item) => item !== e.target.value)
      );
    }
  };

  const searchUser = () => {
    setLoadingState(true);
    let endpoint = base_url + get_police_officer + forceNumber;
    let token = getAuth.token;
    if (forceNumber === undefined || forceNumber === "") {
      Swal.fire({
        title: "Error!",
        text: "Provide a force Number",
        icon: "error",
      }).then(() => {
        setLoadingState(false);
      });
    }
    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        console.log(jsonResp);
        let data = jsonResp.data;
        setUser({});
        if (data.error) {
          Swal.fire({
            title: "Error!",
            text: data.message,
            icon: "error",
          }).then(() => {
            setLoadingState(false);
          });

          return;
        }
        if (data.data) {
          let phoneString = data.data.phone;
          const phoneArr = phoneString.split(",");
          let phone = "";
          let _alternate_phone = "";
          if (phoneArr.length > 1) {
            phone = phoneArr[0].trim();
            _alternate_phone = phoneArr[1].trim();
          } else {
            phone = phoneArr[0].trim();
          }
          setUser({
            force_number: data.data.force_number,
            first_name: data.data.first_name,
            middle_name: data.data.middle_name,
            last_name: data.data.surname,
            phone: phone,
            alternate_phone: _alternate_phone,
            division: data.data.section_division,
            command: data.data.dep_zone_comd,
            sex: data.data.sex,
            state: data.data.state,
            rank: data.data.rank,
            email: data.data.email,
            subsection: data.data.subsection,
          });
        }
        setLoadingState(false);
      });
  };

  useEffect(() => {
    let token = getAuth.token;

    try {
      fetch(base_url + get_permissions, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          setPermissions(jsonResp.data);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div>
      <div className="mt-8">
        <label htmlFor="forceNumber" className="mr-2">
          AP Number/Force
        </label>
        <input
          type="text"
          onChange={(e) => {
            setForceNumber(e.target.value);
          }}
          className="rounded mr-2 h-10 w-1/6"
        />{" "}
        <button
          onClick={() => {
            searchUser();
          }}
          className="bg-blue-400 text-white rounded p-2"
        >
          Search {loadingState && <Loader />}
        </button>
      </div>
      <div className="leading-loose rounded-lg py-3 mb-2 border-2 border-double border-red-900 px-4 mt-3">
        <div className="flex">
          <div className="flex-1">
            <div className="flex">
              <div className="flex w-40 uppercase">Rank:</div>
              <div className="flex-1 font-semibold uppercase">{user?.rank}</div>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex">
              <div className="flex w-40 uppercase">First Name:</div>
              <div className="flex-1 font-semibold uppercase">
                {user?.first_name}
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div className="flex">
              <div className="flex w-32 uppercase">Last Name:</div>
              <div className="flex-1 font-semibold uppercase">
                {user?.last_name}
              </div>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="flex-1">
            <div className="flex">
              <div className="flex w-40 uppercase">Dept/Zone/Comd:</div>
              <div className="flex-1 font-semibold">{user?.command}</div>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex">
              <div className="flex w-40 uppercase">Section/Division:</div>
              <div className="flex-1 font-semibold">{user?.division}</div>
            </div>
          </div>

          <div className="flex-1">
            <div className="flex">
              <div className="flex w-32 uppercase">Subsection:</div>
              <div className="flex-1 font-semibold">{user?.subsection}</div>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="flex-1">
            <div className="flex">
              <div className="flex w-40 uppercase">Phone:</div>
              <div className="flex-1 font-semibold">{user?.phone}</div>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex">
              <div className="flex w-40 uppercase">Alt Phone:</div>
              <div className="flex-1 font-semibold">
                {user?.alternate_phone || ""}
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex">
              <div className="flex uppercase w-32">Email Address:</div>
              <div className="flex-1 font-semibold">{user?.email}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 mr-3">
          <div className="mt-3">
            <label>Role</label>
          </div>
          <div>
            <select
              onChange={(e) => {
                setRole(() => [e.target.value]);
              }}
              className="rounded p-1 w-full"
            >
              <option value="">Select Role</option>
              <option value="super-admin">Super Admin</option>
              <option value="admin">Admin</option>
              <option value="ccc-head">CCC Head</option>
              <option value="ccc-analyser">CCC Analyser</option>
              <option value="cic-head">CIC Head</option>
              <option value="cic-operative">CIC Operative</option>
              <option value="dashboard">Dashboard</option>
            </select>
          </div>
        </div>
        {(role[0] === "ccc-head" ||
          role[0] === "ccc-analyser" ||
          role[0] === "admin" ||
          role[0] === "dashboard") && (
          <>
            <div className="flex-1 mr-3">
              <div className="mt-3">
                <label>Command Centre</label>
              </div>
              <div>
                <select
                  className="rounded p-1 w-full"
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                >
                  <option value="">Select Command Centre</option>
                  {cc.map((val, index) => {
                    console.log(val);
                    return (
                      <option key={index} value={index}>
                        {val}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}

        {(role[0] === "cic-operative" || role[0] === "cic-head") && (
          <>
            <div className="flex-1">
              <div className="mt-3">
                <label>Select CMR Information Center</label>
              </div>
              <div>
                <select
                  className="rounded p-1 w-full"
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                >
                  <option value="">Select CMR Information Center</option>
                  {cic.map((val, index) => {
                    console.log(val);
                    return (
                      <option key={index} value={index}>
                        {val}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex mt-6">
        {role[0] === "admin" ||
          (role[0] === "super-admin" && (
            <>
              <div className="flex-1 mr-3 px-3 py-1 border-2 rounded-lg border-red-900">
                <div className="font-semibold">Users Permission</div>
                <div className="leading-loose">
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="register-admin-user"
                        className="mr-1 pr-2"
                        name="permissions"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      Add User
                    </label>
                  </div>

                  <div>
                    <label className="">
                      <input
                        type="checkbox"
                        value="view-admin-user"
                        className="mr-1 pr-2"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      View User
                    </label>
                  </div>
                  <div>
                    <label className="">
                      <input
                        type="checkbox"
                        value="modify-admin-user"
                        className="mr-1 pr-2"
                        name="permissions"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      Modify User
                    </label>
                  </div>
                  <div>
                    <label className="">
                      <input
                        type="checkbox"
                        value="disable-admin-user"
                        className="pr-2 mr-1"
                        name="permissions"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      Disable User
                    </label>
                  </div>
                </div>
              </div>
            </>
          ))}

        <div className="flex-1 px-3 py-1 border-2 rounded-lg border-red-900">
          <div className="font-semibold">CMR Compliance Officer</div>
          <div className="leading-loose">
            <div>
              <label>
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  value="register-compliance-officer"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                  name="permissions"
                />
                Register Compliance Officer
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  value="modify-compliance-officer"
                  name="permissions"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                Modify Compliance Officer
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  name="permissions"
                  value="disable-compliance-officer"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                Disable Compliance Officer
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  name="permissions"
                  value="view-compliance-officer"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                View Compliance Officers
              </label>
            </div>
          </div>
        </div>

        <div className="flex-1 px-3 py-1 ml-3 border-2 rounded-lg border-red-900">
          <div className="font-semibold">ANPR</div>
          <div className="leading-loose">
            <div>
              <label>
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  value="assign-anpr"
                  name="permissions"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                Assign ANPR
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  value="unassign-anpr"
                  name="permissions"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                  className="pr-2 mr-1"
                />
                Unassign ANPR
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  name="permissions"
                  value="modify-anpr"
                  className="pr-2 mr-1"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                Modify ANPR
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  name="permissions"
                  value="disable-anpr"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                  className="pr-2 mr-1"
                />
                Enable/Disable ANPR
              </label>
            </div>
          </div>
        </div>

        <div className="flex-1 px-3 py-1 ml-3 border-2 rounded-lg border-red-900">
          <div className="font-semibold">CMR Formation</div>
          <div className="leading-loose">
            <div>
              <label>
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  name="permissions"
                  value="register-formation"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                Register Formation
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  name="permissions"
                  value="modify-formation"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                Modify Formation
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  name="permissions"
                  value="disable-formation"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                Disable Formation
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  name="permissions"
                  value="view-formation"
                />
                View Formations
              </label>
            </div>
          </div>
        </div>

        <div className="flex-1 px-3 py-1 ml-3 border-2 rounded-lg border-red-900">
          <div className="font-semibold">Flash/Stolen/Vehicle Permissions</div>
          <div className="leading-loose">
            <div>
              <label>
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  name="permissions"
                  value="report-stolen-vehicle"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                Report Stolen Vehicle
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  name="permissions"
                  value="make-flash-report"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                Make Flash Report
              </label>
            </div>
            <div>
              <label className="">
                <input
                  type="checkbox"
                  className="pr-2 mr-1"
                  name="permissions"
                  value="make-enquiry"
                  onChange={(e) => {
                    buildPermissions(e);
                  }}
                />
                Make Enquiry
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-4">
        <div className="mr-3 px-3 py-2 flex-1 rounded-lg border-red-900 border-2">
          <div className="font-semibold">Approval Permission</div>
          <div className="flex">
            <div className="flex-1">
              <div>
                <label>
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="approve-stolen-vehicle"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Stolen Vehicle
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="approve-cmr-information"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  CMR Information
                </label>
              </div>

              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="approve-change-of-ownership"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Change of Ownership
                </label>
              </div>

              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="approve-change-of-engine"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Change of Engine
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="approve-spy-plate-permit"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  SPY Plate Number Permit
                </label>
              </div>
            </div>
            <div className="flex-1">
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="approve-change-of-body"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Change of Body/Chassis
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="approve-international-driving-permit"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  International Driving Permit
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="approve-international-certificate-for-vehicle"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  International Certificate for Vehicle
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="approve-change-of-color"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Change of Color
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="approve-tint-permit"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Tint Permit
                </label>
              </div>
            </div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
        <div className="px-3 py-2 flex-1 rounded-lg border-red-900 border-2">
          <div className="font-semibold">Analytics</div>
          <div className="flex">
            <div className="flex-1">
              <div>
                <label>
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="view-stolen-vehicle-analytic"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Stolen Vehicle
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="view-cmr-information-analytic"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  CMR Information
                </label>
              </div>

              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="view-change-of-ownership-analytic"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Change of Ownership
                </label>
              </div>

              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="view-change-of-engine-analytic"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Change of Engine
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="view-spy-plate-permit-analytic"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  SPY Plate Number Permit
                </label>
              </div>
            </div>
            <div className="flex-1">
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="view-change-of-body-analytic"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Change of Body/Chassis
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="view-international-driving-permit-analytic"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  International Driving Permit
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="view-international-certificate-for-vehicle-analytic"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  International Certificate for Vehicle
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="view-change-of-color-analytic"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Change of Color
                </label>
              </div>
              <div>
                <label className="">
                  <input
                    type="checkbox"
                    className="pr-2 mr-1"
                    name="permissions"
                    value="view-tint-permit-analytic"
                    onChange={(e) => {
                      buildPermissions(e);
                    }}
                  />
                  Tint Permit
                </label>
              </div>
            </div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="flex mt-4">
          <div className="px-3 py-2 flex-1 rounded-lg border-red-900 border-2">
              <div className="font-semibold">Other Permissions</div>
              <div className="flex">
                  <div className="flex-1">
                    <label>
                      <input
                        type="checkbox"
                        className="pr-2 mr-1"
                        name="permissions"
                        value="onboard-assist-full-access"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      CMR Assist Full Access
                    </label>
                  </div>

                  <div className="flex-1">
                    {/* <label>
                      <input
                        type="checkbox"
                        className="pr-2 mr-1"
                        name="permissions"
                        value="onboard-assist-full-access"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      CMR Assist Full Access
                    </label> */}
                  </div>
                  <div className="flex-1">
                    {/* <label>
                      <input
                        type="checkbox"
                        className="pr-2 mr-1"
                        name="permissions"
                        value="onboard-assist-full-access"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      CMR Assist Full Access
                    </label> */}
                  </div>
                  <div className="flex-1">
                    {/* <label>
                      <input
                        type="checkbox"
                        className="pr-2 mr-1"
                        name="permissions"
                        value="onboard-assist-full-access"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      CMR Assist Full Access
                    </label> */}
                  </div>
                  <div className="flex-1">
                    {/* <label>
                      <input
                        type="checkbox"
                        className="pr-2 mr-1"
                        name="permissions"
                        value="onboard-assist-full-access"
                        onChange={(e) => {
                          buildPermissions(e);
                        }}
                      />
                      CMR Assist Full Access
                    </label> */}
                  </div>
              </div>
          </div>
      </div>
      <button
        className="bg-blue-500 px-3 py-1 mt-3 mb-10 rounded text-white"
        onClick={() => {
          register();
        }}
      >
        Add {isLoading && <Loader />}
      </button>
    </div>
  );
}

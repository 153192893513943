import React from "react";
import { useAdminAuth } from "store/AdminAuth";

export default function ChangeOfOwnershipRequest() {
  const getAuth = useAdminAuth((state) => state.auth);
  const role = localStorage.getItem("__permifyUser");
  const roleJson = JSON.parse(role);

  return (
    <>
      <div
        className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
      >
        CMR Motor Change of Ownership Request
      </div>
      {getAuth.cc === "Abuja" && (
        <>
          <iframe
            className="w-full"
            src="https://lookerstudio.google.com/embed/reporting/fb4b5932-b103-40f3-985c-63ba158889ed/page/Uq88C"
            height="900"
            width="1500"
            title="CMR Motor Vehicle Change of Ownership Request"
            style={{ border: "none" }}
            allowFullScreen
          ></iframe>
        </>
      )}

      {getAuth.cc === "Lagos" && (
        <>
          <iframe
            className="w-full"
            src="https://lookerstudio.google.com/embed/reporting/fb4b5932-b103-40f3-985c-63ba158889ed/page/Uq88C"
            height="900"
            width="1500"
            title="CMR Motor Vehicle Change of Ownership Request"
            style={{ border: "none" }}
            allowFullScreen
          ></iframe>
        </>
      )}
      {(getAuth.cc === "" || getAuth.cc === undefined) && (
        <>
          <iframe
            className="w-full"
            width="1500"
            height="900"
            src="https://lookerstudio.google.com/embed/reporting/fb4b5932-b103-40f3-985c-63ba158889ed/page/Uq88C"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            title="CMR Motor Vehicle Change of Ownership Request"
          ></iframe>
        </>
      )}
    </>
  );
}

import React, { useState, useMemo, useEffect } from "react";
import Sidebar from "components/admin/SideBars/Sidebar";
import Header from "components/admin/Headers/Header";
import Table from "components/Table";
import RegisterPermission from "components/admin/auth/RegisterPermission";
import { base_url, get_permissions } from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";

export default function Permission() {
  const [menu, setMenu] = useState(0);
  const [permissions, setPermissions] = useState([]);
  const data = useMemo(() => permissions, [permissions]);
  const getAuth = useAdminAuth((state) => state.auth);
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: " ",
      },
      {
        Header: "Permission",
        accessor: "name",
      },
    ],
    []
  );

  useEffect(() => {
    let token = getAuth.token;

    try {
      fetch(base_url + get_permissions, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          setPermissions(jsonResp.data);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="Roles/Permissions" />
        <div className="flex mx-4 py-1 w-1/5">
          <div
            onClick={() => {
              setMenu(0);
            }}
            className={`flex-auto hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100 ${
              menu === 0 && "text-yellow-300"
            }`}
          >
            Permisions List
          </div>
          <div
            onClick={() => {
              setMenu(1);
            }}
            className={`flex-auto hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100 ${
              menu === 1 && "text-yellow-300"
            }`}
          >
            Create Permissions
          </div>
        </div>
      </div>
      <div className="md:ml-64">
        <div className="mx-3">
          {menu === 0 && <Table columns={columns} data={data} />}
          {menu === 1 && <RegisterPermission />}
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { loginFields } from "../../../constants/loginFormFields";
import Input from "../../Input";
import FormAction from "../../FormAction";
import { base_url, login, get_my_camera } from "../../../constants/endpoints";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";
import { useEnforcerAuth } from "store/EnforcerAuth";
import { checkEnforcerToken } from "components/checkToken";

const fields = loginFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Login() {
  const getAuth = useEnforcerAuth((state) => state.auth);
  const setEnforcerAuth = useEnforcerAuth((state) => state.setEnforcerAuth)
  const token = getAuth.token;
  const [loadingState, setLoadingState] = useState(false);

  const [loginState, setLoginState] = useState(fieldsState);

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    //Confirm the token
    if (getAuth.token !=""){
      checkEnforcerToken(getAuth.token).then((auth) => {
        if(auth===true){
          if(getAuth.camera==""){
            window.location.href="/vcheck/search"
          }else{
            window.location.href="/vcheck/enforce"
          }
        }
      })
    }
  })

  //Handle Login API Integration here
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingState(true);
    let endpoint = base_url + login;
    var data = {
      loggedIn: false,
      token: "",
    };
    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginState),
    })
      .then((response) => response.json())
      .then((respJson) => {
        if (respJson.data.statusCode === 0) {
          setEnforcerAuth({
            token: respJson.data.token,
            authenticated: true,
            camera: respJson.data.camera,
            state: respJson.data.state,
            location: respJson.data.location,
          });
          if (respJson.data.camera == "") {
            window.location.href = "/vcheck/search";
            return
          }else{
            window.location.href = "/vcheck/enforce";
          }
        } else {
          Swal.fire({
            title: "Error!",
            text: "Wrong Login Credentials!",
            icon: "error",
          });
        }
      });
  };

  // if (token) {
  //   if (getAuth.camera == "") {
  //     return <Navigate to={"/vcheck/search"} />;  
  //   }else{
  //     return <Navigate to={"/enforcement/enforce"} />;
  //   }
  // }
  return (
    <div className="container md:w-1/3 pb-12 border-b-4 rounded-lg border-green-800 mx-auto -mt-14">
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="-space-y-px ml-4 mr-4 lg:ml-0 lg:mr-0">
          {fields.map((field) => (
            <Input
              key={field.id}
              handleChange={handleChange}
              value={loginState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
            />
          ))}
        </div>
        <div className="mr-4 ml-4 lg:ml-0 lg:mr-0">
          <FormAction 
            handleSubmit={handleSubmit} 
            text="Login" 
            loadingState={loadingState}
          />
          </div>
        
      </form>
    </div>
  );
}

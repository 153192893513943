import { useAdminAuth } from "store/AdminAuth";
export const useAuth = () => {
  const getAuth = useAdminAuth((state) => state.auth);
  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return {
    token: getAuth.token,
  };
};

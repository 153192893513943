import React from "react";
import { HasAccess } from "@permify/react-role";
function DashboardCard({ start, top, bottom }) {
  return (
    <>
      <HasAccess
        roles={["ccc-head", "ccc-analyser"]}
        permissions=""
        renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
      >
        <div
          className={`${
            start
              ? "text-left bg-gray-600 text-white font-medium"
              : "text-center bg-gray-400"
          } h-[55px]  text-sm hover:text-yellow-100   rounded p-2 text-black-100 flex justify-center items-center`}
        >
          {top}
        </div>
      </HasAccess>
      <HasAccess
        roles={["ccc-head"]}
        permissions=""
        renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
      >
        <div
          className={`${
            start
              ? "text-left bg-blue-400 text-white font-medium"
              : "text-center bg-blue-200"
          } h-[55px]  text-sm hover:text-yellow-100   rounded p-2 text-black-100 flex justify-center items-center`}
        >
          {bottom}
        </div>
      </HasAccess>
    </>
  );
}

export default DashboardCard;

import create from "zustand";
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  auth: {
    token: "",
    authenticated: false,
    camera: "",
    state: "",
    location: "",
  },
  setEnforcerAuth: (auth) =>
    set(
      produce((temp) => {
        temp.auth = {
          token: auth.token,
          authenticated: auth.authenticated,
          camera: auth.camera,
          state: auth.state,
          name: auth.location,
        };
      })
    ),

  reset: (auth) => set(auth),
});
// store = devtools(store);
store = persist(store, { name: "enforcerAuth" });
export const useEnforcerAuth = create(store);

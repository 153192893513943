import React from "react";
import Profile from "../../components/admin/Profile";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";

function ProfilePage() {
  return (
    <>
      <div className="">
        <Sidebar />
        <div className="md:ml-64 bg-blueGray-600">
          <Header title="Profile" />
        </div>
        <div className="pl-64 mx-auto">
          <Profile />;
        </div>
      </div>
    </>
  );

  // <Profile />;
}

export default ProfilePage;

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { HasAccess } from "@permify/react-role";
import Loader from "components/Loader";
import { approval, base_url } from "constants/endpoints";
import React, { useState } from "react";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table"; // new
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";
import { Button, PageButton } from "../../../shared/Button";
import { classNames } from "../../../shared/Utils";

// Define a default UI for filtering
export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "";
  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("valid") ? "bg-green-100 text-green-700" : null,
        status.startsWith("conflict") ? "bg-red-100 text-red-700" : null,
        status.startsWith("stolen") ? "bg-red-700 text-yellow-100" : null,
        status.startsWith("pending") ? "bg-yellow-100 text-red-700" : null
      )}
    >
      {status}
    </span>
  );
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-900">Search: </span>
      <input
        type="text"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700 whitespace-nowrap">
        {render("Header")}:{" "}
      </span>
      <select
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      // setPageIndex(1);
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const ActionButton = ({ assign, value, loading, length, label }) => {
  const symbol = value === "Approve" ? "\u2713" : "\u2715";
  return (
    <button
      className={`${
        label === "Approve" ? "bg-blue-400" : "bg-red-400"
      } mr-2 rounded px-3 py-1 text-gray-100 hover:text-gray-100 hover:bg-gray-900 whitespace-nowrap`}
      onClick={() => {
        assign(value);
      }}
      disabled={length === 0 || loading}
    >
      {
        <>
          {label} {symbol}
          {loading && <Loader />}
        </>
      }
    </button>
  );
};

function ProcessedPreTintTable({
  columns,
  data,
  action,
  setPreCMRs,
  setcccPrecmrs,
  userloading,
}) {
  const getAuth = useAdminAuth((state) => state.auth);
  // const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  // const { isAuthorized, isLoading } = usePermify();
  const [loading, setLoading] = useState({ positive: false, negative: false });

  const dataSetter = (setter) => {
    setter(
      data.filter(
        (item) =>
          !itemToAssign.some((itemAssign) => itemAssign === item.Id)
      )
    );
  };

  const assign = async (status) => {
    status === "Approved"
      ? setLoading({ positive: true, negative: false })
      : setLoading({ positive: false, negative: true });

    const token = getAuth.token;
    const url = `${base_url}${approval}`;
    const apiData = itemToAssign.map((item) => {
      return String(item);
    });
    const approvalData = {
      request_id: apiData,
      service: "idp",
      request_status: status,
    };

    fetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(approvalData),
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        if (jsonResp.statusCode === 0) {
          dataSetter(setcccPrecmrs);
          dataSetter(setPreCMRs);
          Swal.fire({
            title: status,
            text: " ",
            icon: "success",
          }).then(() => {
            setShowModal(false);
          });
        }
        setLoading({ positive: false, negative: false });
      })
      .catch((error) => {
        setLoading({ positive: false, negative: false });
        console.log(error);
      });
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["Reference"],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      // if (!isAuthorized(["ccc-head"], "")) {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <HasAccess
                roles={["ccc-head", "cic-head"]}
                permissions=""
                renderAuthFailed={<p className="text-red-700 text-4xl"></p>}
              >
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </HasAccess>
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <HasAccess
                roles={["ccc-head", "cic-head"]}
                permissions=""
                renderAuthFailed={<p className="text-red-700 text-4xl"></p>}
              >
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </HasAccess>
            </div>
          ),
        },
        ...columns,
      ]);
    }
    // } // new
  );

  const itemToAssign = selectedFlatRows.map((d) => d.original.Id);

  const [showModal, setShowModal] = useState(false);

  // Render the UI for your table
  return (
    <>
      <div className="flex gap-x-2">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div key={column.id}>
                {/* <label htmlFor={column.id}>{column.render("Header")}: </label> */}
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}

        {/* <HasAccess
          roles={["ccc-head", "cic-head"]}
          permissions=""
          renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
        >
          <span className="mt-2 flex">
            <ActionButton
              value="Approved"
              length={itemToAssign.length}
              loading={loading.positive}
              assign={assign}
              label="Approve"
            />
            <ActionButton
              length={itemToAssign.length}
              assign={assign}
              loading={loading.negative}
              value="Not Approved"
              label="Not Approved"
            />
          </span>
        </HasAccess> */}

        <span className="mt-2">
          <HasAccess
            roles={["ccc-head", "cic-head"]}
            permissions=""
            renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
          ></HasAccess>
        </span>
      </div>
      <div className="mt-2 flex flex-col w-full">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            {userloading ? (
              <div className="flex justify-center my-20">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <>
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table
                    {...getTableProps()}
                    className="min-w-full divide-y divide-gray-200"
                  >
                    <thead className="bg-gray-50">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-sm font-bold text-black uppercase tracking-wider"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " ▼"
                                    : " ▲"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    {data.length === 0 ? (
                      <tbody className="text-center">
                        <tr className="text-center">
                          <td
                            className="text-center font-bold p-20 text-xl"
                            colSpan="10"
                          >
                            No Request Found
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody
                        {...getTableBodyProps()}
                        className="bg-white divide-y divide-gray-200"
                      >
                        {page.map((row, i) => {
                          // new
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps()}
                              // onClick={() => viewDetails(row.original, action)}
                              className="odd:bg-white text-sm even:bg-lightBlue-100 hover:even:bg-lightBlue-200 hover:odd:bg-gray-50 cursor-pointer"
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    className="px-6 py-4 whitespace-nowrap"
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* new */}
      <div className="py-3 flex items-center justify-between">
        <div className="flex-1 flex justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </Button>
          <Button
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            Next
          </Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex gap-x-2">
            <label>
              <span className="sr-only">Items Per Page</span>
              <select
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className="flex gap-x-2">
            <label>
              <span className="sr-only">Page count</span>
              <span
                className={`bg-gray-400 px-3 py-2 rounded text-black ${
                  pageCount === 0 && "hidden"
                }`}
              >
                {pageCount !== 0 ? "Page" : ""}{" "}
                {pageCount !== 0 ? currentPage + " of " + pageCount : ""}
              </span>
            </label>
          </div>

          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <PageButton
                className="rounded-l-md"
                onClick={() => {
                  gotoPage(0);
                  setCurrentPage(1);
                }}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => {
                  previousPage();
                  setCurrentPage(currentPage - 1);
                }}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => {
                  nextPage();
                  setCurrentPage(currentPage + 1);
                }}
                disabled={!canNextPage}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => {
                  gotoPage(pageCount - 1);
                  setCurrentPage(pageCount);
                }}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProcessedPreTintTable;

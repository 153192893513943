import React, {useState, useEffect} from "react";
import Input from "components/Input";
import { add_model, manufacturers as makers, base_url } from "constants/endpoints";
import Loader from "components/Loader";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";

function AddModel() {
  const [manufacturers, setManufacturers] = useState([]);
  const [newManufacturer, setNewManufacturer] = useState(false);
  const [loading, setLoading] = useState(false);
  const getAuth = useAdminAuth((state) => state.auth);

  const [inputs, setInputs] = useState({
    manufacturer:"",
    model:"",
    category:""
  })

  const [errorMessage, setErrorMessage]=useState({
    manufacturer:"",
    model:"",
    category:""
  })

  const handleChange = (e) => {
    setInputs({
        ...inputs,
        [e.target.name] : e.target.value
    })
  }

  useEffect(() => {
    console.log(makers)
    fetch(base_url + makers+inputs.category)
      .then((res) => res.json())
      .then((resJson) => {
        setManufacturers(resJson.data);
      });
  }, [inputs.category]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(inputs.category=="" || inputs.category == undefined){
      setErrorMessage({
        ...errorMessage,
        category:"Please select a category"
      })
      return
    }

    if(inputs.manufacturer=="" || inputs.manufacturer == undefined){
      setErrorMessage({
        ...errorMessage,
        category:"Manufacturer is required"
      })
      return
    }

    if(inputs.model=="" || inputs.model == undefined){
      setErrorMessage({
        ...errorMessage,
        category:"Please enter a model"
      })
      return
    }
    
    let token = getAuth.token;
    let data = {
      manufacturer: inputs.manufacturer,
      model: inputs.model,
      category: inputs.category
    };

    setLoading(true)
    fetch(base_url + add_model, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }).then((response)=> response.json())
    .then((jsonResp) => {
      console.log(jsonResp)
      if(jsonResp.statusCode == 0){
        Swal.fire({
          title: "Success",
          text: "",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        }).then(()=>{
          setInputs({
            manufacturer:"",
            model:"",
            category:""
          })
        })
        //successful
      }
      setLoading(false)
    })

  }
  return (
    <div className="max-w-2xl ml-4">
      <div>
        <label
            htmlFor="category"
            className="mt-3 block text-gray-900 text-md"
          >
            Category
          </label>
          <select
            value={inputs.category || ""}
            name="category"
            onChange={handleChange}
            className="w-full rounded border-2 hover:border-green-800 py-1 px-2 text-lg"
          >
            <option value="">Select Category</option>
            <option value="motorvehicle">Motor Vehicle</option>
            <option value="motorcycle">Motorcycle/Tricycle</option>
          </select>
        </div>

      <div>
        <label
          htmlFor="manufacturer"
          className="mt-3 block text-gray-900 text-md"
        >
          Manufacturer <span className={`text-sm cursor-pointer ${newManufacturer ? "hidden" : ""}`} onClick={(e) => setNewManufacturer(true)}>(New Manufacturer)</span>
        </label>
        {newManufacturer ? 
          <>
            <input value={inputs.manufacturer || ""} type="text" required={true} name="manufacturer" onChange={handleChange} className="w-full rounded border-2 hover:border-green-800 py-1 px-2 text-lg" />
          </>
          :
          <>
            <select
            value={inputs.manufacturer || ""}
            required={true}
            name="manufacturer"
            onChange={handleChange}
            className="w-full rounded border-2 hover:border-green-800 py-1 px-2 text-lg"
          >
            <option value="">Select Manufacturer</option>
            {manufacturers?.map((val, index) => {
              return (
                <option key={index} value={val}>
                  {val}
                </option>
              );
            })}
          </select>
          </>}
          {errorMessage?.manufacturer && <>{errorMessage?.manufacturer}</>}        
      </div>
      <div>
      <label
          htmlFor="manufacturer"
          className="mt-3 block text-gray-900 text-md"
        >
          Model 
        </label>
        <input type="text" value={inputs.model || ""} required={true} name="model" onChange={handleChange} className="w-full rounded border-2 hover:border-green-800 py-1 px-2 text-lg" />
      </div>

      <div>
        <button onClick={handleSubmit} className="bg-blue-400 mt-3 rounded px-2 py-1 hover:text-gray-50">Add {loading && <Loader />}</button>
      </div>
      
    </div>
  );
}

export default AddModel;

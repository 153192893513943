import React, { useState, useMemo, useEffect } from "react";
import Sidebar from "components/admin/SideBars/Sidebar";
import CreateEnforcer from "components/admin/Enforcers/CreateEnforcer";
import Header from "components/admin/Headers/Header";
import Table from "components/Table";
import { useAdminAuth } from "store/AdminAuth";
import { base_url, get_enforcers } from "constants/endpoints";

export default function PatrolOfficer() {
  const [menu, setMenu] = useState(0);
  const [enforcers, setEnforcers] = useState([]);
  const data = useMemo(() => enforcers, [enforcers]);
  const getAuth = useAdminAuth((state) => state.auth);
  const columns = useMemo(
    () => [
      {
        Header: "User name",
        accessor: "username",
        Cell: ({ cell }) => (
          <span>
            {cell.row.values.username.toUpperCase()}
          </span>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Camera",
        accessor: "camera_name",
      },
      {
        Header: "CMR Information Center",
        accessor: "location_name",
      },
      {
        Header: "State",
        accessor: "location_state",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => (
          <span
            className={`${
              cell.row.values.status == "1"
                ? "bg-green-400 rounded px-3 py-1 text-black-400"
                : "bg-red-500 rounded px-3 py-1 text-black-400"
            } rounded p-1`}
          >
            {cell.row.values.status ? "Active":"Not Active"}
          </span>
        ),
      }
    ],
    []
  );

  useEffect(() => {
    // console.log(base_url + get_cameras);
    let token = getAuth.token;
    try {
      fetch(base_url + get_enforcers, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log("Response: "+jsonResp.data);
          if(jsonResp.data == null){
            jsonResp.data =[]
          }
          setEnforcers(jsonResp.data)
        });
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Enforcement User Manager" />
        <div className="flex mx-4 py-1 w-1/4">
          <div
            onClick={() => {
              setMenu(0);
            }}
            className={`flex-auto hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100 ${
              menu === 0 && "text-yellow-300"
            }`}
          >
            List Enforcement Officer
          </div>
          <div
            onClick={() => {
              setMenu(1);
            }}
            className={`flex-auto hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100 ${
              menu === 1 && "text-yellow-300"
            }`}
          >
            Add Enforcement Officer
          </div>
        </div>
      </div>
      <div className="relative md:ml-64 bg-lightBlue-700">
        <div className="bg-white pt-1">
          {menu == 0 ? (
            <div className="px-8 mt-3 w-full">
              <Table columns={columns} data={data} />
            </div>
          ) : (
            <div className="mt-3">
              <CreateEnforcer />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

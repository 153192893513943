export const colorResolution = (color) =>{
    let colorResolved="";
    switch(color){
      case "Brown":
        colorResolved="bg-red-900";
        break;
      case "Amber":
        colorResolved="bg-yellow-600";
        break;
      case "Blue":
        colorResolved="bg-blue-700";
        break;
      case "Gray":
        colorResolved="bg-gray-700";
        break;
      case "Green":
        colorResolved="bg-green-700";
        break;
      case "Hazel":
        colorResolved="bg-yellow-500";
        break;
      case "Red":
        colorResolved="bg-red-500"
        break;
      default:
        colorResolved="bg-black"
    }
    return colorResolved;
  }
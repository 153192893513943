import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import {
  base_url,
  get_locations,
  get_cameras,
  create_enforcer,
} from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";

export default function CreateEnforcer() {
  const [showPassword, setShowPassword] = useState(true);
  const [role, setRole]= useState("")
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [cameras, setCameras] = useState([]);
  const [locations, setLocations] = useState([]);
  const getAuth = useAdminAuth((state) => state.auth);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = (data) => {
    data.camera = parseInt(data.camera);
    data.location = parseInt(data.location);
    data.password = data.phone;
    let token = getAuth.token;
    console.log(data)
    fetch(base_url + create_enforcer, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((respoJson) => {
        if (respoJson.statusCode === 0) {
          Swal.fire({
            title: "Success!",
            text: "Enforcement Officer Profile Created",
            icon: "success",
          }).then(() => {
            reset();
          });
        }
      });
  };

  useEffect(() => {
    let token = getAuth.token;
    fetch(base_url + get_cameras, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        let dataArr = [];
        for (const [key, value] of Object.entries(jsonResp.data)) {
          dataArr[value.id] = value.camId;
        }
        setCameras(dataArr);
      });

    fetch(base_url + get_locations, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        let dataArr = [];
        for (const [key, value] of Object.entries(jsonResp.data)) {
          dataArr[value.id] = value.name;
        }
        setLocations(dataArr);
      });
  }, []);

  return (
    <div className="px-8">
      <form>
        <div className="w-full">
          <p className="text-xl text-gray-700 font-semi-bold mb-3">
            Add Enforcement Officer
          </p>
          <div className="flex">
            <div className="flex-1 mr-2">
              <label htmlFor="username" className="block text-gray-900 text-md">
                AP/Force Number
              </label>
              <input
                type="text"
                {...register("username", { required: true })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.username?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Username is required
                </p>
              )}
            </div>
            <div className="flex-1 mr-2">
              <label htmlFor="first_name" className="block text-gray-900 text-md">
                First Name
              </label>
              <input
                type="text"
                {...register("first_name", { required: true })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.first_name?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  First Name is required
                </p>
              )}
            </div>
            <div className="flex-1 mr-2">
            <label htmlFor="middle_name" className="block text-gray-900 text-md">
                Middle Name
              </label>
              <input
                type="text"
                {...register("middle_name", { required: false })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.middle_name?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Middle Name is required
                </p>
              )}
            </div>
            <div className="flex-1">
            <label htmlFor="last_name" className="block text-gray-900 text-md">
                Last Name
              </label>
              <input
                type="text"
                {...register("last_name", { required: true })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.last_name?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Last Name is required
                </p>
              )}
            </div>
          </div>
          
          <div className="flex mt-5">
            <div className="flex-1 mr-2">
              <label htmlFor="username" className="block text-gray-900 text-md">
                Rank
              </label>
              <input
                type="text"
                {...register("rank", { required: true })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.rank?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Rank is required
                </p>
              )}
            </div>
            <div className="flex-1 mr-2">
              <label htmlFor="first_name" className="block text-gray-900 text-md">
                Department/Zone/Command
              </label>
              <input
                type="text"
                {...register("department", { required: true })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.department?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Department/Zone/Command is required
                </p>
              )}
            </div>
            <div className="flex-1 mr-2">
            <label htmlFor="middle_name" className="block text-gray-900 text-md">
                Section/Area Command/Division
              </label>
              <input
                type="text"
                {...register("section", { required: false })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.section?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Section is required
                </p>
              )}
            </div>
            <div className="flex-1">
            <label htmlFor="last_name" className="block text-gray-900 text-md">
                Duty Post
              </label>
              <input
                type="text"
                {...register("duty_post", { required: true })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.duty_post?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Duty Post is required
                </p>
              )}
            </div>
          </div>

          <div className="flex mt-5">
            {/* <div className="flex-1 mr-2">
              <label htmlFor="username" className="block text-gray-900 text-md">
                Rank
              </label>
              <input
                type="text"
                {...register("rank", { required: true })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.rank?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Rank is required
                </p>
              )}
            </div> */}
            <div className="flex-1 mr-2">
              <label htmlFor="first_name" className="block text-gray-900 text-md">
                Date Posted
              </label>
              <input
                type="date"
                {...register("date_posted", { required: true })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.date_posted?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Date Posted is required
                </p>
              )}
            </div>
            <div className="flex-1 mr-2">
              <label htmlFor="phone" className="block text-gray-900 text-md">
                Phone
              </label>
              <input
                type="text"
                {...register("phone", { required: true })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.phone ? "true" : "false"}
              />
              {errors.phone?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Phone is required
                </p>
              )}
            </div>
            <div className="flex-1">
              <label htmlFor="name" className="block text-gray-900 text-md">
                Email
              </label>
              <input
                type="email"
                {...register("email", { required: false })}
                className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                aria-invalid={errors.email ? "true" : "false"}
              />
              {errors.email?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Email is required
                </p>
              )}
            </div>
          </div>
          
          <div className="flex">
            <div className="flex-1">
              <label htmlFor="type" className="mt-3 block text-gray-900 text-md">
                CMR Information Center
              </label>
              <select
                name="location"
                {...register("location", { required: true })}
                aria-invalid={errors.location ? "true" : "false"}
                className="w-full rounded border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
              >
                <option value="">Select a Location</option>
                {locations.map((val, index) => {
                  return (
                    <option key={index} value={index}>
                      {val}
                    </option>
                  );
                })}
              </select>
              {errors.location?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  CIC is required
                </p>
              )}
            </div>
            <div className="flex-1 ml-3">
            <label htmlFor="type" className="mt-3 block text-gray-900 text-md">
                Role
              </label>
              <select
                name="role"
                {...register("role", { required: true })}
                aria-invalid={errors.role ? "true" : "false"}
                className="w-full rounded border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                onChange={(e)=>{ setRole(e.target.value)}}
              >
                <option value="">Select a Role</option>
                <option value="Enforcement Team Leader">Enforcement Team Leader</option>
                <option value="Enforcement Team Member">Enforcement Team Member</option>
                <option value="Vehicle Status Checker">Vehicle Status Checker</option>
              </select>
              {errors.role?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Role is required
                </p>
              )}
            </div>
          </div>
          {(role === "Enforcement Team Leader" || role==="Enforcement Team Member") && <>
          <label htmlFor="type" className="mt-3 block text-gray-900 text-md">
            Select Camera
          </label>
          <select
            name="camera"
            {...register("camera", { required: false })}
            aria-invalid={errors.camera ? "true" : "false"}
            className="w-full rounded border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
          >
            <option value="">Select a Camera</option>
            {cameras.map((val, index) => {
              return (
                <option key={index} value={index}>
                  {val}
                </option>
              );
            })}
          </select>
          {errors.camera?.type === "required" && (
            <p role="alert" className="text-sm text-red-500">
              Camera is required
            </p>
          )}
          </> }
          
          
          {/* <label
            htmlFor="password"
            className="block mt-3 text-gray-900 text-md"
          >
            Password
          </label>
          <input
            placeholder="password here"
            type={`${showPassword === false ? "text" : "password"}`}
            {...register("password", { required: true })}
            aria-invalid={errors.password ? "true" : "false"}
            className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
          />
          <span
            onClick={() => {
              togglePassword();
            }}
            className="cursor-pointer hover:text-gray-500"
          >
            {showPassword === false ? "Hide Password" : "Show Password"}
          </span>
          {errors.password?.type === "required" && (
            <p role="alert" className="text-sm text-red-500">
              Password is required
            </p>
          )} */}
          <div
            className={`bg-lightBlue-700 rounded-lg w-32 cursor-pointer h-12 pt-3 text-center mt-3 text-gray-100 px-3 py-1`}
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </div>
        </div>
      </form>
    </div>
  );
}

export const useUploadValidator = (image) => {
    const defaultFormat = ["image/jpeg", "image/png"];
  
    const formatBytes = (bytes, decimals = 2) => {
      if (!+bytes) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      // const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}`;
    };
  
    const validateImage = (
      image,
      allowed_format_arr = defaultFormat,
      max_allowed_size_in_kb
    ) => {
      const file_type = image.type;
      const file_size_in_kb = formatBytes(image.size, 0);
      const type_validated = allowed_format_arr.includes(file_type, 0);
      let size_validated = false;
  
      if (file_size_in_kb <= max_allowed_size_in_kb) {
        size_validated = true;
      }
  
      return {
        valid: type_validated && size_validated,
        fileSize: file_size_in_kb,
      };
    };
  
  // the validateImage returns an object that has the validity and the size of the file uplaoded
    return validateImage;
  };
  
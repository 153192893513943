export const vatPercentage = 0.075;

export const tableArrayData = [
  { dataField: "Request/Report No", value: "requestNumber" },
  { dataField: "Last Name", value: "lastName" },
  { dataField: "First Name", value: "firstName" },
  { dataField: "Middle Name", value: "middleName" },
  { dataField: "Gender", value: "gender" },
  { dataField: "Phone No", value: "phone" },
  { dataField: "Email Address", value: "email" },
  { dataField: "State of Residence", value: "residentialState" },
  { dataField: "LGA of Residence", value: "residentialLg" },
  { dataField: "Address", value: "residentialAddress" },
  { dataField: "Licence Plate No", value: "licensePlate" },
  { dataField: "VIN/Chasis No", value: "chassisNumber" },
  { dataField: "Engine No", value: "engineNumber" },
  { dataField: "Make", value: "manufacturer" },
  { dataField: "Model", value: "model" },
  { dataField: "Type", value: "vehicleType" },
  { dataField: "Colour", value: "color" },
  { dataField: "Licence State", value: "mla" },
  { dataField: "Request State", value: "requestState" },
  // { dataField: "Request/Theft/Incident LGA", value: "" },
  // { dataField: "Request/Stolen/Incident Date", value: "" },
  { dataField: "Request Date", value: "" },
  { dataField: "Request Status", value: "vehicleStatus" },

  // { dataField: "Place of Theft/Incident", value: "" },
  // { dataField: "Police Station/Division", value: "" },
];




import React, { useState, useEffect, useRef } from "react";
import Header from "components/admin/Headers/Header";
import AddAgentForm from "components/admin/Forms/AddAgentForm";
import Sidebar from "components/admin/SideBars/Sidebar";
import { addAgent, base_url, ninData, get_admins, superagents } from "constants/endpoints";
import Swal from "sweetalert2";
import states from "../../../constants/states";
import { useAdminAuth } from "store/AdminAuth";
import { set } from "react-hook-form";
import Validate from "./Validation";
import { HasAccess } from "@permify/react-role";
import { sha256 } from "../../../utils/hashings"
import Loader from "components/Loader";

export default function AgentRegistration() {
  const [sameEmail, setSameEmail] = useState(false);
  const [samePhone, setSamePhone] = useState(false);
  const [sameAddress, setSameAddress] = useState(false);
  const { token, state } = useAdminAuth((state) => state.auth);
  const [lgs, setLocalgs] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [focus, setFocus] = useState(false)

  const [isToggled, setToggled] = useState(true);

  const handleToggle = () => {
    setToggled(!isToggled);
  };
  const inputRef = useRef();

  const [inputs, setInputs] = useState({
    lastName: "",
    firstName: "",
    middleName: "",
    currentPhoneNumber: "",
    registeredEmailAddress: "",
    currentEmailAddress: "",
    registeredPhoneNumber: "",
    registeredState: "",
    registeredLg: "",
    registeredAddress: "",
    currentState: "",
    currentAddress: "",
    gender: "",
    nin: "",
    username: "",
    superAgent: "",
    accountType: "3",
  });

  // useEffect(() => {
  //   if (focus==true){
  //     window.location.reload()
  //   }
  // }, [focus])
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (inputs.currentPhoneNumber == "") {
      Swal.fire({
        title: "Error!",
        text: "Enter phone number",
        icon: "error",
      }).then(() => {
        setLoading(false);
      });
      return;
    }

    if (
      inputs.currentPhoneNumber.length < 11 ||
      inputs.currentPhoneNumber.length > 12
    ) {
      Swal.fire({
        title: "Error!",
        text: "Phone number is not valid",
        icon: "error",
      }).then(() => {
        setLoading(false);
      });
      return;
    }
    if (
      inputs.currentEmailAddress == "" ||
      inputs.currentEmailAddress == null
    ) {
      Swal.fire({
        title: "Error!",
        text: "Email is not valid",
        icon: "error",
      }).then(() => {
        setLoading(false);
      });
      return;
    }

    const payload = {
      first_name: inputs.firstName,
      middle_name: inputs.middleName,
      last_name: inputs.lastName,
      phone: inputs.currentPhoneNumber,
      email: inputs.currentEmailAddress,
      current_state: inputs.currentState,
      current_lg: inputs.currentLg,
      current_address: inputs.currentAddress,
      pulled_email: inputs.registeredEmailAddress,
      pulled_phone: inputs.registeredPhoneNumber,
      pulled_state: inputs.registeredState,
      pulled_lg: inputs.registeredLg,
      pulled_address: inputs.registeredAddress,
      password: inputs.currentPhoneNumber,
      confirm_password: inputs.currentPhoneNumber,
      status: "Verified",
      gender: inputs.gender,
      operation_state: state,
      ...(isToggled === false
        ? { account_type: inputs.accountType, super_agent: inputs.superAgent }
        : { id_type: "nin", id_number: inputs.nin }),
    };
    try {
      const response = await fetch(base_url + addAgent, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.message === "already exists") {
        Swal.fire({
          title: "Error!",
          text: data.message,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Success!",
          text: "CMRIS Assistant request submitted for approval",
          icon: "success",
        }).then(() => {
          setInputs({});
          setLoading(false);
        });
      }
      setLoading(false);
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error submitting form!",
        icon: "error",
      }).then(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    try {
      fetch(base_url + superagents, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          setAdmins(jsonResp.data);
        });
    } catch (e) {

    }
  }, []);

  const fetchLga = async (e) => {
    const url = `${base_url}/api/lgs/${e.target.value}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = await response.json();
      //   dataSetter(data);
      setLocalgs(data);
      //   loadSetter(false);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Error fetching local government",
        icon: "error",
      });
      //   loadSetter(false);
    }
  };

  useEffect(() => {
    if (sameEmail == true) {
      setInputs({
        ...inputs,
        currentEmailAddress: inputs.registeredEmailAddress,
      });
    } else {
      setInputs({
        ...inputs,
        currentEmailAddress: "",
      });
    }
  }, [sameEmail]);

  useEffect(() => {
    if (samePhone == true) {
      setInputs({
        ...inputs,
        currentPhoneNumber: inputs.registeredPhoneNumber,
      });
    } else {
      setInputs({
        ...inputs,
        currentPhoneNumber: "",
      });
    }
  }, [samePhone]);

  useEffect(() => {
    if (sameAddress == "yes") {
      setInputs({
        ...inputs,
        currentState: inputs.registeredState,
        currentLg: inputs.registeredLg,
        currentAddress: inputs.registeredAddress,
      });
    } else {
      setInputs({
        ...inputs,
        currentState: "",
        currentLg: "",
        currentAddress: "",
      });
    }
  }, [sameAddress]);

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  // const handleOnBlur = (e) => {
  //   inputRef.current.focus();

  // }

  const handleClose = () => {
    inputRef.current.focus();
  };
  const fetchAgentFromNin =  () => {
    setLoading(true)
    let base_token="1dfc021e4a1500f5cf893cea9e2f6aaff22a67f1a653870d6dc964c3c853c74f86731be4b78d42a805ff7942beddf413fdcece5b0124596a51f452e5fb33b190"
    // inputRef.current.focus();
    if (inputs.nin.length < 10 || inputs.nin.length > 12) {
      Swal.fire({
        title: "Error!",
        text: "NIN is not valid",
        icon: "error",
      });
      return;
    }
    const payload = {
      idType: "nin",
      id: inputs.nin,
    };
    const url = `${base_url}${ninData}`;
    let string = inputs.nin+"nin"+"agent"+"meshmellow"
    sha256(string).then((hash)=>{
      try {
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${base_token}`,
            "X-Api-Key": hash,
          },
          body: JSON.stringify(payload),
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.statusCode != undefined && data.statusCode == 6) {
            Swal.fire({
              title: "Error!",
              text: "NIN already onboarded or awaiting approval",
              icon: "error",
            });
            setLoading(false)
          return;
        } else if (data.success != undefined && data.success==true) {
          // console.log(data);
          setInputs({
            ...inputs,
            firstName: data.message.firstName,
            middleName: data.message.middleName,
            lastName: data.message.surname,
            registeredPhoneNumber: data.message.telephoneno,
            // registeredEmailAddress: data.email,
            registeredState: data.message.residence_state,
            registeredLg: data.message.residence_lga,
            registeredAddress: data.message.residence_Address,
            // gender: data.gender == "f" ? "Female" : "Male",
          });
          setLoading(false)
        } else {
          // Swal.fire('NIN details not found in NIMC’s Database', '', 'error', { onClose: handleClose })
          Swal.fire({
            title: "Error!",
            text: "NIN details not found in NIMC’s Database",
            icon: "error",
            // onClose: ()=>{handleClose()},
          }).then(() => {
            setLoading(false)
            handleClose();
          });
        }
        })
        // const { data, statusCode } = await response.json();
        // console.log(statusCode)
        
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    });
    
  };
  return (
    <div>
      <form className="mt-2">

        <HasAccess
        // roles={["access", "ccc-analyser"]}
        permissions="onboard-assist-full-access"
        renderAuthFailed={<p className="hidden"></p>}
      >
        <div className="my-5 flex flex-col">
          <span>Strict Mode</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              checked={isToggled}
              onChange={handleToggle}
            />
            <span className="slider"></span>
          </label>
        </div>
      </HasAccess>

        {isToggled === true && (
          <>
            <div>
              <label className="whitespace-nowrap mr-2">
                National Identity Number
              </label>
            </div>
            <div className="mb-4 flex w-full">
              <div classsName="w-4/5">
              <input
                ref={inputRef}
                type="text"
                name="nin"
                // autoFocus
                onKeyDown={(e) => {
                  if (e.keyCode === 9) e.preventDefault();
                }}
                className="w-full h-auto p-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                onChange={handleChange}
                // onBlur={fetchAgentFromNin}
              />
              </div>
              <div>
                <button
                  type="button"
                  disabled={true}
                  onClick={fetchAgentFromNin}
                  className="ml-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Search {loading && <Loader />}
                </button>
              </div>
            </div>
          </>
        )}
        <div className="flex">
          <div className="flex-1">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              // disabled={isToggled === false ? false : true}
              // readOnly={isToggled === false ? false : true}
              value={inputs.firstName || ""}
              onChange={handleChange}
              className={`w-full h-auto p-2 text-base font-normal text-gray-700 ${
                isToggled ? "bg-gray-100" : "bg-white"
              } bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
            />
          </div>
          <div className="flex-1 mr-3 ml-3">
            <label>Middle Name</label>
            <input
              type="text"
              name="middleName"
              // disabled={isToggled === false ? false : true}
              // readOnly={isToggled === false ? false : true}
              value={inputs.middleName || ""}
              onChange={handleChange}
              className={`w-full h-auto p-2 text-base font-normal text-gray-700 ${
                isToggled ? "bg-gray-100" : "bg-white"
              } bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
            />
          </div>
          <div className="flex-1">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              // disabled={isToggled === false ? false : true}
              // readOnly={isToggled === false ? false : true}
              value={inputs.lastName || ""}
              onChange={handleChange}
              className={`w-full h-auto p-2 text-base font-normal text-gray-700 ${
                isToggled ? "bg-gray-100" : "bg-white"
              } bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
            />
          </div>
        </div>

        {isToggled === false && (
          <div className="flex mt-4">
            <div className="flex-1">
              <label>Account Type</label>
              <select
                className="w-full h-auto p-2 text-base font-normal text-gray-700bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                value={inputs.accountType || ""}
                name="accountType"
                onChange={handleChange}
              >
                <option>Select Account Type</option>
                <option value={"1"}>Tier 1</option>
                <option value={"2"}>Tier 2</option>
                <option value={"3"}>Tier 3</option>
              </select>
            </div>
            <div className="flex-1 ml-3">
              <label>Super Assist</label>
              <select
                className="w-full h-auto p-2 text-base font-normal text-gray-700bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                value={inputs.superAgent || ""}
                name="superAgent"
                onChange={handleChange}
              >
                <option value="">Select Assist</option>
                {inputs.accountType=="2" ? (
                  admins.map((val, index) => (
                    <option key={index} value={val.id}>
                      {val.first_name}
                    </option>
                  ))
                ) : null}
              </select>
            </div>
          </div>
        )}
        <div className="flex mt-4">
          <div className="flex-1">
            <label>Registered Phone Number</label>
            <input
              type="text"
              name="registeredPhoneNumber"
              value={inputs.registeredPhoneNumber || ""}
              onChange={handleChange}
              // disabled={isToggled === false ? false : true}
              // readOnly={isToggled === false ? false : true}
              className={`w-full h-auto p-2 text-base font-normal text-gray-700 ${
                isToggled ? "bg-gray-100" : "bg-white"
              } bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
            />
          </div>
          <div className="flex-1 ml-3">
            <label>Registered Email Address</label>
            <input
              type="text"
              name="registeredEmailAddress"
              // disabled={isToggled === false ? false : true}
              // readOnly={isToggled === false ? false : true}
              value={inputs.registeredEmailAddress || ""}
              onChange={handleChange}
              className={`w-full h-auto p-2 text-base font-normal text-gray-700 ${
                isToggled ? "bg-gray-100" : "bg-white"
              } bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
            />
          </div>
        </div>

        <div className="flex mt-4">
          <div className="flex-1">
            <label>Current Phone Number</label>
            <input
              type="text"
              name="currentPhoneNumber"
              value={inputs.currentPhoneNumber || ""}
              onChange={handleChange}
              readOnly={samePhone}
              className="w-full h-auto p-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            />
            {inputs.registeredPhoneNumber != "" && (
              <span>
                <input
                  className="mr-1"
                  type="checkbox"
                  id="samePhone"
                  onChange={() => {
                    setSamePhone(!samePhone);
                  }}
                  checked={samePhone}
                  name="samePhone"
                  value="true"
                />
                Same as above
              </span>
            )}
          </div>
          <div className="flex-1 ml-3">
            <label>Current Email Address</label>
            <input
              type="text"
              name="currentEmailAddress"
              value={inputs.currentEmailAddress || ""}
              onChange={handleChange}
              className="w-full h-auto p-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            />
            {inputs.registeredEmailAddress != undefined && (
              <span>
                <input
                  className="mr-1"
                  type="checkbox"
                  id="sameEmail"
                  onChange={() => {
                    setSameEmail(!sameEmail);
                  }}
                  checked={sameEmail}
                  name="sameEmail"
                  value="true"
                />
                Same as above
              </span>
            )}
          </div>
        </div>

        <div className="flex mt-4">
          <div className="flex-1">
            <label>Registered State</label>
            {isToggled === true ? (
              <input
                type="text"
                name="registeredState"
                readOnly
                value={inputs.registeredState || ""}
                className="w-full h-auto p-2 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              />
            ) : (
              <select
                type="text"
                name="registeredState"
                value={inputs.registeredState || ""}
                onChange={(e) => {
                  handleChange(e);
                  fetchLga(e);
                }}
                className="w-full h-auto p-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              >
                <option>Select State</option>
                {states.map((val, index) => {
                  return (
                    <option key={index} value={val}>
                      {val.name ? val.name : val}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
          <div className="flex-1 ml-3">
            <label>Registered Local Government</label>
            {isToggled === true ? (
              <input
                type="text"
                name="registeredLg"
                readOnly
                value={inputs.registeredLg || ""}
                className="w-full h-auto p-2 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              />
            ) : (
              <select
                type="text"
                name="registeredLg"
                value={inputs.registeredLg || ""}
                onChange={handleChange}
                className="w-full h-auto p-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              >
                <option>Select Local Government</option>
                {lgs.map((val, index) => {
                  return (
                    <option key={index} value={val}>
                      {val.name ? val.name : val}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
        </div>

        <div className="mt-4">
          <label>Registered Address</label>
          <input
            type="text"
            // disabled={isToggled === false ? false : true}
            // readOnly={isToggled === false ? false : true}
            name="registeredAddress"
            value={inputs.registeredAddress || ""}
            onChange={handleChange}
            className={`w-full h-auto p-2 text-base font-normal text-gray-700 ${
              isToggled ? "bg-gray-100" : "bg-white"
            } bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
          />
        </div>

        <div className="mt-3">
          <label htmlFor="token" className="subtitle-2 text-secondary ">
            Is the current address the same as the registered address?
            <div
              onChange={(e) => {
                setSameAddress(e.target.value);
              }}
              className="text-left"
            >
              <label>
                <input
                  type="radio"
                  className="mr-2"
                  name="SameAddress"
                  value="yes"
                  required={true}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  className="ml-8 mr-2"
                  name="SameAddress"
                  value="no"
                  required={true}
                />
                No
              </label>
            </div>
          </label>
        </div>
        {sameAddress === "no" && (
          <>
            <div className="flex mt-4">
              <div className="flex-1">
                <label>Current State</label>
                <select
                  className="w-full h-auto p-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  name="currentState"
                  onChange={(e) => {
                    handleChange(e);
                    fetchLga(e);
                  }}
                >
                  <option>Select State</option>
                  {states.map((val, index) => {
                    return (
                      <option key={index} value={val}>
                        {val.name ? val.name : val}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="flex-1 ml-3">
                <label>Current Local Government</label>
                <select
                  className="w-full h-auto p-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  name="currentLg"
                  onChange={handleChange}
                >
                  <option>Select Local Government</option>
                  {lgs.map((val, index) => {
                    return (
                      <option key={index} value={val}>
                        {val.name ? val.name : val}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div>
              <div className="mt-4">
                <label>Current Address</label>
                <input
                  type="text"
                  name="currentAddress"
                  className="w-full h-auto p-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />
              </div>
            </div>
          </>
        )}
        <button
          className="mt-4 bg-blue-500 rounded text-gray-100 w-full py-1 hover:bg-blue-900 hover:text-white"
          onClick={handleSubmit}
          disabled={loading}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

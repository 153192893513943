import { base_url } from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";

export const useFetchFunc = () => {
  const { token } = useAdminAuth((state) => state.auth);
  const fetchFunc = async (url, setter, loadingSetter) => {
    loadingSetter(true);
    try {
      const response = await fetch(base_url + url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const responseData = await response.json();
      const { data, statusCode } = responseData;
      if (statusCode === 0) {
        data && setter(data);
      }
      loadingSetter(false);
    } catch (e) {
      loadingSetter(false);
    }
  };

  return fetchFunc;
};

import React, { useEffect, useState } from "react";
import { base_url, stolen_details, approval_layer2 } from "constants/endpoints";
import { useParams } from "react-router-dom";
import Sidebar from "components/admin/SideBars/Sidebar";
import Swal from "sweetalert2";
import { HasAccess } from "@permify/react-role";

export default function PostedReportDetails() {
  const { id } = useParams();
  const [reportDetails, setReportDetails] = useState([]);

  const Approve = (status) => {
    let token = localStorage.getItem("cmr_admin_token");
    let data = {
      service: "stolen",
      modelId: id,
      status: status,
    };
    try {
      fetch(base_url + approval_layer2, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(token),
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            Swal.fire({
              title: "Error!",
              text: "Error approving resouce!",
              icon: "error",
            });
          }
        })
        .then((jsonResp) => {
          // window.location.reload();
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error approving resouce!",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    console.log(base_url + stolen_details);
    let token = localStorage.getItem("cmr_admin_token");
    try {
      fetch(base_url + stolen_details + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(token),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            Swal.fire({
              title: "Error!",
              text: "Error fetching data from the server!",
              icon: "error",
            });
          }
        })
        .then((jsonResp) => {
          setReportDetails(jsonResp.data);
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error fetching data from the server!",
        icon: "error",
      });
    }
  }, []);
  return (
    <div>
      <Sidebar />
      <div className="relative md:ml-64 bg-lightBlue-700">
        <ul className="ml-8 flex flex-wrap text-lg pb-4 font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
          {/* <li className="mr-2 cursor-pointer"></li>
          <li className="mr-2"></li> */}
        </ul>
        <div className="bg-white mt-6 pt-8">
          {/* <div className="bg-green-800 px-8 py-10"> */}
          {/* Stolen Vehicles Report Approval */}
          {/* </div> */}
          <div className="px-8 mt-1 w-full text-lg leading-9">
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-50 py-2 px-4 bg-lightBlue-900">
              Owner Details
            </div>
            <div className="columns-3">
              <div>
                First Name:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.firstName}
                </span>
              </div>
              <div>
                Last Name
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.lastName}
                </span>
              </div>
              <div>
                Middle Name
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.middleName}
                </span>
              </div>
            </div>
            <div className="columns-3">
              <div>
                Profile Type:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.type}
                </span>
              </div>
              <div>
                Gender:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.gender}
                </span>
              </div>
              <div>
                Email:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.email}
                </span>
              </div>
            </div>

            <div className="columns-3">
              <div>
                Phone:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.phone}
                </span>
              </div>
              <div>
                State:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.residentialState}
                </span>
              </div>
              <div>
                Local Government:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.residentialLg}
                </span>
              </div>
            </div>
            <div className="columns-3">
              <div>
                Address:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.residentialAddress}
                </span>
              </div>
              <div>
                Next of Kin:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.nokPhone}
                </span>
              </div>
            </div>
            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-50 py-2 px-4 bg-lightBlue-900">
              Vehicle Details
            </div>
            <div className="columns-3">
              <div>
                Licence Plate:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.licensePlate}
                </span>
              </div>
              <div>
                Chassis Number
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.chassisNumber}
                </span>
              </div>
              <div>
                Engine Number
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.engineNumber}
                </span>
              </div>
            </div>
            <div className="columns-3">
              <div>
                Manufacturer:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.manufacturer}
                </span>
              </div>
              <div>
                Model
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.model}
                </span>
              </div>
              <div>
                Year
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.year}
                </span>
              </div>
            </div>

            <div className="columns-3">
              <div>
                Vehicle Type:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.vehicleType}
                </span>
              </div>
              <div>
                Color:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.color}
                </span>
              </div>
              <div>
                Vehicle Usage:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.vehicleUsage}
                </span>
              </div>
            </div>

            <div className="columns-3">
              <div>
                Vehicle Status:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.vehicleStatus}
                </span>
              </div>
            </div>

            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-50 py-2 px-4 bg-lightBlue-900">
              Report Details
            </div>
            <div className="columns-3">
              <div>
              Request Date:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.entryDate}
                </span>
              </div>
              <div>
                State:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.state}
                </span>
              </div>
              <div>
                Local Government:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.lg}
                </span>
              </div>
            </div>

            <div className="columns-3">
              <div>
                Location:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.location}
                </span>
              </div>
              <div>
                Date and Time of Stolen:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.time}
                </span>
              </div>
              <div>
                Extract Number:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.extractNumber}
                </span>
              </div>
            </div>

            <div className="columns-3">
              <div>
                Report Status:
                <span className="ml-4 text-blue-800 font-semibold">
                  {reportDetails?.status}
                </span>
              </div>
            </div>
            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-50 py-2 px-4 bg-lightBlue-900">
              Documents
            </div>
            <div className="columns-1">
              <div>
                <a href={reportDetails?.proof} target="__blank">
                  <img src={reportDetails?.proof} alt="Proof of Ownership" />
                </a>
              </div>
              <div>
                <a href={reportDetails?.extract} target="__blank">
                  <img src={reportDetails?.extract} alt="Police Extract" />
                </a>
              </div>
            </div>
            <hr />
            <button
              onClick={() => {
                Approve("Approved");
              }}
              className="text-gray-100 bg-lightBlue-700 rounded-lg px-4 mr-4 mt-4"
            >
              Approve
            </button>
            <button
              onClick={() => {
                Approve("Declined");
              }}
              className="text-gray-100 bg-red-500 rounded-lg px-4 mr-4 mt-4"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import {
  base_url,
  upload_passport,
  get_idp_details,
  idp_recommendation
} from "constants/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "components/admin/SideBars/Sidebar";
import Swal from "sweetalert2";
import { useAdminAuth } from "store/AdminAuth";
import Header from "components/admin/Headers/Header";
import { formatDateNew } from "utils/date_format";
import Loader from "components/Loader";
import { AiOutlineUser, AiFillEdit } from "react-icons/ai";
import { useUploadValidator } from "utils/Hooks/uploadValidator";
import { upload_signature } from "constants/endpoints";

export default function IDPUpdateDetails() {
  const { id } = useParams();
  const getAuth = useAdminAuth((state) => state.auth);
  const [requestDetails, setRequestDetails] = useState({});
  const [syncing, setSyncing] = useState(false);
  const validImage = useUploadValidator();
  const [document_title, setDocumentTitle] = useState("Signature");
  const [isVerifying, setIsVerifying] = useState(false);
  const [validated, setValidation] = useState(false);
  const [imageDetails, setImageDetails] = useState({
    size: "",
    validated: false,
    message: "",
  });
  const [document, setDocument] = useState("");
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [camModal, setcamModal] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [camPreview, setcamPreview] = useState("");
  const [fileData, setfileData] = useState(null);
  const [uploadLoading, setuploadLoading] = useState(false);
  const [captured, setCaptured] = useState(false)
  const [comment, setComment] = useState("")
  const navigate = useNavigate();
  const uploadRef = useRef();

  const uploadSignature =(e) =>{
    e.preventDefault()
    setuploadLoading(true)
    let token = getAuth.token;
    let url = base_url + upload_signature

    const formData = new FormData();
    formData.append("id", id);
    formData.append("document_title", document_title);
    // formData.append("cow_document", document);
    const signature_document =
      window.document.getElementById("signature");
    formData.append(
      "signature",
      signature_document?.files[0],
      document
    );
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    })
      .then((resp) => {
        console.log("File upload response: ", resp);
        return resp.json();
      })
      .then((respJson) => {
        Swal.fire("Success", "Uploaded Successfully", "success").then(()=>{
          setSignatureModal(false)
          window.location.reload()
          setuploadLoading(false)
        })
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Error Creating Transaction")
        setIsVerifying(false)
      });
  }
  const handleFile = (e) => {
    const file = uploadRef.current.files[0];
    let validated = validImage(
      file,
      ["image/jpeg", "image/png", "application/pdf"],
      250
    );
    // console.log(file);
    // console.log(validated);
    setImageDetails({
      size: validated.fileSize + " kb",
      validated: validated.valid,
      message: validated.valid ? "Passed" : "Failed",
    });

    if (validated.valid) {
      setDocument(URL.createObjectURL(file));
    } else {
      setDocument("");
      return;
    }
  };

  const Approve = (status) => {
    if (requestDetails.passport=="" || requestDetails.signasture==""){
      Swal.fire({
        title: "Error!",
        text: "Please upload passport and signature!",
        icon: "error",
      });
      return
    }
    let token = getAuth.token;
    const data = {
      request_id: id,
      comment:comment,
      recommendation: status,
    };
    try {
      fetch(base_url + idp_recommendation, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            Swal.fire({
              title: status + "...!!!",
              text: "",
              icon: "success",
            }).then(() => {
              setComment("");
              navigate("/admin/idp/pending");
            });

            return response.json();
          } else {
            Swal.fire({
              title: "Error!",
              text: "Error approving resouce!",
              icon: "error",
            });
          }
        })
        .then((jsonResp) => {
          // window.location.reload();
          console.log(jsonResp);
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error approving resouce!",
        icon: "error",
      });
    }
  };

  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(stream);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  };

  const stopWebcam = () => {
    if (stream) {
      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });

      setStream(null);
    }
  };

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      const imageDataURL = canvas.toDataURL("image/png");
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], "captured-image.png", {
            type: "image/png",
          });
          // console.log("Captured Image File:", file);
          setfileData(file);
        }
      }, "image/png");

      // console.log("Captured Image Data URL:", imageDataURL);
      setCaptured(true)
      setcamPreview(imageDataURL);
    }
  };

  const updatePassport = () => {
    setuploadLoading(true);
    let token = getAuth.token;
    console.log(base_url + upload_passport);
    let formData = new FormData();
    formData.set("id", id);
    formData.set("passport", fileData);
    formData.set("document_title", "IDP Passport");
    // console.log("File on form data: " + fileData)
    console.log("FormData:", formData);
    try {
      fetch(base_url + upload_passport, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      })
        .then((response) => {
          console.log("Response: " + response)
          if (response.ok) {
            return response.json();
          } else {
            console.log("Response not ok");
            Swal.fire({
              title: "Error!",
              text: "Error fetching data from the server!",
              icon: "error",
            });
          }
          // Move this line outside the 'then' block
        })
        .then((jsonResp) => {
          console.log(jsonResp);
         if (jsonResp.statusCode==0){
          Swal.fire({
            title: "Success!",
            text: "Uploading Passport Successful!",
            icon: "success",
          }).then(() => {
            setcamPreview("");
            setcamModal(false);
            stopWebcam();
          });
         }
          // Move this line outside the 'then' block
        })
        .catch((error) => {
          console.error("Error uploading Passport:", error);
          Swal.fire({
            title: "Error!",
            text: "Error uploading Passport!",
            icon: "error",
          });
        })
        .finally(() => {
          setuploadLoading(false);
        });
    } catch (e) {
      console.error("Error uploading Passport:", e);
      Swal.fire({
        title: "Error!",
        text: "Error uploading Passport!",
        icon: "error",
      });
      setuploadLoading(false);
    }
  };

  const SyncroniseOwner = () => {
    setSyncing(true);
    
  };
  useEffect(() => {
    let token = getAuth.token;
    console.log(token);
    console.log(base_url + get_idp_details + id);
    try {
      fetch(base_url + get_idp_details + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          // console.log(response.json().data);
          if (response.ok) {
            console.log("repsonse ok");
            return response.json();
          } else {
            console.log("repsonse not ok");
            Swal.fire({
              title: "Error!",
              text: "Error fetching data from the server!",
              icon: "error",
            }).then(() => {
              window.history.back();
            });
          }
        })
        .then((jsonResp) => {
          console.log(jsonResp.data);
          setRequestDetails({
            firstName: jsonResp.data?.owner?.first_name,
            middleName: jsonResp.data?.owner?.middle_name,
            lastName: jsonResp.data?.owner?.last_name,
            gender: jsonResp.data?.owner?.gender,
            phone: jsonResp.data?.owner?.phone,
            email: jsonResp.data?.owner?.email,
            state: jsonResp.data.residentialState,
            residentialState: jsonResp.data.request_state,
            residentialLg: jsonResp.data?.owner?.lga,
            residentialAddress: jsonResp.data?.owner?.present_address,
            profileType: jsonResp.data?.owner?.type,
            dateOfBirth: jsonResp.data.date_of_birth,
            countryOfBirth: jsonResp.data.country_of_birth,
            entryDate: jsonResp.data.entry_date,
            certNo: jsonResp.data.certificate_number,
            licenseClass: jsonResp.data.license_class,
            height: jsonResp.data.height,
            eyeColor: jsonResp.data.eye_color,
            paymentStatus: jsonResp.data.payment_status,
            mla: jsonResp.data.mla,
            requestNumber: jsonResp.data.request_number,
            reference: jsonResp.data.reference,
            commandCenter: jsonResp.data.command_center,
            status: jsonResp.data.status,
            passport: jsonResp.data.passport,
            signature: jsonResp.data.signature
          });
          // setUserInputVehicle(jsonResp.data);
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error fetching data from the server!",
        icon: "error",
      });
    }
  }, []);

  if (!getAuth.token || getAuth.token === "" || getAuth.token === undefined) {
    window.location = "/admin/login";
    return;
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="International Driving Permit Request Details" />
        {/* <ul className="ml-8 flex flex-wrap text-lg pb-4 font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"> */}
        {/* <li className="mr-2 cursor-pointer"></li>
          <li className="mr-2"></li> */}
        {/* </ul> */}

        <div className="bg-white mt-6 py-8">
          <div className="px-8 mt-1 w-full text-lg leading-9 overflow-x-auto">
            <button
              onClick={() => {
                window.history.back();
              }}
              className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
            >
              &larr; Go Back
            </button>
            <div className="my-4">
              <button
                className="bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                onClick={() => {
                  setcamModal(true);
                  startWebcam();
                }}
              >
                <AiOutlineUser />
              </button>
              <button
                className="ml-3 bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                onClick={() => {
                  setSignatureModal(true);
                }}
              >
                <AiFillEdit />
              </button>
            </div>
            {camModal && (
              <div
                className="relative z-10"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <span className="absolute top-0 right-0 mt-2 mr-2 cursor-pointer" onClick={()=>{
                          setcamPreview("");
                          setcamModal(false);
                          stopWebcam();
                        }}>&#10005;</span>
                        <h1>IDP Image Capture</h1>
                        {!captured && <video
                          ref={videoRef}
                          width="640"
                          height="480"
                          onClick={captureImage}
                          autoPlay
                        ></video>}
                        <div>
                          {" "}
                          {/* <button
                            className="my-4 bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                            onClick={captureImage}
                          >
                            Capture Image
                          </button> */}
                          {/* <button
                            className="my-4 ml-4 bg-red-400 text-white px-2 py-1 rounded hover:bg-red-600 cursor-pointer hover:text-black"
                            onClick={() => {
                              setcamPreview("");
                              setcamModal(false);
                              stopWebcam();
                            }}
                          >
                            Close
                          </button> */}
                        </div>
                        <canvas
                          ref={canvasRef}
                          width="640"
                          height="480"
                          style={{ display: "none" }}
                        ></canvas>
                        {camPreview !== "" && (
                          <>
                            {/* <p className="">Image Preview</p> */}
                            <img src={camPreview} alt="Preview" />
                          </>
                        )}
                        {fileData !== null && (
                            <>
                            <button
                              className="my-4 bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                              onClick={() => {
                                setCaptured(false);
                                setcamPreview("");
                                setcamModal(false);
                                stopWebcam();
                                setcamModal(true);
                                startWebcam();
                              }}
                            >
                              Retake
                            </button>

                            <button
                              className="my-4 ml-4 bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                              onClick={() => updatePassport()}
                            >
                              {uploadLoading ? <Loader /> : "Upload Image"}
                            </button>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {signatureModal && (
              <div
                className="relative z-10"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <input
                        ref={uploadRef}
                        required={true}
                        name="document"
                        onChange={handleFile}
                        type="file"
                        id="signature"
                        accept="image/*, application/pdf"
                        className="mb-1 mt-1 text-gray-800 leading-loose"
                      />
                      <button
                        onClick={(e)=>{
                          uploadSignature(e)
                        }} className="bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black">{uploadLoading ? <Loader /> : "Upload"}</button>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Owner Details |{" "}
              <span
                className="cursor-pointer hover:text-black"
                onClick={() => {
                  !syncing && SyncroniseOwner();
                }}
              >
                {syncing ? "Syncing..." : "Syncronise"}
              </span>
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">First Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.firstName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Profile Type:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.profileType === "individual" &&
                          "Personal"}
                        {requestDetails.profileType === "organisation" &&
                          "Organisation"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Phone:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.phone}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Address:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialAddress}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Last Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Gender:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.gender}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">State:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialState}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Middle Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.middleName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Email:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.email}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">L.G.A:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialLg}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="flex mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400 w-full">
              <div className="flex-1 w-12">
                IDP Details
              </div>
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Request Date:</td>
                      <td className="text-blue-800 font-semibold">
                        {formatDateNew(requestDetails.entryDate)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">License Clas:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.licenseClass}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Height:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.height}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">DOB:</td>
                      <td className="text-blue-800 font-semibold">
                        {formatDateNew(requestDetails.dateOfBirth)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Cert No.:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.certNo}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Eye Color:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.eyeColor}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Country of Birth:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.countryOfBirth}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Payment Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.paymentStatus}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Request Details
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Request Date:</td>
                      <td className="text-blue-800 font-semibold">
                        {formatDateNew(requestDetails.entryDate)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Request State:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialState}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Command Centre:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.commandCenter}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Request Number:</td>
                      <td className="text-blue-800 font-semibold whitespace-nowrap">
                        {requestDetails.requestNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Request Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.status}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
          <div className="flex flex-row mb-10">
            <div className="w-1/2">
              <label htmlFor="comment" className="ml-2">
                Comment
              </label>
              <div className="mx-2">
                <textarea
                  size="1000"
                  className="w-full"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                ></textarea>
              </div>
            </div>
            <div className="mt-7">
              <button
                id="approve"
                onClick={() => {
                  Approve("Cleared");
                }}
                className="ml-4 text-gray-100 bg-lightBlue-700 rounded px-4 py-1 mr-4 mt-4"
              >
                Cleared &#10003;
              </button>
              <button
                id="decline"
                onClick={() => {
                  Approve("Not Cleared");
                }}
                className="text-gray-100 bg-red-500 rounded px-4 py-1 mr-4 mt-4"
              >
                Not Cleared &#x2715;
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

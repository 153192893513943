import React, { useState, useEffect, useMemo } from "react";
import { colorResolution } from "utils/ColorResolution";
import Sidebar from "components/admin/SideBars/Sidebar";
import Header from "components/admin/Headers/Header";
import FinalizedCMRTable, {
  StatusPill,
  SelectColumnFilter,
} from "../../components/admin/DataTable/FinalizedCMRTable";
import PreCMRTableAdmin, {
  StatusPill as StatusP,
  SelectColumnFilter as SelectColumnF,
} from "../../components/admin/DataTable/PreCMRTableAdmin";
import { HasAccess } from "@permify/react-role";
import {
  base_url,
  assigned_cmr_requests,
  pre_cmr_by_cc,
  tint_entries,
  ccchead_idp_entries,
} from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";
import { formatDateNew } from "utils/date_format";
import PreCMRTable from "components/admin/DataTable/PreCMRTable";

export default function AssignedTintRequests() {
  const [loading, setLoading] = useState(false);
  const [precmrs, setPreCMRs] = useState([]);
  const [cccprecmrs, setcccPrecmrs] = useState([]);
  const masterTableData = useMemo(() => precmrs, [precmrs]);
  const commandCenterTableData = useMemo(() => cccprecmrs, [cccprecmrs]);
  const getAuth = useAdminAuth((state) => state.auth);

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      // fetch(base_url + assigned_cmr_requests, {
      fetch(`${base_url}${tint_entries}?status=assigned`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          setLoading(false);
          console.log(jsonResp);
          if (jsonResp.data != null) {
            setPreCMRs(jsonResp.data);
          }
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      setLoading(true);
      // fetch(base_url + pre_cmr_by_cc, {
      fetch(`${base_url}${tint_entries}?status=assigned`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          if (jsonResp.data != null) {
            setcccPrecmrs(jsonResp.data);
            setLoading(false);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: () => <div className="w-22">Request Number</div>,
        accessor: "request_number",
      },
      {
        Header: "Name",
        accessor: "owner",
      },
      {
        Header: "Licence Number",
        accessor: "license_plate",
      },
      {
        Header: "Chassis Number",
        accessor: "chassis_number",
      },
      {
        Header: "Make/Model",
        accessor: "model",
      },
      {
        Header: "Vehicle Status",
        accessor: "vehicle_status",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Tint Status",
        accessor: "status",
      },
      {
        Header: "Assigned Date",
        accessor: "entry_date",
        Cell: ({ cell }) => (
          <span>{formatDateNew(cell.row.values.entry_date)}</span>
        ),
      },
      {
        Header: "Command",
        accessor: "request_state",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell }) => (
          <HasAccess
            roles={["cic-head", "ccc-head", "super-admin", "admin"]}
            // permissions="can-repush-tinted"
            renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            // isLoading={<Spinner />}
          >
            <div className="flex justify-between space-x-2">              
              <button
              className={`ml-3 bg-green-300 rounded`}
              value={cell.row.values.id}
              onClick={() => {
                window.location = "/admin/locations/" + cell.row.values.id;
              }}
            >
              Details
            </button>
            {cell.row.values.status !="Assigned" && <>
              <button
                className={`${"bg-red-300" } rounded px-1`}
                value={cell.row.values.id}
                onClick={() => {
                  // toggleAgentStatus(cell.row.values.id, cell.row.values.status);
                }}
              >
                Repush
              </button>
              </>}
            </div>
          </HasAccess>
        ),
      },
    ],
    []
  );

  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Tint Permit Assigned List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <PreCMRTable
                columns={columns}
                data={masterTableData}
                action="link"
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <FinalizedCMRTable
                columns={columns}
                data={commandCenterTableData}
                action="link"
                loading={loading}
              />
            </HasAccess>

          
          </div>
        </div>
      </div>
    </div>
  );
}

import Header from "../../../components/Header"
// import Login from "../components/auth/Login"

export default function NoMatch(){
    return(
        <>
        <div className="container md:w-1/3 mx-auto">
            <Header heading="404 Not Found Error" />
            <div className="text-center text-2xl mt-12 text-red-500">The resource you are looking for is not here.</div>
        </div>
            

        </>
    )
}
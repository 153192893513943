import React from 'react'

const Correction = () => {
    return(
        <div>
            LekanL
        </div>
    )
}

export default  Correction
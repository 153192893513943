import React, { useState, useEffect } from "react";
import {
  base_url
} from "constants/endpoints";
import { useAdminAuth } from "store/AdminAuth";
import Swal from "sweetalert2";
import useModalStore from "store/ModalState";
import Loader from "components/Loader";
import { switch_owner } from "constants/endpoints";

const SwitchVehicleOwner = ({vehicle_id}) => {
  const getAuth = useAdminAuth((state) => state.auth);
  const { modals, toggleModal, setModalState } = useModalStore();
  const [idType, setIdType] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const handleToggleModal = (id) => {
    toggleModal(id);
  };

  const SwitchOwner = (e) => {
    setIsLoading(true)
    e.preventDefault();
    let token = getAuth.token;
    const id = vehicle_id
    // {vehicle_id}/{id_type}/{id_number}
    let url = base_url + switch_owner+ vehicle_id +"/"+idType+"/"+idNumber;
    fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((jsonResp) => {
        console.log(jsonResp);
        if (jsonResp.statusCode === 0) {
          Swal.fire({
            title: "Successful",
            text: "",
            icon: "success",
          }).then(() => {
            setIsLoading(false)
            window.location.reload()
            handleToggleModal("switchowner");
          });
        }else{
          Swal.fire({
            title: "Error",
            text: jsonResp?.message?.to,
            icon: "success",
          }).then(() => {
            setIsLoading(false)
            handleToggleModal("switchowner");
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error Occured",
          text: "Could not make correction happen!",
          icon: "error",
        }).then(() => {
          setIsLoading(false)
          handleToggleModal("switchowner");
        });
        // setConfirmationLoading(false);
      });
  };
  return (
    <>
      {/* Correction Modals */}
      {modals.switchowner && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <span
                    className="float-right font-bold text-2xl cursor-pointer"
                    onClick={() => handleToggleModal("switchowner")}
                  >
                    &#x2715;
                  </span>
                  <h1 className="font-bold">Vehicle Owner Switch</h1>
                  <form className="flex flex-col gap-y-4">
                    <div>
                      <label>Select ID Type</label>
                      <select
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        onChange={(e) => {
                          setIdType(e.target.value)
                        }}
                      >
                        <option value="">Select Id Type</option>
                        <option value="nin">NIN</option>
                        <option value="tin">TIN</option>
                        <option value="cac">CAC</option>
                      </select>
                    </div>

                    <div>
                      <label>ID Number</label>
                      <input
                        className="rounded-md h-10 inline w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                        name="id_number"
                        onChange={(e) => {
                          setIdNumber(e.target.value)
                        }}
                      />
                    </div>
                    <div>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={SwitchOwner}>{!isLoading ? "Switch" : <Loader />}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Correction Modals */}
    </>
  );
};

export default SwitchVehicleOwner;

import { HasAccess } from "@permify/react-role";
import { useAuth } from "Hooks/useAuth";
import { useCowColumns } from "Hooks/useCowColumns";
import ChangeOwnershipRequestTable from "components/admin/DataTable/ChangeOwnership/Admin/ChangeOwnershipRequestTable";
import ProcessedChangeOwnership from "components/admin/DataTable/ChangeOwnership/Head/ProcessedChangeOwnership";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import { base_url, get_admin_cow, get_ccc_cow } from "constants/endpoints";
import { useEffect, useMemo, useState } from "react";
import {
  SelectColumnFilter,
  StatusPill,
} from "../../components/admin/DataTable/ChangeOwnership/Head/ProcessedChangeOwnership";

export default function ProcessedChangeOwnershipRequests() {
  const [loading, setLoading] = useState(false);
  const [precow, setPrecow] = useState([]);
  const [cccprecow, setcccprecow] = useState([]);
  const masterTableData = useMemo(() => precow, [precow]);
  const commandCenterTableData = useMemo(() => cccprecow, [cccprecow]);
  const column_admin = useCowColumns("admin");
  const { token } = useAuth();

  useEffect(() => {
    fetchFunc(`${get_admin_cow}?status=Processed`, setPrecow, setLoading);
    fetchFunc(`${get_ccc_cow}?status=Processed`, setcccprecow, setLoading);
  }, []);

  const fetchFunc = async (url, setter, loadingSetter) => {
    loadingSetter(true);
    try {
      const response = await fetch(base_url + url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const responseData = await response.json();
      const { data, statusCode } = responseData;
      if (statusCode === 0) {
        data && setter(data);
        console.log(data);
      }
      loadingSetter(false);
    } catch (e) {
      loadingSetter(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Request Number",
        accessor: "request_number",
      },
      {
        Header: "Current Owner Name",
        accessor: "current_owner_name",
      },
      {
        Header: "New Owner Name",
        accessor: "new_owner_name",
      },
      {
        Header: "Licence Number",
        accessor: "license_plate",
      },
      {
        Header: "Chassis Number",
        accessor: "chassis_number",
      },
      {
        Header: "Make/Model",
        accessor: "vehicle_make",
      },
      {
        Header: "Request State",
        accessor: "request_state",
      },
      {
        Header: "Recommendation",
        accessor: "recommendations",
        Cell: ({ cell }) => (
          <span
            className={`${
              cell.row.values.recommendations == "Cleared"
                ? "bg-green-400 rounded px-3 py-1 text-black-400"
                : "bg-red-500 rounded px-3 py-1 text-black-400"
            } rounded p-1 ml-3`}
          >
            {cell.row.values.recommendations}
          </span>
        ),
      },

      {
        Header: "Watchlist",
        accessor: "status",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Analyzer",
        accessor: "assigned_to",
      },
      {
        Header: "Command Centre",
        accessor: "command_center",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "cow_id",
        Cell: ({ cell }) => (
          <>
            <div className="flex flex-row">
              <a
                className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
                // value={cell.row.values.id}
                disabled={true}
                onClick={() => {}}
                href={"/admin/cow/details/" + cell.row.values.cow_id}
              >
                Details
              </a>
            </div>
          </>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Change of Ownership Request List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <ChangeOwnershipRequestTable
                columns={column_admin}
                data={masterTableData}
                action="link"
                loading={loading}
              />
              {/* <ChangeOwnershipRequestTable
                columns={columns}
                data={masterTableData}
                action="link"
                loading={loading}
              /> */}
            </HasAccess>

            <HasAccess
              roles={["ccc-head", "cic-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <ProcessedChangeOwnership
                columns={columns}
                data={commandCenterTableData}
                action="link"
                setcccprecow={setcccprecow}
                userloading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

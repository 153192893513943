import React from "react";
import { useAdminAuth } from "store/AdminAuth";

export default function InformationRequest() {
  const getAuth = useAdminAuth((state) => state.auth);
  const role = localStorage.getItem("__permifyUser");
  const roleJson = JSON.parse(role);

  return (
    <>
      <div
        className={`text-xl font-bold text-center py-1 px-10 bg-blueGray-800 text-gray-100`}
      >
        CMR Motor Vehicle Information Request
      </div>
      {getAuth.cc === "Abuja" && (
        <>
          <iframe
            className="w-full"
            // src="https://lookerstudio.google.com/embed/reporting/21acd6e8-e8a2-4a40-a4ff-c0e88e95a644/page/Uq88C"
            src="https://lookerstudio.google.com/embed/reporting/f29af657-2108-4ae7-ac39-af0f0fea2867/page/Uq88C"
            height="900"
            width="1500"
            title="CMR Motor Vehicle Information Request"
            style={{ border: "none" }}
            allowFullScreen
          ></iframe>
        </>
      )}

      {getAuth.cc === "Lagos" && (
        <>
          <iframe
            className="w-full"
            // src="https://lookerstudio.google.com/embed/reporting/fec220da-0293-4870-8ea9-22125410a4bd/page/Uq88C"
            src="https://lookerstudio.google.com/embed/reporting/f29af657-2108-4ae7-ac39-af0f0fea2867/page/Uq88C"
            height="900"
            width="1500"
            title="CMR Motor Vehicle Information Request"
            style={{ border: "none" }}
            allowFullScreen
          ></iframe>
        </>
      )}
      {(getAuth.cc === "" || getAuth.cc === undefined) && (
        <>
          <iframe
            className="w-full"
            width="1500"
            height="900"
            src="https://lookerstudio.google.com/embed/reporting/f29af657-2108-4ae7-ac39-af0f0fea2867/page/Uq88C"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            title="CMR Motor Vehicle Information Request"
          ></iframe>
        </>
      )}
    </>
  );
}

import React, { useState, useMemo, useEffect } from "react";
import Sidebar from "components/admin/SideBars/Sidebar";
import CreateCamera from "components/admin/Cameras/CreateCamera";
import Table from "components/Table";
import {
  base_url,
  get_cameras,
  enable_camera,
  disable_camera,
} from "constants/endpoints";
import Header from "components/admin/Headers/Header";
import { useAdminAuth } from "store/AdminAuth";
import { formatDateReverse } from "utils/date_format";
import Swal from "sweetalert2";

export default function Cameras() {
  const [menu, setMenu] = useState(0);
  const [cameras, setCameras] = useState([]);
  const data = useMemo(() => cameras, [cameras]);
  const getAuth = useAdminAuth((state) => state.auth);
  const token = getAuth.token;

  useEffect(() => {
    // console.log(base_url + get_cameras);
    let token = getAuth.token;
    try {
      fetch(base_url + get_cameras, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => setCameras(jsonResp.data));
    } catch (e) {
      console.log(e);
    }
  }, []);
  const goToIndex = () => {
    setMenu(1);
  };

  const goToCreateLocation = () => {
    setMenu(2);
  };

  const toggleANPRStatus = (id, status) => {
    if (status === 1) {
      disableANPR(id);
    } else {
      enableANPR(id);
    }
  };

  const disableANPR = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to disable this ANPR?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = base_url + disable_camera + id;
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((jsonResp) => {
            Swal.fire("Disabled!", "ANPR disabled.", "success");
          });
      }
    });
  };

  const enableANPR = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to enable this ANPR?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = base_url + enable_camera + id;
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((jsonResp) => {
            Swal.fire("Enabled!", "ANPR enabled.", "success");
          });
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ANPR ID",
        accessor: "camId",
      },
      {
        Header: "Formation Assigned",
        accessor: "location",
        // Cell: StatusPill,
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => {
          if (cell.row.values.status) {
            return <span className="text-green-700">Enabled</span>;
          } else {
            return <span className="text-red-700">Disabled</span>;
          }
        },
      },
      {
        Header: "Date Registered",
        accessor: "created",
        Cell: ({ cell }) => {
          if (cell.row.values.created) {
            return formatDateReverse(cell.row.values.created);
          }
        },
      },

      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell }) => (
          <div className="flex space-x-2">
            <button
              className={`${
                cell.row.values.status === 1 ? "bg-red-300" : "bg-green-300"
              } rounded px-2`}
              value={cell.row.values.id}
              onClick={() => {
                toggleANPRStatus(cell.row.values.id, cell.row.values.status);
              }}
            >
              {cell.row.values.status === 1 ? "Disable" : "Enable"}
            </button>

            <button
              className={`bg-blue-300 rounded px-2`}
              value={cell.row.values.id}
              onClick={() => {
                window.location = "/admin/cameras/" + cell.row.values.id;
              }}
            >
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );
  if (
    getAuth.token === "" ||
    getAuth.token === undefined ||
    getAuth.auth === false
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="ANPR Manager" />
        <div className="flex mx-4 py-1 w-1/5">
          <div
            onClick={() => {
              setMenu(0);
            }}
            className={`flex-auto hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100 ${
              menu === 0 && "text-yellow-300"
            }`}
          >
            List ANPRs
          </div>
          <div
            onClick={() => {
              setMenu(1);
            }}
            className={`flex-auto hover:text-yellow-100 cursor-pointer bg-gray-600 mr-2 rounded py-2 pl-2 text-gray-100 ${
              menu === 1 && "text-yellow-300"
            }`}
          >
            Register ANPR
          </div>
        </div>
        <div className="bg-white pt-1">
          {menu === 0 ? (
            <div className="px-8 mt-3 w-full">
              <Table columns={columns} data={data} />
            </div>
          ) : (
            <div className="mt-3">
              <CreateCamera />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

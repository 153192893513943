import React, { useState, useEffect } from "react";
import { base_url, get_locations, create_camera } from "constants/endpoints";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import Loader from "components/Loader";
import { useAdminAuth } from "store/AdminAuth";
export default function CreateCamera() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [locations, setLocations] = useState([]);
  const getAuth = useAdminAuth((state) => state.auth);
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);
    data.location_id = parseInt(data.location_id);
    let token = getAuth.token;
    try {
      fetch(base_url + create_camera, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          if (jsonResp.statusCode === 0) {
            Swal.fire({
              title: "Success!",
              text: "Camera update successful",
              icon: "success",
            }).then(() => {
              reset();
              setLoading(false);
            });
          } else if (jsonResp.statusCode === 6) {
            Swal.fire({
              title: "ANPR already exist!",
              text: "",
              icon: "warning",
            }).then(() => {
              setLoading(false);
              return;
            });
          }
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(base_url + get_locations);
    let token = getAuth.token;
    try {
      fetch(base_url + get_locations, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          let dataArr = [];
          for (const [key, value] of Object.entries(jsonResp.data)) {
            dataArr[value.id] = value.name;
          }
          setLocations(dataArr);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className="px-8 flex justify-center w-full mt-8">
      <form>
        <div className="">
          <p className="text-xl text-gray-900 font-bold mb-3">Register ANPR</p>
          <label htmlFor="cam_id" className="block text-gray-900 text-md">
            ANPR ID
          </label>
          <input
            type="text"
            {...register("cam_id", { required: true })}
            className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
            aria-invalid={errors.cam_id ? "true" : "false"}
          />
          {errors.cam_id?.type === "required" && (
            <p role="alert" className="text-sm text-red-500">
              ANPR ID is required
            </p>
          )}
          <label
            htmlFor="location_id"
            className="mt-3 block text-gray-900 text-md"
          >
            Formation
          </label>

          <select
            name="location_id"
            {...register("location_id", { required: true })}
            aria-invalid={errors.location_id ? "true" : "false"}
            // onChange={handleChange}
            className="w-full rounded border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
          >
            <option value="">Select CMR Formation</option>
            {locations.map((val, index) => {
              return (
                <option key={index} value={index}>
                  {val}
                </option>
              );
            })}
          </select>
          {errors.location_id?.type === "required" && (
            <p role="alert" className="text-sm text-red-500">
              Location is required
            </p>
          )}
          <div
            className={`bg-lightBlue-700 rounded-lg w-32 cursor-pointer h-12 pt-3 text-center mt-8 text-gray-100 px-3 py-1`}
            onClick={handleSubmit(onSubmit)}
          >
            Submit {isLoading && <Loader />}
          </div>
        </div>
      </form>
    </div>
  );
}

import React, {useEffect} from "react";
import Header from "../../components/Header";
import AdminLogin from "../../components/admin/auth/AdminLogin";
import { checkToken } from "components/checkToken";
import { useAdminAuth } from "store/AdminAuth";

export default function AdminLoginPage() {
  const getAuth = useAdminAuth((state) => state.auth);
  
  useEffect(() => {
    if (
      getAuth.authenticated === true ||
      getAuth.token !== "" ||
      getAuth.token !== ""
    ) {
      checkToken(getAuth.token).then((auth) => {
        if (!auth) {
          localStorage.clear();
          window.location = "/admin/login";
        }else{
          window.location="/admin/dashboard"
        }
      });
    }
  }, []);

  return (
    <>
      <div className="">
        <Header heading="Central Motor Regisry Information System (CMRIS)" subheading={`Admin Portal`} />
        <AdminLogin />
      </div>
    </>
  );
}

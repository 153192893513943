import ChangePasswordForm from "../../components/admin/ChangePasswordForm";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";

export default function ChangePassword() {
  return (
    <>
      <div className="">
        <Sidebar />
        <div className="md:ml-64 bg-blueGray-600">
          <Header title="Change Password" />
        </div>
        <div className="pl-64 mx-auto">
          <ChangePasswordForm />
        </div>
        {/* <Header heading="NIGERIA POLICE FORCE" /> */}
      </div>
    </>
  );
}

import { useAuth } from "Hooks/useAuth";
import Loader from "components/Loader";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import {
  base_url,
  get_cow_details,
  approval_layer_cow,
} from "constants/endpoints";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { formatDateNew } from "utils/date_format";
import { upperFirstChar } from "utils/string_manipulation";
import { useNavigate } from "react-router-dom";
import { HasAccess } from "@permify/react-role";
import image from "../../img/document.png";

export default function PreChangeOwnershipDetails() {
  const { id } = useParams();
  const [requestDetails, setRequestDetails] = useState({});
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchFunc();
  }, []);

  const Approve = (status) => {
    const data = {
      request_id: id,
      comment,
      recommendation: status,
    };
    try {
      fetch(base_url + approval_layer_cow, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            Swal.fire({
              title: status + "...!!!",
              text: "",
              icon: "success",
            }).then(() => {
              setComment("");
              navigate("/admin/cow/pending");
            });

            return response.json();
          } else {
            Swal.fire({
              title: "Error!",
              text: "Error approving resouce!",
              icon: "error",
            });
          }
        })
        .then((jsonResp) => {
          // window.location.reload();
          console.log(jsonResp);
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error approving resouce!",
        icon: "error",
      });
    }
  };

  const fetchFunc = async () => {
    setLoading(true);
    try {
      const response = await fetch(base_url + get_cow_details + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const responseData = await response.json();
      const { data, statusCode } = responseData;
      if (statusCode === 0) {
        data && setRequestDetails(data);
      } else {
        errorFunc();
      }
      setLoading(false);
    } catch (e) {
      errorFunc();
      setLoading(false);
    }
  };

  const errorFunc = () => {
    Swal.fire({
      title: "Error!",
      text: "Error fetching data from the server!",
      icon: "error",
    });
  };

  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Change of Ownership Request Details" />
        <>
          {loading ? (
            <div className="mt-6 py-8 bg-white">
              <Loader />
            </div>
          ) : (
            <div className="bg-white mt-6 py-8">
              <div className="px-8 mt-1 w-full text-lg leading-9 overflow-x-auto">
                <button
                  onClick={() => {
                    window.history.back();
                  }}
                  className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                >
                  &larr; Go Back
                </button>

                <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
                  Vehicle Details
                </div>
                <div className="table-container">
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className=" text-lg py-2">Licence Plate:</td>
                          <td className="text-blue-800 font-semibold text-base ">
                            {requestDetails.licensePlate}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Make/Model:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.model}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Vehicle Type:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.vehicleType}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Watchlist Status:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.vehicleStatus}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className=" text-lg py-2">Chassis Number:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.chassisNumber}
                          </td>
                        </tr>
                        {/* <tr>
                          <td className=" text-lg py-2">Model:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.model}
                          </td>
                        </tr> */}
                        <tr>
                          <td className=" text-lg py-2">Color:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.color}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Licence State:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.requestState}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className=" text-lg py-2">Engine Number:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.engineNumber}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Year:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.year}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Vehicle Usage:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.vehicleUsage}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <hr />
                <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
                  New Owner Details
                </div>
                <div className="table-container">
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className="text-lg py-2">First Name:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.new_owner?.firstName}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Profile Type:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {upperFirstChar(
                              requestDetails?.new_owner?.profileType
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Phone:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.new_owner?.phone}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Address:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.new_owner?.residentialAddress}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className=" text-lg py-2">Last Name:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.new_owner?.lastName}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Gender:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.new_owner?.gender}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">State:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.new_owner?.residentialState}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className=" text-lg py-2">Middle Name:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.new_owner?.middleName}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Email:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.new_owner?.email}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">L.G.A:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.new_owner?.residentialLg}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />

                <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
                  Old Owner Details
                </div>
                <div className="table-container">
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className=" text-lg py-2">First Name:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.old_owner?.firstName}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Profile Type:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {upperFirstChar(
                              requestDetails?.old_owner?.profileType
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Phone:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.old_owner?.phone}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Address:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.old_owner?.residentialAddress}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className=" text-lg py-2">Last Name:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.old_owner?.lastName}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Gender:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.old_owner?.gender}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">State:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.old_owner?.residentialState}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className=" text-lg py-2">Middle Name:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.old_owner?.middleName}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Email:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.old_owner?.email}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">L.G.A:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails?.old_owner?.residentialLg}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <hr />
                <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
                  Request Details
                </div>
                <div className="table-container">
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className=" text-lg py-2">Request Date:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {formatDateNew(requestDetails.entryDate)}
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-lg py-2">Request Number:</td>
                          <td className="text-blue-800 font-semibold text-base whitespace-nowrap">
                            {requestDetails.requestNumber}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <td className=" text-lg py-2">Request Status:</td>
                          <td className="text-blue-800 font-semibold text-base">
                            {requestDetails.status}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div className="bg-green-200 w-1/12 mt-4">
                  <span className="cursor-pointer">View Document</span>
                  <a href={requestDetails?.document} target="__blank">
                    <img
                      src={image}
                      alt="Change of ownership document"
                      className="h-20 w-20"
                    />
                  </a>
                </div>
              </div>
              <HasAccess
                roles={["ccc-analyser", "cic-operative"]}
                renderAuthFailed={
                  <p className="text-red-700 text-4xl mx-8"></p>
                }
              >
                <div className="flex flex-row py-10">
                  <div className="w-1/2">
                    <label htmlFor="comment" className="ml-4">
                      Comment
                    </label>
                    <div className="mx-4">
                      <textarea
                        size="1000"
                        className="w-full"
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                      ></textarea>
                    </div>
                  </div>
                  <div className="mt-2">
                    <button
                      id="approve"
                      onClick={() => {
                        Approve("Cleared");
                      }}
                      className="ml-4 text-gray-100 bg-lightBlue-700 rounded px-4 py-1 mr-4 mt-4"
                    >
                      Cleared &#10003;
                    </button>
                    <button
                      id="decline"
                      onClick={() => {
                        Approve("Error");
                      }}
                      className="text-gray-100 bg-red-500 rounded px-4 py-1 mr-4 mt-4"
                    >
                      Not Cleared &#x2715;
                    </button>
                  </div>
                </div>
              </HasAccess>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

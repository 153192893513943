import create from "zustand";
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  menu: "",
  setMenu: (menu) =>
    set(
      produce((temp) => {
        temp.menu = menu;
      })
    ),
  reset: (menu) => set(menu),
});
// store = devtools(store);
store = persist(store, { name: "side" });
export const useSideMenu = create(store);

import React from "react";
import Header from "components/admin/Headers/Header";
import AddAgentForm from "components/admin/Forms/AddAgentForm";
import AgentRegistration from "components/admin/Forms/AgentRegistration";
import Sidebar from "components/admin/SideBars/Sidebar";

export default function AddAgent() {
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-gray-100">
        <Header title="CMRIS Assistant Registration" />
        <div className="w-full mx-auto px-5  my-12 border shadow-md rounded-lg pt-1 pb-3 bg-white">
          <div className="mb-4 text-xl text-left border-b pb-4 font-bold text-gray-600">
         Add CMRIS Assistant
          </div>
          {/* <AddAgentForm /> */}
          <AgentRegistration />
        </div>
      </div>
    </div>
  );
}

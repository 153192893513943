import { HasAccess } from "@permify/react-role";
import PreCMRTable from "components/admin/DataTable/PreCMRTable";
import Header from "components/admin/Headers/Header";
import Sidebar from "components/admin/SideBars/Sidebar";
import {
  public_base,
  base_url,
  get_admin_request,
  get_ccc_request,
} from "constants/endpoints";
import { useEffect, useMemo, useState } from "react";
import { useAdminAuth } from "store/AdminAuth";
import FinalizedCMRTable, {
  SelectColumnFilter,
  StatusPill,
} from "../../components/admin/DataTable/FinalizedCMRTable";
import { useNavigate} from "react-router-dom";

export default function FinalizedCMRRequests() {
  const [loading, setLoading] = useState(false);
  const [precmrs, setPreCMRs] = useState([]);
  const [cccprecmrs, setcccPrecmrs] = useState([]);
  const masterTableData = useMemo(() => precmrs, [precmrs]);
  const commandCenterTableData = useMemo(() => cccprecmrs, [cccprecmrs]);
  const getAuth = useAdminAuth((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      fetch(`${base_url}${get_admin_request}?status=finalized`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          if (jsonResp.data != null) {
            setPreCMRs(jsonResp.data);
            // console.log(jsonResp.data);
            setLoading(false);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    // let token = localStorage.getItem("cmr_admin_token");
    let token = getAuth.token;

    try {
      fetch(`${base_url}${get_ccc_request}?status=finalized`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((jsonResp) => {
          if (jsonResp.data != null) {
            setcccPrecmrs(jsonResp.data);
            setLoading(false);
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S/No",
        id: "index",
        accessor: (row, i) => i + 1,
      },
      {
        Header: () => <div className="w-22">Request Number</div>,
        accessor: "RequestNumber",
      },
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Licence Number",
        accessor: "LicensePlate",
      },
      {
        Header: "Chassis Number",
        accessor: "ChassisNumber",
      },
      {
        Header: "Make/Model",
        accessor: "Model",
      },
      {
        Header: "Vehicle Status",
        accessor: "VehicleStatus",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Analyzer",
        accessor: "AssignedTo",
      },
      {
        Header: "Certificate Number",
        accessor: "CertNumber",
      },
      // {
      //   Header: "Finalized Date",
      //   accessor: "FinalizedAt",
      //   Cell: ({ cell }) => (
      //     <span>
      //       {formatDateNew(cell.row.values.FinalizedAt)}
      //       <button onClick={() => console.log(cell)}>tobi</button>
      //     </span>
      //   ),
      // },
      {
        Header: "Command Centre",
        accessor: "CommandCenter",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Status",
        accessor: "Status",
        Cell: ({ cell }) => (
          <span
            className={`${
              cell.row.values.Status === "Approved"
                ? "border-2 border-green-500 rounded px-3 py-2 text-green-500"
                : "border-2 border-red-500 rounded px-3 py-2 text-red-500"
            } rounded p-1`}
          >
            {cell.row.values.Status}{" "}
            {cell.row.values.Status === "Approved" ? (
              <span>&#10003;</span>
            ) : (
              <span>&#x2715;</span>
            )}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "CMREntryId",
        Cell: ({ cell }) => (
          <>
            {/* <button
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900`}
              value={cell.row.values.CMREntryId}
              disabled={false}
              onClick={() => {}}
            >
              View Reciept
            </button> */}

            <button
              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900 ml-3`}
              value={cell.row.values.CMREntryId}
              disabled={false}
              onClick={() => {
                let url = public_base+"/cert/"+cell.row.values.CMREntryId
                window.open(url,'_blank', 'rel=noopener noreferrer')
              }}
            >
              View Certificates
            </button>
          </>
        ),
      },
    ],
    []
  );

  if (
    getAuth.authenticated === false ||
    getAuth.token === "" ||
    getAuth.token === undefined
  ) {
    window.location = "/admin/login";
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Information Requests List" />
        <div className="bg-white mt-6 pt-8">
          <div className="px-8 mt-3 w-full">
            <HasAccess
              roles={["super-admin", "admin"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Master Table */}
              <PreCMRTable
                columns={columns}
                data={masterTableData}
                loading={loading}
              />
            </HasAccess>

            <HasAccess
              roles={["ccc-head"]}
              // permissions="view-formation"
              renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
            >
              {/**Command Centres Table */}
              <FinalizedCMRTable
                columns={columns}
                data={commandCenterTableData}
                action="link"
                loading={loading}
              />
            </HasAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

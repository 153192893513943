import { base_url } from "constants/endpoints";
import { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { useController, useForm } from "react-hook-form";
import color from "../../../constants/colors";
import vehicle_types from "../../../constants/vehicle_types";
import FormAction from "../../FormAction";
import Input from "./Input";
import Select from "./Select";

export default function FlashReportForm() {
  const [formation, setformation] = useState([]);
  const [vehicleMake, setvehicleMake] = useState([]);
  const [vehicleModel, setvehicleModel] = useState([]);
  const [value, onChange] = useState(new Date());
  const [incidentType, setIncidentType] = useState("")

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
    getValues,
  } = useForm({
    defaultValues: {},
  });

  const incident_type = ["Stolen Vehicle", "Incident"];
  const vehicle_category = ["Motorcycle/Tricycle", "Motor Vehicle"];
  const mode_of_stealing = ["Removed from Where Parked", "Carjacked/Snatched"];


  const state = "Edo";

  const fetchData = async (path_url, query, setter) => {
    const url = `${base_url}/api/${path_url}/${query}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = await response.json();
      setter(data);
      console.log(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData("formations", state, setformation);
    fetchData("manufacturers", "", setvehicleMake);
  }, []);

  const incident = useController({
    name: "incident",
    control,
    defaultValue: "",
  });

  const time = useController({
    name: "time",
    control,
    defaultValue: "",
  });

  const incident2 = useController({
    name: "incident2",
    control,
    defaultValue: "",
  });

  const mode = useController({
    name: "mode",
    control,
    defaultValue: "",
  });

  const onSubmit = (data) => {
    console.log({ data, errors });
  };

  return (
    <div className="">
      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <div className="flex">
            <div className="flex-1">
              <Select
                label="Incident Type"
                id="incident_type"
                name="incident_type"
                options={incident_type}
                register={register}
                required
                onChange={(e) =>{
                  setIncidentType(e.target.value)
                }}
            />
            </div>
            <div className="flex-1 ml-3">
            <Input
              label={"Name of Reporter"}
              name="nameOfReporter"
              id="nameOfReporter"
              type="text"
              register={register}
              required
              errors={errors.nameOfReporter}
            />
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex-1">
            <Input
              label={"Address of Reporter"}
              name="addressOfReporter"
              id="addressOfReporter"
              type="text"
              register={register}
              required
              errors={errors.addressOfReporter}
            />
          </div>
          <div className="flex-1 ml-3">
            <Input
              label={"Phone Number"}
              id="phone"
              name="phone"
              type="text"
              register={register}
              required
              errors={errors.gsm}
            />
          </div>
        </div>
        <div className="flex">
        <div className="flex-1 mr-3">
          <Select
            label="Vehicle Category"
            id="category"
            name="category"
            options={vehicle_category}
            register={register}
            required
            // onChange={(e) =>
            //   fetchData("models", e.target.value, setvehicleModel)
            // }
          />
        </div>
          <div className="flex-1 mr-3">
            <Select
              label="Vehicle Make"
              id="vehicleMake"
              name="vehicleMake"
              options={vehicleMake}
              register={register}
              required
              onChange={(e) =>
                fetchData("models", e.target.value, setvehicleModel)
              }
            />
          </div>
          <div className="flex-1">
            <Select
              label="Model"
              id="model"
              name="model"
              options={vehicleModel}
              register={register}
              required
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 mr-3">
            <Select
              label={"Type"}
              id={"type"}
              name="type"
              options={vehicle_types}
              register={register}
              required
            />
          </div>
          <div className="flex-1">
            <Select
              label={"Color"}
              id={"color"}
              name="color"
              options={color}
              register={register}
              required
            />
          </div>
        </div>

        
         <div className="flex-1">
         <Input
             label={" Place/Location of Occurence"}
             register={register}
             required
             name="occurence"
             errors={errors.occurence}
           />
           {/* <label htmlFor="time">Date</label>
           <DateTimePicker
             onChange={onChange}
             id="time"
             value={value}
             className="w-full rounded border-2 h-11 text-lg hover:border-green-800"
           /> */}
         </div>
         <div className="flex-1">
           <label
             htmlFor="incidentDetails"
             className="whitespace-nowrap mr-2 self-start"
           >
             Incident Details:
           </label>
           <textarea
             id="incidentDetails"
             className="w-full border-2 hover:border-green-800  h-40 text-lg"
             {...register("incidentDetails", { required: true })}
           />
           <span>{errors.incidentDetails?.message}</span>
         </div>
        

        <>
        <div className="flex">
          <div className="flex-1">
            <Select
              label="Mode of Stealing"
              id="mode_of_stealing"
              name="mode_of_stealing"
              options={mode_of_stealing}
              register={register}
              required
              onChange={(e) =>{
                setIncidentType(e.target.value)
              }}
            />
          </div>
        </div>
          {/* {incidentType === "Stolen Vehicle" ? (
            <div className="flex justify-between  mt-5 ">
              <h5 className="whitespace-nowrap mr-2">Mode of Stealing:</h5>
              <div className="flex items-center justify-center gap-2 ">
                <input
                  type="radio"
                  id="vehicle_theft"
                  name="incident_checked"
                  className=" hover:border-green-800"
                  value="vehicle_theft"
                  checked={incident2.field.value === "vehicle_theft"}
                  onChange={() => incident2.field.onChange("vehicle_theft")}
                />
                <label htmlFor="vehicle_theft">Carjacked/Snatched</label>
              </div>
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  id="vehicle_ass-acident"
                  name="incident_checked"
                  className=" hover:border-green-800"
                  value="vehicle_theft"
                  checked={incident2.field.value === "vehicle_ass-acident"}
                  onChange={() =>
                    incident2.field.onChange("vehicle_ass-acident")
                  }
                />
                <label htmlFor="vehicle_ass-acident">
                  Removed from Where Parked
                </label>
              </div>
            </div>
          ) : null} */}
        </>

        {/* <div className="flex flex-col mt-20 mb-10 "> */}
          {/* <h4 className="font-semibold mx-auto">Official</h4> */}
          {/* <hr className="border-2 border-solid border-black"></hr> */}
        {/* </div> */}

        <Select
          label={"Reporting Formation/Division/Station"}
          id="reporting"
          options={formation}
          name="reporting"
          register={register}
          required
          errors={errors.reporting}
        />

        <div className="flex justify-between  mt-5 ">
          <h5 className="whitespace-nowrap mr-2">Incident Reported By:</h5>
          <div className="md:grid-cols-3 grid-cols-2  grid gap-5">
            <div className="col-span-1 ">
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  id="mode"
                  className=" hover:border-green-800"
                  name="mode"
                  value="radioMessage"
                  checked={mode.field.value === "radioMessage"}
                  onChange={() => mode.field.onChange("radioMessage")}
                />
                <label htmlFor="mode">Radio Message</label>
              </div>
              <div className="flex items-center justify-center mt-5 gap-2">
                <input
                  type="radio"
                  id="mode"
                  name="mode"
                  className=" hover:border-green-800 ml-1"
                  value="telephone"
                  checked={mode.field.value === "telephone"}
                  onChange={() => mode.field.onChange("telephone")}
                />
                <label htmlFor="mode">Telephone Call</label>
              </div>
            </div>

            <div className="col-span-1 ">
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  id="mode"
                  name="mode"
                  className=" hover:border-green-800"
                  value="physicalVisit"
                  checked={mode.field.value === "physicalVisit"}
                  onChange={() => mode.field.onChange("physicalVisit")}
                />
                <label htmlFor="mode">Physical Visit</label>
              </div>
              <div className="flex items-center justify-center mt-5 gap-2">
                <input
                  type="radio"
                  id="mode"
                  name="mode"
                  className=" hover:border-green-800"
                  value="sms"
                  checked={mode.field.value === "sms"}
                  onChange={() => mode.field.onChange("sms")}
                />
                <label htmlFor="mode">SMS</label>
              </div>
            </div>

            <div className="col-span-1 ">
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  id="mode"
                  name="mode"
                  className=" hover:border-green-800"
                  value="email"
                  checked={mode.field.value === "email"}
                  onChange={() => mode.field.onChange("email")}
                />
                <label htmlFor="mode">Email</label>
              </div>
              <div className="flex items-center justify-center mt-5 gap-2">
                <input
                  type="radio"
                  id="mode"
                  name="mode"
                  className=" hover:border-green-800"
                  value="others"
                  checked={mode.field.value === "others"}
                  onChange={() => mode.field.onChange("others")}
                />
                <label htmlFor="mode">Others</label>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-5">
          <label
            htmlFor="dopName"
            className="whitespace-nowrap mr-2 self-start"
          >
            Control Room Duty Officer
          </label>
          <div className="w-full flex flex-col">
            <input
              type="text"
              id="dopName"
              name="dopName"
              className=" w-full
              h-auto
              p-2
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              {...register("dopName", { required: true })}
            />
            <span>{errors.dopName?.message}</span>
            <h6 className="block mx-auto">AP/Force No., Rank and Name</h6>
          </div>
        </div>

        {/* <div className="flex flex-col mt-5">
          <label
            htmlFor="dopName"
            className="whitespace-nowrap mr-2 self-start"
          >
            CIC Desk Officer
          </label>
          <div className="w-full flex flex-col">
            <input
              type="text"
              id="dopName"
              name="dopName"
              className=" w-full
              h-auto
              p-2
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              {...register("dopName", { required: true })}
            />
            <span>{errors.dopName?.message}</span>
            <h6 className="block mx-auto">AP/Force No., Rank and Name</h6>
          </div>
        </div> */}
        <FormAction action="submit" text="Submit" type="Button" />
      </form>

      <h6 className="flex justify-center mt-14 text-sm">
        The Nigeria Police Force, Central Motor Registry, Force Headquarters
        Annex Lagos
      </h6>
    </div>
  );
}

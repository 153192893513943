import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

function IconComponent({ showPassword, setShowPassword }) {
  const toggleShowPassword = () => setShowPassword(!showPassword);
  return (
    <div className="absolute top-[20%] right-0 pr-3 flex items-center">
      {showPassword ? (
        <EyeIcon
          className="h-5 w-5 text-gray-400 cursor-pointer"
          onClick={toggleShowPassword}
        />
      ) : (
        <EyeOffIcon
          className="h-5 w-5 text-gray-400 cursor-pointer"
          onClick={toggleShowPassword}
        />
      )}
    </div>
  );
}

export default IconComponent;
